import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks"
import { TimelineSlot } from "./TimelineSlot"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { useTranslation } from "react-i18next"
import { ReducedEvent, TIME_FORMAT_DEFAULT, TIME_FORMAT_DEFAULT_SKELETON } from "../../utils"
import { setIsSystemLive } from "../../redux/reducers/isSystemLive.slice"
import { setReducedEvents } from "../../redux/reducers/reducedEvents.slice"
import { setCurrentEventParameters } from "../../redux/reducers/currentEventParameters.slice"
import { ReducedEventParameters } from "../../utils/eventReducedListCreator"

interface TimeslotContainerProps {
    eventId?: string
}

function TimelineSlotContainer({eventId}: TimeslotContainerProps) {
    const queryInterval = useAppSelector((state) => state.queryInterval)
    const reducedEventsList = useAppSelector((state) => state.reducedEventsList)
    const currentEventParameters = useAppSelector((state) => state.currentEventParameters)
    const eventParametersList = useAppSelector((state) => state.eventParametersList)
    const isLoading = useAppSelector((state) => state.isLoading)
    const dispatch = useAppDispatch()
    const { i18n } = useTranslation()

    dayjs.extend(utc)
    dayjs.locale(i18n.language)

    const handleTimeslotClick = (reducedEvents: ReducedEventParameters) => {
        const newCurrentEventParameters = eventParametersList.find((eventParameters) => eventParameters.creationInfo?.creationTime === reducedEvents.creationTime)
        if (newCurrentEventParameters) {
            dispatch(setIsSystemLive(false))
            dispatch(setReducedEvents(reducedEvents))
            dispatch(setCurrentEventParameters(newCurrentEventParameters))
        }
    }

    return(
        <div className={'tl-slot-container'}>
            <div className='tl-slot-initial'></div>
            {!isLoading && reducedEventsList.map((reducedEvents) => {
                const key = eventId ? `${eventId}${reducedEvents.creationTime}` : reducedEvents.creationTime
                return(
                    <TimelineSlot 
                        key={key} 
                        onClick={() => handleTimeslotClick(reducedEvents)}
                        reducedEvents={reducedEvents}
                        eventId={eventId}
                        isCurrent={currentEventParameters.creationInfo?.creationTime === reducedEvents.creationTime} />
                )
            })}
            <div className='tl-slot-final'></div>
            <div className='tl-slot-timebar'>
                <span className='tl-slot-timebar-time tl-slot-timebar-begin'>{isLoading ? TIME_FORMAT_DEFAULT_SKELETON : dayjs.utc(queryInterval.startDate).format(TIME_FORMAT_DEFAULT)}</span>
                <span className='tl-slot-timebar-time tl-slot-timebar-end'>{isLoading ? TIME_FORMAT_DEFAULT_SKELETON : dayjs.utc(queryInterval.endDate).format(TIME_FORMAT_DEFAULT)}</span>
            </div>
        </div>
    )
}

export { TimelineSlotContainer }