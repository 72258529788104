import './App.css'
import {Navigate, Route, Routes} from 'react-router-dom'
import {EarlyestNavbar} from './components/EarlyestNavbar/EarlyestNavbar'
import {EarlyestDashboard} from './pages/EarlyestDashboard/EarlyestDashboard'
import {NotFound} from './components/NotFound/NotFound'
import { SideBar } from './components/SideBar/SideBar'
import { DASHBOARD_PATH, EVENTS_PATH, EVENT_PATH, PICKS_PATH, PICK_PATH, STATIONS_PATH, STATION_PATH, INFO_PATH } from './routes/routes'
import { EventDetail } from './pages/EventDetail/EventDetail'
import { Stations } from './pages/Stations/Stations'
import { StationDetail } from './pages/StationDetail/StationDetail'
import { Picks } from './pages/Picks/Picks'
import { PickDetail } from './pages/PickDetail/PickDetail'
import {Events} from "./pages/Events/Events"
import { InfoPage } from './pages/InfoPage/InfoPage'

function App() {
    // First the navbar and the sidebar
    // Then all the other routes
    return (
        <div className='main-container'>
            <EarlyestNavbar/>
            <SideBar/>
            <main className='main-content'>
                <Routes>
                    <Route path='/' element={<Navigate to={DASHBOARD_PATH} replace/>}/>
                    <Route path={DASHBOARD_PATH} element={<EarlyestDashboard/>}/>
                    <Route path={EVENT_PATH} element={<EventDetail/>} />
                    <Route path={EVENTS_PATH} element={<Events/>}/>
                    <Route path={PICKS_PATH} element={<Picks/>}/>
                    <Route path={PICK_PATH} element={<PickDetail/>}/>
                    <Route path={STATIONS_PATH} element={<Stations/>}/>
                    <Route path={STATION_PATH} element={<StationDetail/>}/>
                    <Route path={INFO_PATH} element={<InfoPage/>}/>
                    <Route path='*' element={<NotFound/>}/>
                </Routes>
            </main>
        </div>
    )
}

export default App
