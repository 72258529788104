import { useState } from 'react'
import { IconButton } from '@carbon/react'
import './EarlyestDropdown.css'

type EarlyestDropdownProps = {
    text: string,
    icon: React.ReactNode,
    children?: React.ReactNode
}

function EarlyestDropdown({text, icon, children}: EarlyestDropdownProps) {
    const [show, setShow] = useState<boolean>(false)
    
    return <>
        <div className='dd-container dropdown'>
            <IconButton className="bc-button earlyest-btn" enterDelayMs={10000000} label={text} onClick={() => setShow(!show)}>
                {icon}
            </IconButton>
            {show && children}
        </div>
    </>
}

export { EarlyestDropdown }