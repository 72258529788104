/* tslint:disable */
/* eslint-disable */
/**
 * EarlyEst Dashboard API
 * All the APIs of the EarlyEst Dashboard
 *
 * The version of the OpenAPI document: 2.16.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { FilteredRecentEvents } from '../model';
// @ts-ignore
import { Orderings } from '../model';
// @ts-ignore
import { RecentEvent } from '../model';
/**
 * RecentEventsApi - axios parameter creator
 * @export
 */
export const RecentEventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return all the RecentEvent existing in the DB with origin time that falls within the last month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentEvents: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recent-events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the RecentEvent existing in the DB that matches the filters passed as parameters
         * @param {string} [startdate] The start date for an Event
         * @param {string} [enddate] The end date for an Event
         * @param {number} [lat] The latitude of the center of circular area
         * @param {number} [lon] The longitude of the center of circular area
         * @param {number} [minradiuskm] The minimum radius (in Km) of the circular area
         * @param {number} [maxradiuskm] The maximum radius (in Km) of the circular area
         * @param {number} [minlat] The minimum latitude of the rectangular area
         * @param {number} [minlon] The minimum longitude of the rectangular area
         * @param {number} [maxlat] The maximum latitude of the rectangular area
         * @param {number} [maxlon] The maximum longitude of the rectangular area
         * @param {number} [minmag] The minimum magnitude for an event
         * @param {number} [maxmag] The maximum magnitude for an event
         * @param {number} [mindepth] The minimum depth (in Km) for an event
         * @param {number} [maxdepth] The maximum depth (in Km) for an event
         * @param {Orderings} [orderby] The ordering to use to sort the results
         * @param {number} [limit] The number of maximum events per page. If omitted, all events are returned
         * @param {number} [page] The page number for the event search, to use together with limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentEventsFiltered: async (startdate?: string, enddate?: string, lat?: number, lon?: number, minradiuskm?: number, maxradiuskm?: number, minlat?: number, minlon?: number, maxlat?: number, maxlon?: number, minmag?: number, maxmag?: number, mindepth?: number, maxdepth?: number, orderby?: Orderings, limit?: number, page?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recent-events-filtered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startdate !== undefined) {
                localVarQueryParameter['startdate'] = (startdate as any instanceof Date) ?
                    (startdate as any).toISOString() :
                    startdate;
            }

            if (enddate !== undefined) {
                localVarQueryParameter['enddate'] = (enddate as any instanceof Date) ?
                    (enddate as any).toISOString() :
                    enddate;
            }

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (lon !== undefined) {
                localVarQueryParameter['lon'] = lon;
            }

            if (minradiuskm !== undefined) {
                localVarQueryParameter['minradiuskm'] = minradiuskm;
            }

            if (maxradiuskm !== undefined) {
                localVarQueryParameter['maxradiuskm'] = maxradiuskm;
            }

            if (minlat !== undefined) {
                localVarQueryParameter['minlat'] = minlat;
            }

            if (minlon !== undefined) {
                localVarQueryParameter['minlon'] = minlon;
            }

            if (maxlat !== undefined) {
                localVarQueryParameter['maxlat'] = maxlat;
            }

            if (maxlon !== undefined) {
                localVarQueryParameter['maxlon'] = maxlon;
            }

            if (minmag !== undefined) {
                localVarQueryParameter['minmag'] = minmag;
            }

            if (maxmag !== undefined) {
                localVarQueryParameter['maxmag'] = maxmag;
            }

            if (mindepth !== undefined) {
                localVarQueryParameter['mindepth'] = mindepth;
            }

            if (maxdepth !== undefined) {
                localVarQueryParameter['maxdepth'] = maxdepth;
            }

            if (orderby !== undefined) {
                localVarQueryParameter['orderby'] = orderby;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecentEventsApi - functional programming interface
 * @export
 */
export const RecentEventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecentEventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return all the RecentEvent existing in the DB with origin time that falls within the last month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentEvents(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecentEvent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentEvents(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecentEventsApi.getRecentEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Return the RecentEvent existing in the DB that matches the filters passed as parameters
         * @param {string} [startdate] The start date for an Event
         * @param {string} [enddate] The end date for an Event
         * @param {number} [lat] The latitude of the center of circular area
         * @param {number} [lon] The longitude of the center of circular area
         * @param {number} [minradiuskm] The minimum radius (in Km) of the circular area
         * @param {number} [maxradiuskm] The maximum radius (in Km) of the circular area
         * @param {number} [minlat] The minimum latitude of the rectangular area
         * @param {number} [minlon] The minimum longitude of the rectangular area
         * @param {number} [maxlat] The maximum latitude of the rectangular area
         * @param {number} [maxlon] The maximum longitude of the rectangular area
         * @param {number} [minmag] The minimum magnitude for an event
         * @param {number} [maxmag] The maximum magnitude for an event
         * @param {number} [mindepth] The minimum depth (in Km) for an event
         * @param {number} [maxdepth] The maximum depth (in Km) for an event
         * @param {Orderings} [orderby] The ordering to use to sort the results
         * @param {number} [limit] The number of maximum events per page. If omitted, all events are returned
         * @param {number} [page] The page number for the event search, to use together with limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentEventsFiltered(startdate?: string, enddate?: string, lat?: number, lon?: number, minradiuskm?: number, maxradiuskm?: number, minlat?: number, minlon?: number, maxlat?: number, maxlon?: number, minmag?: number, maxmag?: number, mindepth?: number, maxdepth?: number, orderby?: Orderings, limit?: number, page?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilteredRecentEvents>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentEventsFiltered(startdate, enddate, lat, lon, minradiuskm, maxradiuskm, minlat, minlon, maxlat, maxlon, minmag, maxmag, mindepth, maxdepth, orderby, limit, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecentEventsApi.getRecentEventsFiltered']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RecentEventsApi - factory interface
 * @export
 */
export const RecentEventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecentEventsApiFp(configuration)
    return {
        /**
         * 
         * @summary Return all the RecentEvent existing in the DB with origin time that falls within the last month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentEvents(options?: any): AxiosPromise<Array<RecentEvent>> {
            return localVarFp.getRecentEvents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the RecentEvent existing in the DB that matches the filters passed as parameters
         * @param {string} [startdate] The start date for an Event
         * @param {string} [enddate] The end date for an Event
         * @param {number} [lat] The latitude of the center of circular area
         * @param {number} [lon] The longitude of the center of circular area
         * @param {number} [minradiuskm] The minimum radius (in Km) of the circular area
         * @param {number} [maxradiuskm] The maximum radius (in Km) of the circular area
         * @param {number} [minlat] The minimum latitude of the rectangular area
         * @param {number} [minlon] The minimum longitude of the rectangular area
         * @param {number} [maxlat] The maximum latitude of the rectangular area
         * @param {number} [maxlon] The maximum longitude of the rectangular area
         * @param {number} [minmag] The minimum magnitude for an event
         * @param {number} [maxmag] The maximum magnitude for an event
         * @param {number} [mindepth] The minimum depth (in Km) for an event
         * @param {number} [maxdepth] The maximum depth (in Km) for an event
         * @param {Orderings} [orderby] The ordering to use to sort the results
         * @param {number} [limit] The number of maximum events per page. If omitted, all events are returned
         * @param {number} [page] The page number for the event search, to use together with limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentEventsFiltered(startdate?: string, enddate?: string, lat?: number, lon?: number, minradiuskm?: number, maxradiuskm?: number, minlat?: number, minlon?: number, maxlat?: number, maxlon?: number, minmag?: number, maxmag?: number, mindepth?: number, maxdepth?: number, orderby?: Orderings, limit?: number, page?: number, options?: any): AxiosPromise<FilteredRecentEvents> {
            return localVarFp.getRecentEventsFiltered(startdate, enddate, lat, lon, minradiuskm, maxradiuskm, minlat, minlon, maxlat, maxlon, minmag, maxmag, mindepth, maxdepth, orderby, limit, page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecentEventsApi - interface
 * @export
 * @interface RecentEventsApi
 */
export interface RecentEventsApiInterface {
    /**
     * 
     * @summary Return all the RecentEvent existing in the DB with origin time that falls within the last month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentEventsApiInterface
     */
    getRecentEvents(options?: RawAxiosRequestConfig): AxiosPromise<Array<RecentEvent>>;

    /**
     * 
     * @summary Return the RecentEvent existing in the DB that matches the filters passed as parameters
     * @param {string} [startdate] The start date for an Event
     * @param {string} [enddate] The end date for an Event
     * @param {number} [lat] The latitude of the center of circular area
     * @param {number} [lon] The longitude of the center of circular area
     * @param {number} [minradiuskm] The minimum radius (in Km) of the circular area
     * @param {number} [maxradiuskm] The maximum radius (in Km) of the circular area
     * @param {number} [minlat] The minimum latitude of the rectangular area
     * @param {number} [minlon] The minimum longitude of the rectangular area
     * @param {number} [maxlat] The maximum latitude of the rectangular area
     * @param {number} [maxlon] The maximum longitude of the rectangular area
     * @param {number} [minmag] The minimum magnitude for an event
     * @param {number} [maxmag] The maximum magnitude for an event
     * @param {number} [mindepth] The minimum depth (in Km) for an event
     * @param {number} [maxdepth] The maximum depth (in Km) for an event
     * @param {Orderings} [orderby] The ordering to use to sort the results
     * @param {number} [limit] The number of maximum events per page. If omitted, all events are returned
     * @param {number} [page] The page number for the event search, to use together with limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentEventsApiInterface
     */
    getRecentEventsFiltered(startdate?: string, enddate?: string, lat?: number, lon?: number, minradiuskm?: number, maxradiuskm?: number, minlat?: number, minlon?: number, maxlat?: number, maxlon?: number, minmag?: number, maxmag?: number, mindepth?: number, maxdepth?: number, orderby?: Orderings, limit?: number, page?: number, options?: RawAxiosRequestConfig): AxiosPromise<FilteredRecentEvents>;

}

/**
 * RecentEventsApi - object-oriented interface
 * @export
 * @class RecentEventsApi
 * @extends {BaseAPI}
 */
export class RecentEventsApi extends BaseAPI implements RecentEventsApiInterface {
    /**
     * 
     * @summary Return all the RecentEvent existing in the DB with origin time that falls within the last month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentEventsApi
     */
    public getRecentEvents(options?: RawAxiosRequestConfig) {
        return RecentEventsApiFp(this.configuration).getRecentEvents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the RecentEvent existing in the DB that matches the filters passed as parameters
     * @param {string} [startdate] The start date for an Event
     * @param {string} [enddate] The end date for an Event
     * @param {number} [lat] The latitude of the center of circular area
     * @param {number} [lon] The longitude of the center of circular area
     * @param {number} [minradiuskm] The minimum radius (in Km) of the circular area
     * @param {number} [maxradiuskm] The maximum radius (in Km) of the circular area
     * @param {number} [minlat] The minimum latitude of the rectangular area
     * @param {number} [minlon] The minimum longitude of the rectangular area
     * @param {number} [maxlat] The maximum latitude of the rectangular area
     * @param {number} [maxlon] The maximum longitude of the rectangular area
     * @param {number} [minmag] The minimum magnitude for an event
     * @param {number} [maxmag] The maximum magnitude for an event
     * @param {number} [mindepth] The minimum depth (in Km) for an event
     * @param {number} [maxdepth] The maximum depth (in Km) for an event
     * @param {Orderings} [orderby] The ordering to use to sort the results
     * @param {number} [limit] The number of maximum events per page. If omitted, all events are returned
     * @param {number} [page] The page number for the event search, to use together with limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentEventsApi
     */
    public getRecentEventsFiltered(startdate?: string, enddate?: string, lat?: number, lon?: number, minradiuskm?: number, maxradiuskm?: number, minlat?: number, minlon?: number, maxlat?: number, maxlon?: number, minmag?: number, maxmag?: number, mindepth?: number, maxdepth?: number, orderby?: Orderings, limit?: number, page?: number, options?: RawAxiosRequestConfig) {
        return RecentEventsApiFp(this.configuration).getRecentEventsFiltered(startdate, enddate, lat, lon, minradiuskm, maxradiuskm, minlat, minlon, maxlat, maxlon, minmag, maxmag, mindepth, maxdepth, orderby, limit, page, options).then((request) => request(this.axios, this.basePath));
    }
}

