import { useState } from 'react'
import './Accordion.css'
import { ReactComponent as AccordionOpenIcon } from '../../assets/accordion-open-icon.svg'
import { ReactComponent as AccordionCloseIcon } from '../../assets/accordion-close-icon.svg'

interface AccordionProps {
    title: string
    open?: boolean,
    children?: React.ReactNode
}

function Accordion({title, open, children}: AccordionProps) {
    const [openSt, setOpenSt] = useState<boolean>(open ?? true)

    return (
        <div className='accordion'>
            <div className='accordion-title-bar' onClick={() => setOpenSt(!openSt)}>
                <span className='accordion-title'>{title.toUpperCase()}</span>
                <div className='accordion-icon'>
                    {openSt ? 
                    <AccordionCloseIcon />
                    :
                    <AccordionOpenIcon />
                    }
                </div>
            </div>
            {openSt && children && 
            <div className='accordion-content'>
                {children}
            </div>
            }
        </div>
    )
}

export { Accordion }