import './IconButton.css'

interface IconButtonProps {
    icon: () => JSX.Element,
    onClick: Function
}

function IconButton({icon, onClick}: IconButtonProps) {
    return(
        <div className={'icon-button'} onClick={() => onClick()}>
            {icon()}
        </div>
    )
}

export { IconButton }