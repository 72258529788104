import { ChannelReduced } from '../../services/network/model/channel-reduced'
import { TooltipFragment, TooltipProvider } from '../TooltipProvider/TooltipProvider'
import './LatencyDisplay.css'

interface LatencyDisplayProps {
    latency: number,
    chans: ChannelReduced[]
}

function LatencyDisplay({latency, chans}: LatencyDisplayProps) {
    const formatLatency = (latencyRaw: number) => {
        if (latency === -1) {
            return '--'
        } else if (latencyRaw < 60) {
            return `${latencyRaw}s`
        } else if (latencyRaw < 3600) {
            return `${Math.floor(latencyRaw/60)}m ${latencyRaw%60}s`
        } else if (latencyRaw < 86400) {
            return `${Math.floor(latencyRaw/3600)}h ${Math.floor((latencyRaw%3600)/60)}m ${(latencyRaw%3600)%60}s`
        } else {
            return `${Math.floor(latencyRaw/86400)}d ${Math.floor((latencyRaw%86400)/3600)}h ${Math.floor(((latencyRaw%86400)%3600)/60)}m ${((latencyRaw%86400)%3600)%60}s`
        }
    }

    const classNameForLatency = (latencyRaw: number, tooltip: boolean) => {
        let latencyClass 
        if (latency === -1) {
            latencyClass = ' latency-unknown'
        } else if (latencyRaw < 30) {
            latencyClass = tooltip ? ' latency-lt-30s-tt' : ' latency-lt-30s'
        } else if (latencyRaw < 300) {
            latencyClass = tooltip ? ' latency-lt-5m-tt' : ' latency-lt-5m'
        } else if (latencyRaw < 3600) {
            latencyClass = tooltip ? ' latency-lt-1h-tt' : ' latency-lt-1h'
        } else {
            latencyClass = tooltip ? ' latency-gt-1h-tt' : ' latency-gt-1h'
        }
        return 'latency-text' + latencyClass
    }

    const generateTooltipFragments = (chans: ChannelReduced[]) => {
        return chans.map(chan => ({
            prefix: (chan.name ?? '--') + ':',
            value: formatLatency(+(chan.latency ?? '-1')),
            prefixClass: 'latency-chan-name',
            valueClass: classNameForLatency(+(chan.latency ?? '-1'), true)
        }))
    }

    return (
        <>
        {chans.length === 0 ?
            <span className={classNameForLatency(latency, false)}>{formatLatency(latency)}</span>
        :
        <TooltipProvider
            tooltipFragments={generateTooltipFragments(chans)}
            orientation='right' >
            <span className={classNameForLatency(latency, false)}>{formatLatency(latency)}</span>
        </TooltipProvider>
        }
        </>
    )
}

export { LatencyDisplay }