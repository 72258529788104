import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EventParameters } from '../../services/network'
import { RootState } from '../store'

const initialStateCurrentEventParameters: EventParameters = {}

export const currentEventParametersSlice = createSlice({
  name: 'currentEventParameters',
  initialState: initialStateCurrentEventParameters,
  reducers: {
    resetCurrentEventParameters: (state) => {
      return initialStateCurrentEventParameters
    },
    setCurrentEventParameters: (state, action: PayloadAction<EventParameters>) => {
      return action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentEventParameters, resetCurrentEventParameters } = currentEventParametersSlice.actions

export const getCurrentEventParameters = (state: RootState) => state.currentEventParameters

export default currentEventParametersSlice.reducer