import log from 'loglevel'
import { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxCustomHooks'
import { resetIsSystemLive, setIsSystemLive, toggleIsSystemLive } from '../../redux/reducers/isSystemLive.slice'
import { resetIsLoading, setIsLoading } from '../../redux/reducers/isLoading.slice'
import { ApiClient } from '../../services/ApiClient'
import { IntervalViewer } from '../IntervalViewer/IntervalViewer'
import './Timeline.css'
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { TimelineButton, TimelineButtonType } from './TimelineButton'
import { TimelineSlotContainer } from './TimelineSlotContainer'
import { setqueryInterval } from "../../redux/reducers/queryInterval.slice"
import { eventReducedListCreator, ReducedEventParameters } from "../../utils/eventReducedListCreator"
import { resetReducedEventsList, setReducedEventsList } from "../../redux/reducers/reducedEventsList.slice"
import { resetReducedEvents, setReducedEvents } from "../../redux/reducers/reducedEvents.slice"
import { DATE_FORMAT_DEFAULT_WITH_UTC } from '../../utils'
import { resetCurrentEventParameters, setCurrentEventParameters } from '../../redux/reducers/currentEventParameters.slice'
import { resetEventParametersList, setEventParametersList } from '../../redux/reducers/eventParametersList.slice'
import { useTranslation } from 'react-i18next'
import { resetRequestError, setRequestError } from '../../redux/reducers/requestError.slice'
import { resetCancelledEvents, setCancelledEvents } from '../../redux/reducers/cancelledEvents.slice'

interface TimelineProps {
    eventId?: string,
    className?: string
}

function Timeline({eventId, className}: TimelineProps) {
    const requestError = useAppSelector((state) => state.requestError)
    const reducedEvents = useAppSelector((state) => state.reducedEvents)
    const reducedEventsList = useAppSelector((state) => state.reducedEventsList)
    const eventParametersList = useAppSelector((state) => state.eventParametersList)
    const cancelledEvents = useAppSelector((state) => state.cancelledEvents)
    const currentEventParameters = useAppSelector((state) => state.currentEventParameters)
    const queryInterval = useAppSelector((state) => state.queryInterval)
    const isLoading = useAppSelector((state) => state.isLoading)
    const isSystemLive = useAppSelector((state) => state.isSystemLive)
    const dispatch = useAppDispatch()
    const [lastKnownEventDate, setLastKnownEventDate] = useState<string>()
    const [liveButtonDisabled, setLiveButtonDisabled] = useState(false)
    const lastKnownEventDateRef = useRef(lastKnownEventDate)
    const eventParametersListRef = useRef(eventParametersList)
    const currentEventParametersRef = useRef(currentEventParameters)
    const { i18n } = useTranslation()

    dayjs.extend(utc)
    dayjs.locale(i18n.language)

    const getEventParametersByDate = async (startDate: string, endDate: string) => {
        try {
            // Query the backend with start and end date
            log.debug('Timeline (Date) => Requesting eventParamters...')
            log.debug('Timeline (Date) => Start Date => ', startDate, ' EndDate => ', endDate)
            
            // This is done to avoid showing the loading indicator too often when it's not needed
            if (!isSystemLive || eventParametersListRef?.current?.length === 0) {
                dispatch(setIsLoading(true))
            }
            
            // TODO: Call the backend service that returns the ID of the latest EventParameters; check if the ID is different; only if it is different, request all the EventParameters
            const res = await ApiClient.eventParametersApi().getEventParameters(startDate, endDate)

            // Set the results in redux
            const mostRecentEventParameters = res.data?.eventParametersList.at(-1)
            if (currentEventParametersRef?.current?._id !== mostRecentEventParameters?._id) {
                dispatch(setEventParametersList(res.data.eventParametersList))
                dispatch(setCancelledEvents(res.data.cancelledEvents))
                if (mostRecentEventParameters) {
                    dispatch(setCurrentEventParameters(mostRecentEventParameters))
                } else {
                    dispatch(resetCurrentEventParameters())
                }
            }

            // If everything was successful, remove any remaining errors from previous iterations
            if (requestError) dispatch(setRequestError(null))
            dispatch(resetIsLoading())
        } catch (e: any) {
            log.debug("Timeline (Date) => Request failed => ", e)
            dispatch(setRequestError(e.message))
            dispatch(resetCurrentEventParameters())
            dispatch(resetIsLoading())
        }
    }

    const getEventParametersByEventId = async (eventId: string, lastKnownEventDate?: string) => {
        try {
            // Query the backend for the Event ID
            log.debug('Timeline (Event) => Requesting eventParamters...')
            log.debug('Timeline (Event) => Event ID => ', eventId, ' Last Known Event Date => ', lastKnownEventDate, ' EventParametersList Length => ', eventParametersListRef?.current?.length)

            // This is done to avoid showing the loading indicator too often when it's not needed
            if (eventParametersListRef?.current?.length === 0) {
                dispatch(setIsLoading(true))
            }

            const res = await ApiClient.eventParametersApi().getEventParametersWithEvent(eventId, lastKnownEventDate)

            // Check if the cache can be kept or not
            if (!(res.data?.isCacheStillGood) && res.data?.eventParameters.length > 0) {
                log.debug('Timeline (Event) => Cache is outdated, new data available. Setting in redux...')
                // Set the results in redux
                const mostRecentEventParameters = res.data.eventParameters.at(-1)
                log.debug('Timeline (Event) => Most recent Event Parameters => ', mostRecentEventParameters?._id)
                dispatch(setEventParametersList(res.data.eventParameters))
                if (mostRecentEventParameters) {
                    log.debug('Timeline (Event) => Setting Most Recent Event Parameters to => ', mostRecentEventParameters._id)
                    dispatch(setCurrentEventParameters(mostRecentEventParameters))
                    res.data.isEventCancelled ? dispatch(setCancelledEvents([eventId])) : dispatch(setCancelledEvents([]))
                } else {
                    log.debug('Timeline (Event) => No Most Recent Event Parameters found, resetting currentEventParameters')
                    dispatch(resetCurrentEventParameters())
                }
            } else {
                log.debug('Timeline (Event) => Cache is stil good, not changing anything')
            }

            // Set the new lastKnownEventDate in the state
            log.debug('Timeline (Event) => Setting Last Known Event Date to => ', res.data.lastEventDate)
            setLastKnownEventDate(res.data.lastEventDate)

            // Set the query interval relative to the event
            log.debug(`Timeline (Event) => Setting query interval for the event to => ${res.data.startDate} - ${res.data.endDate}`)
            dispatch(setqueryInterval({startDate: res.data.startDate, endDate: res.data.endDate}))

            if (isSystemLive && !res.data.isEventStillHappening) {
                // The system was live, but the event is not happening anymore, so cancel any future updates
                log.debug('Timeline (Event) => The Event is finished, putting system out of live mode')
                dispatch(setIsSystemLive(false))
                
                // If the event is over, should not be possible to go live again in the event detail
                setLiveButtonDisabled(true)
            }

            // If everything was successful, remove any remaining errors from previous iterations
            if (requestError) dispatch(setRequestError(null))

            dispatch(resetIsLoading())
        } catch (e: any) {
            log.debug("Timeline (Event) => Request failed => ", e)
            dispatch(setRequestError(e.message))
            dispatch(resetCurrentEventParameters())
            dispatch(resetIsLoading())
        }
    }

    const updateInterval = () => {  // TODO: Evaluate if this is needed here or can be originated elsewhere
        if (eventId) return // Avoid updates here when in Event mode
        const nowDate = dayjs.utc()
        // const nowDate = dayjs.utc('2022-10-19T21:10:41.000Z')
        log.debug('Timeline => updateInterval => executing function to update the interval at ', nowDate.format(DATE_FORMAT_DEFAULT_WITH_UTC))
        const startDate = nowDate.subtract(59, 'minutes').second(0).toISOString()
        const endDate = nowDate.second(59).toISOString()
        dispatch(setqueryInterval({startDate, endDate}))
    }

    const processEventParametersList = async () => {
        // The following computation is a bit heavy, better set the isLoading state
        log.debug('Timeline => processEventParametersList => Processing eventParametersList...')
        dispatch(setIsLoading(true))
        
        const newReducedEventsList = eventReducedListCreator(eventParametersList, cancelledEvents)
        dispatch(setReducedEventsList(newReducedEventsList))
        const newReducedEvents = newReducedEventsList.at(-1) ?? {creationTime: '', version: '--', duration: '',  columnStart: -1, columnSpan: -1, events: []}
        dispatch(setReducedEvents(newReducedEvents))
        
        // Reset the isLoading state
        dispatch(resetIsLoading())
        log.debug('Timeline => processEventParametersList => Finished processing eventParametersList')
    }

    useEffect(() => {
        if (eventId) {
            log.debug('Timeline => New Timeline with EventId...')
            dispatch(setIsSystemLive(true))
        } else {
            log.debug('Timeline => New Timeline by date...')
            updateInterval() // TODO: Evaluate if this is needed here or can be originated elsewhere
        }

        return(() => {
            log.debug('Timeline => Component unmounting, cleaning up...')
            dispatch(resetIsSystemLive())
            dispatch(resetCurrentEventParameters())
            dispatch(resetRequestError())
            dispatch(resetReducedEvents())
            dispatch(resetReducedEventsList())
            dispatch(resetEventParametersList())
            dispatch(resetCancelledEvents())
            setLastKnownEventDate(undefined)
        })
    }, [])

    useEffect(() => {
        lastKnownEventDateRef.current = lastKnownEventDate;
    }, [lastKnownEventDate])

    useEffect(() => {
        currentEventParametersRef.current = currentEventParameters;
    }, [currentEventParameters]);

    useEffect(() => {
        if (eventId) return // Avoid updates here when in Event mode

        // When the query is updated, perform a request to the backend
        log.debug('Timeline => useEffect queryInterval => Requesting eventParameters')
        getEventParametersByDate(queryInterval.startDate, queryInterval.endDate) 
        // TODO: Implement something that can be called very frequently (every ~2 sec) and only return full results when there's some new event
    }, [queryInterval])

    useEffect(() => {
        if (isSystemLive) { // If the system is live again, restart the updates, both in Event mode and in Dashboard mode
            if (eventId) {
                getEventParametersByEventId(eventId, lastKnownEventDateRef.current)
                const eventTimer = setInterval(() => getEventParametersByEventId(eventId, lastKnownEventDateRef.current), 2000)
                return () => clearInterval(eventTimer)
            } else {
                updateInterval()
                const intervalTimer = setInterval(() => updateInterval(), 2000)
                return () => clearInterval(intervalTimer)
            }
        }
        // When the system is not live, just do nothing, React already called the funtions (returned before) to cancel the timers
    }, [isSystemLive])
    
    useEffect(() => {
        processEventParametersList()
        eventParametersListRef.current = eventParametersList;
    }, [eventParametersList])

    const getCurrentIndex = () => {
        return eventParametersList.findIndex((element) => element._id === currentEventParameters._id)
    }

    const canIncrementOneHour = () => {
        return dayjs.utc(queryInterval.startDate).add(62, 'minutes').isBefore(dayjs.utc())
    }

    const evaluateButtonEnabled = (buttonType: TimelineButtonType): boolean => {
        if (isLoading) {
            return false
        }

        switch (buttonType) {
            case 'backward':
                return eventId ? false : true
            case 'previousSlot':
                return eventId ? (getCurrentIndex() > 0) : true
            case 'forward':
                return eventId ? false : canIncrementOneHour()
            case 'nextSlot':
                return eventId ? (getCurrentIndex() < eventParametersList.length - 1) : (getCurrentIndex() < eventParametersList.length - 1) || canIncrementOneHour()
            default: 
                return false
        }
    }

    const handleLiveButtonClick = () => {
        log.debug('Timeline => handleLiveButtonClick => Checking for live button disabled...')
        if (liveButtonDisabled) return
        log.debug('Timeline => handleLiveButtonClick => Live button enabled')
        if (!isSystemLive) {
            dispatch(resetEventParametersList())
            dispatch(resetCancelledEvents())
            dispatch(resetCurrentEventParameters())
            dispatch(resetRequestError())
            dispatch(resetReducedEvents())
            dispatch(resetReducedEventsList())
            setLastKnownEventDate(undefined)
        }
        dispatch(toggleIsSystemLive())
    }
    
    const handleSlotButtonClick = (buttonType: TimelineButtonType) => {
        const currentIndex = getCurrentIndex()
        if (currentIndex === -1) return // Can't find the current EventParameters between EventParameters. Should be impossible

        switch (buttonType) {
            case 'previousSlot':
                if (currentIndex === 0) {
                    handleBackwardForwardButtonClick('backward')
                    break
                } else {
                    dispatch(setIsSystemLive(false))
                    dispatch(setReducedEvents(reducedEventsList[currentIndex - 1]))
                    dispatch(setCurrentEventParameters(eventParametersList[currentIndex - 1]))
                    break
                }
            case 'nextSlot':
                if (currentIndex === eventParametersList.length - 1) {
                    handleBackwardForwardButtonClick('forward')
                    break
                } else {
                    dispatch(setIsSystemLive(false))
                    dispatch(setReducedEvents(reducedEventsList[currentIndex + 1]))
                    dispatch(setCurrentEventParameters(eventParametersList[currentIndex + 1]))
                    break
                }
            default:
                return // Should be impossible to end up here
        }
    }

    const handleBackwardForwardButtonClick = (buttonType: TimelineButtonType) => {
        switch (buttonType) {
            case 'backward':
                dispatch(setIsSystemLive(false))
                dispatch(setqueryInterval({
                    startDate: dayjs.utc(queryInterval.startDate).subtract(1, 'hour').toISOString(),
                    endDate: dayjs.utc(queryInterval.endDate).subtract(1, 'hour').toISOString()
                }))
                break
            case 'forward':
                if (canIncrementOneHour()) {
                    dispatch(setIsSystemLive(false))
                    dispatch(setqueryInterval({
                        startDate: dayjs.utc(queryInterval.startDate).add(1, 'hour').toISOString(),
                        endDate: dayjs.utc(queryInterval.endDate).add(1, 'hour').toISOString()
                    }))
                }
                break
        }
    }
    
    return (
        <div className={`timeline ${ className ? className : ''}`}>
            <TimelineButton type='live' enabled={!liveButtonDisabled} isSystemLive={isSystemLive} onClick={() => handleLiveButtonClick()} />
            <TimelineButton type='backward' enabled={evaluateButtonEnabled('backward')} onClick={() => {handleBackwardForwardButtonClick('backward')}} />
            <IntervalViewer enabled={!isLoading && eventId === undefined} />
            <TimelineButton type='forward' enabled={evaluateButtonEnabled('forward')} onClick={() => {handleBackwardForwardButtonClick('forward')}} />
            <TimelineButton type='previousSlot' enabled={evaluateButtonEnabled('previousSlot')} onClick={() => {handleSlotButtonClick('previousSlot')}} />
            <TimelineButton type='nextSlot' enabled={evaluateButtonEnabled('nextSlot')} onClick={() => {handleSlotButtonClick('nextSlot')}} />
            <TimelineSlotContainer eventId={eventId} />
        </div>
    )
}

export { Timeline }