import './PickDetailFragment.css'
import { useNavigate } from 'react-router-dom'
import { PICKS_PATH } from '../../routes/routes'
import { ReactComponent as IconBack } from '../../assets/back-icon.svg'
import log from 'loglevel'
import { IconButton } from '../IconButton/IconButton'
import { EventParametersEventInner, EventParametersEventInnerOriginArrivalInner, EventParametersEventInnerPickInner } from '../../services/network'
import { useTranslation } from 'react-i18next'
import { PickDetailData } from './PickDetailData'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT_SKELETON } from '../../utils'
import { useAppSelector } from '../../hooks/reduxCustomHooks'
import { Loading } from '@carbon/react'

interface PickDetailFragmentProps {
    event?: EventParametersEventInner,
    pick?: EventParametersEventInnerPickInner,
    arrival?: EventParametersEventInnerOriginArrivalInner
}
function PickDetailFragment({event, pick, arrival}: PickDetailFragmentProps) {
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()
    const isLoading = useAppSelector((state) => state.isLoading)
    
    dayjs.extend(utc)
    dayjs.locale(i18n.language)

    log.debug('PickDetailFragment => Pick => ', pick)

    return (
        <div className='pick-detail-data-container'>
            <div className='breadcrumb-container'>
                <IconButton icon={() => <IconBack/>} onClick={() => navigate(PICKS_PATH, {replace: false})}/>
                <div className='breadcrumb-container-internal'>
                    <span className='breadcrumb-item-rtd'>Event Pick List</span>
                    <span className='breadcrumb-item-separator'>/</span>
                    <span className='breadcrumb-item-pick-title'>{`${isLoading ? DATE_FORMAT_DEFAULT_SKELETON : dayjs.utc(pick?.time?.value).format(DATE_FORMAT_DEFAULT)} - Event Id ${event?.publicID?.split('/').pop() ?? '--'}`}</span>
                </div>
            </div>
            {isLoading ?
            <div className='pick-detail-loader-container'>
                <Loading className='pick-detail-loader-element' description="Loading indicator" withOverlay={false} small={true} />
                <span className='pick-detail-loader-element'> {t('component__pick_detail_data__loading')}</span>
            </div>
            :
            <PickDetailData event={event} pick={pick} arrival={arrival} />
            }
        </div>
    )
}

export { PickDetailFragment }