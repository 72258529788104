import log from 'loglevel'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TsunamigenicEvaluatorClient, TsunamigenicEvaluatorResponse } from '../../services/TsunamigenicEvaluatorClient'
import './DecisionTable.css'
import { ReducedEvent } from '../../utils'

interface DecisionTableProps {
    reducedEvent?: ReducedEvent
}

function DecisionTable({reducedEvent}: DecisionTableProps) {
    const [decisionTableData, setDecisionTableData] = useState<TsunamigenicEvaluatorResponse | undefined>(undefined)
    const {t} = useTranslation()

    const dtColors: {[key: string]: string} = {
        GREEN: '#6FDC8C',
        LTGREEN: '#DEFBE6',
        YELLOW: '#FDDC69',
        RED: '#FF8389',
        GREY: '#6F6F6F'
    }

    const extractMagnitude = (reducedEvent: ReducedEvent): {mag: number, nr_mag: number} => {
        if (reducedEvent.mb) {
            return {mag: +reducedEvent.mb, nr_mag: +reducedEvent.mbStations}
        }

        if (reducedEvent.mwp) {
            return {mag: +reducedEvent.mwp, nr_mag: +reducedEvent.mwpStations}
        }

        if (reducedEvent.mwpd) {
            return {mag: +reducedEvent.mwpd, nr_mag: +reducedEvent.mwpdStations}
        }

        return {mag: 0, nr_mag: 0}
    }

    const getDecisionTableValues = async (reducedEvent: ReducedEvent) => {
        try {
            const res = await TsunamigenicEvaluatorClient.getTsunamigenicEvaluatorData({
                event_id: reducedEvent?.eventId ?? '',
                origin_id: reducedEvent?.originId ?? '',
                lat: reducedEvent?.lat ? +reducedEvent.lat : 'None',
                lon: reducedEvent?.lon ? +reducedEvent.lon : 'None',
                depth: reducedEvent.depth ? +reducedEvent.depth : 'None',
                minHorUnc: reducedEvent.originMinHorUnc ? +reducedEvent.originMinHorUnc : 'None',
                maxHorUnc: reducedEvent.originMaxHorUnc ? +reducedEvent.originMaxHorUnc : 'None',
                azMaxHorUnc: reducedEvent.originAzMaxHorUnc ? +reducedEvent.originAzMaxHorUnc : 'None',
                ...extractMagnitude(reducedEvent),
                T50Ex: reducedEvent.t50ex ? +reducedEvent.t50ex : 'None',
                T50Ex_nr: reducedEvent.t50exStations ? +reducedEvent.t50exStations : 'None',
                Td: reducedEvent.td ? +reducedEvent.td : 'None',
                Td_nr: reducedEvent.tdStations ? +reducedEvent.tdStations : 'None',
                T0: reducedEvent.t0 ? +reducedEvent.t0 : 'None',
                T0_nr: reducedEvent.t0 ? +reducedEvent.t0Stations : 'None'
            }) 
            
            setDecisionTableData(res.data)
        } catch (e) {
            log.error('DecisionTable => Request of Decision Table data failed => ', e)
        }
    }

    useEffect(() => {
        if (reducedEvent) {
            getDecisionTableValues(reducedEvent)
        }
    }, [reducedEvent])

    return (
        <div className='decision-table-container'>
            <div className='decision-table-labels-container'>
                <div className='decision-table-label'>{t('component__decision_table__event_type')}</div>
                <div className='decision-table-label'>{t('component__decision_table__plate_boundary')}</div>
                <div className='decision-table-label'>{t('component__decision_table__prob_underwater')}</div>
                <div className='decision-table-label'>{t('component__decision_table__td_t50ex')}</div>
                <div className='decision-table-label'>{t('component__decision_table__td_to')}</div>
                <div className='decision-table-label'>{t('component__decision_table__mwp_mag')}</div>
                <div className='decision-table-label'>{t('component__decision_table__hypo_depth')}</div>
                <div className='decision-table-label'>{t('component__decision_table__slab_depth')}</div>
            </div>
            <div className='decision-table-values-container'>
                <div className='decision-table-value-title' style={{gridColumn: '1 / span 6', gridRow: '1 / span 1'}}>{t('component__decision_table__subduction_zone')}</div>
                <div className='decision-table-value' style={{gridColumn: '1 / span 6', gridRow: '2 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.Compatible_close_plate_boundary_color?.[0] ?? 'GREEN']}}>{decisionTableData?.data?.Decision_table?.Compatible_close_plate_boundary_text?.[0] ?? '-'}</div>
                <div className='decision-table-value' style={{gridColumn: '1 / span 6', gridRow: '3 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.prob_underwater_color?.[0] ?? 'GREEN']}}>{decisionTableData?.data?.Decision_table?.prob_underwater_text?.[0] ?? '-'}</div>
                
                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '4 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 5', gridRow: '4 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.TdT50Ex_color?.[0] ?? 'LTGREEN']}}>{decisionTableData?.data?.Decision_table?.TdT50Ex_value?.[0] ?? '-'}</div>

                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '5 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 1', gridRow: '5 / span 1', background: dtColors['LTGREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '3 / span 4', gridRow: '5 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.tdt0_color?.[0] ?? 'GREY']}}>{decisionTableData?.data?.Decision_table?.tdt0_value?.[0] ?? '-'}</div>

                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '6 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 1', gridRow: '6 / span 1', background: dtColors['LTGREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '3 / span 1', gridRow: '6 / span 1', background: dtColors['GREY']}}></div>
                <div className='decision-table-value' style={{gridColumn: '4 / span 3', gridRow: '6 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.Mwp_magnitude_color?.[0] ?? 'LTGREEN']}}>{decisionTableData?.data?.Decision_table?.Mwp_magnitude_text?.[0] ?? '-'}</div>

                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '7 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 1', gridRow: '7 / span 1', background: dtColors['LTGREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '3 / span 1', gridRow: '7 / span 1', background: dtColors['GREY']}}></div>
                <div className='decision-table-value' style={{gridColumn: '4 / span 3', gridRow: '7 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.Hypocenter_depth_color?.[0] ?? 'RED']}}>{decisionTableData?.data?.Decision_table?.Hypocenter_depth_text ?? '-'}</div>

                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '8 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 1', gridRow: '8 / span 1', background: dtColors['LTGREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '3 / span 1', gridRow: '8 / span 1', background: dtColors['GREY']}}></div>
                <div className='decision-table-value' style={{gridColumn: '4 / span 3', gridRow: '8 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.slab_depth_color?.[0] ?? 'GREY']}}>{decisionTableData?.data?.Decision_table?.slab_depth_text?.[0] ?? '-'}</div>
            </div>
            <div className='decision-table-values-container'>
                <div className='decision-table-value-title' style={{gridColumn: '1 / span 6', gridRow: '1 / span 1'}}>{t('component__decision_table__other_thrust_normal')}</div>
                <div className='decision-table-value' style={{gridColumn: '1 / span 6', gridRow: '2 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.Compatible_close_plate_boundary_color?.[0] ?? 'GREEN']}}>{decisionTableData?.data?.Decision_table?.Compatible_close_plate_boundary_text?.[1] ?? '-'}</div>
                <div className='decision-table-value' style={{gridColumn: '1 / span 6', gridRow: '3 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.prob_underwater_color?.[1] ?? 'GREEN']}}>{decisionTableData?.data?.Decision_table?.prob_underwater_text?.[1] ?? '-'}</div>
                
                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '4 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 5', gridRow: '4 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.TdT50Ex_color?.[1] ?? 'LTGREEN']}}>{decisionTableData?.data?.Decision_table?.TdT50Ex_value?.[1] ?? '-'}</div>

                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '5 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 1', gridRow: '5 / span 1', background: dtColors['LTGREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '3 / span 4', gridRow: '5 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.tdt0_color?.[1] ?? 'GREY']}}>{decisionTableData?.data?.Decision_table?.tdt0_value?.[1] ?? '-'}</div>

                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '6 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 1', gridRow: '6 / span 1', background: dtColors['LTGREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '3 / span 1', gridRow: '6 / span 1', background: dtColors['GREY']}}></div>
                <div className='decision-table-value' style={{gridColumn: '4 / span 3', gridRow: '6 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.Mwp_magnitude_color?.[1] ?? 'LTGREEN']}}>{decisionTableData?.data?.Decision_table?.Mwp_magnitude_text?.[1] ?? '-'}</div>

                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '7 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 1', gridRow: '7 / span 1', background: dtColors['LTGREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '3 / span 1', gridRow: '7 / span 1', background: dtColors['GREY']}}></div>
                <div className='decision-table-value' style={{gridColumn: '4 / span 3', gridRow: '7 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.Hypocenter_depth_color?.[1] ?? 'RED']}}>{decisionTableData?.data?.Decision_table?.Hypocenter_depth_text ?? '-'}</div>

                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '8 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 1', gridRow: '8 / span 1', background: dtColors['LTGREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '3 / span 1', gridRow: '8 / span 1', background: dtColors['GREY']}}></div>
                <div className='decision-table-value' style={{gridColumn: '4 / span 3', gridRow: '8 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.slab_depth_color?.[1] ?? 'GREY']}}>{decisionTableData?.data?.Decision_table?.slab_depth_text?.[1] ?? '-'}</div>
            </div>
            <div className='decision-table-values-container'>
                <div className='decision-table-value-title' style={{gridColumn: '1 / span 6', gridRow: '1 / span 1'}}>{t('component__decision_table__strike_slip')}</div>
                <div className='decision-table-value' style={{gridColumn: '1 / span 6', gridRow: '2 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.Compatible_close_plate_boundary_color?.[0] ?? 'GREEN']}}>{decisionTableData?.data?.Decision_table?.Compatible_close_plate_boundary_text?.[2] ?? '-'}</div>
                <div className='decision-table-value' style={{gridColumn: '1 / span 6', gridRow: '3 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.prob_underwater_color?.[2] ?? 'GREEN']}}>{decisionTableData?.data?.Decision_table?.prob_underwater_text?.[2] ?? '-'}</div>
                
                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '4 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 5', gridRow: '4 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.TdT50Ex_color?.[2] ?? 'LTGREEN']}}>{decisionTableData?.data?.Decision_table?.TdT50Ex_value?.[2] ?? '-'}</div>

                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '5 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 1', gridRow: '5 / span 1', background: dtColors['LTGREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '3 / span 4', gridRow: '5 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.tdt0_color?.[2] ?? 'GREY']}}>{decisionTableData?.data?.Decision_table?.tdt0_value?.[2] ?? '-'}</div>

                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '6 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 1', gridRow: '6 / span 1', background: dtColors['LTGREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '3 / span 1', gridRow: '6 / span 1', background: dtColors['GREY']}}></div>
                <div className='decision-table-value' style={{gridColumn: '4 / span 3', gridRow: '6 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.Mwp_magnitude_color?.[2] ?? 'LTGREEN']}}>{decisionTableData?.data?.Decision_table?.Mwp_magnitude_text?.[2] ?? '-'}</div>

                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '7 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 1', gridRow: '7 / span 1', background: dtColors['LTGREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '3 / span 1', gridRow: '7 / span 1', background: dtColors['GREY']}}></div>
                <div className='decision-table-value' style={{gridColumn: '4 / span 3', gridRow: '7 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.Hypocenter_depth_color?.[2] ?? 'RED']}}>{decisionTableData?.data?.Decision_table?.Hypocenter_depth_text ?? '-'}</div>

                <div className='decision-table-value' style={{gridColumn: '1 / span 1', gridRow: '8 / span 1', background: dtColors['GREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '2 / span 1', gridRow: '8 / span 1', background: dtColors['LTGREEN']}}></div>
                <div className='decision-table-value' style={{gridColumn: '3 / span 1', gridRow: '8 / span 1', background: dtColors['GREY']}}></div>
                <div className='decision-table-value' style={{gridColumn: '4 / span 3', gridRow: '8 / span 1', background: dtColors[decisionTableData?.data?.Decision_table?.slab_depth_color?.[2] ?? 'GREY']}}>{decisionTableData?.data?.Decision_table?.slab_depth_text?.[2] ?? '-'}</div>
            </div>
        </div>
    )
}

export { DecisionTable }