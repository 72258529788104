import axios from 'axios'
import {ReactComponent as FileDownloadIcon} from '../../assets/file-download-icon.svg'
import {ReactComponent as FileShareIcon} from '../../assets/file-share-icon.svg'
import './BreadcrumbButtonsContainer.css'
import { ApiClient } from '../../services/ApiClient'
import { useState } from 'react'
import { Loading } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { NotificationPanel, NotificationType } from '../NotificationPanel/NotificationPanel'

interface BreadcrumbButtonsContainerProps {
    eventParametersId?: string
}

const BreadcrumbButtonsContainer = ({eventParametersId}: BreadcrumbButtonsContainerProps) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [notificationVisible, setNotificationVisible] = useState<boolean>(false)
    const [notificationTitle, setNotificationTitle] = useState<string>('')
    const [notificationMessage, setNotificationMessage] = useState<string>('')
    const [notificationType, setNotificationType] = useState<NotificationType>('success')
    const {t, i18n} = useTranslation()

    const postUrl = process.env.REACT_APP_MONITOR_XML_POST_URL ?? 'http://localhost:8091'

    const handleDownloadClick = async (): Promise<void> => {
        if (!eventParametersId) return

        const data = await ApiClient.eventParametersApi().getRawEventParametersFile(eventParametersId)
        const blob = new Blob([data?.data?.fileContent ?? ''], { type: 'application/xml' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'monitor.xml'
        a.click()
        URL.revokeObjectURL(url)
    }

    const handlePostClick = async (): Promise<void> => {
        if (!eventParametersId) return
        setLoading(true)
        try {
            const data = await ApiClient.eventParametersApi().getRawEventParametersFile(eventParametersId)
            await axios.post(postUrl, data?.data?.fileContent ?? '', {
                headers: {
                    'Content-Type': 'application/xml'
                }
            })
            setNotificationType('success')
            setNotificationTitle(t('component__breadcrumb_buttons_container__success_title'))
            setNotificationMessage(t('component__breadcrumb_buttons_container__success_body'))
            setNotificationVisible(true)
        } catch(error: any) {
            setNotificationType('error')
            setNotificationTitle(t('component__breadcrumb_buttons_container__error_title'))
            setNotificationMessage(t('component__breadcrumb_buttons_container__error_body', {error: error.message}))
            setNotificationVisible(true)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="breadcrumb-buttons-container">
            <FileDownloadIcon className='breadcrumb-icon-button' onClick={handleDownloadClick} />
            {loading ?
            <div className='breadcrumb-loader'>
                <Loading description="Loading indicator" withOverlay={false} small={true} />
            </div>
            :
            <FileShareIcon className='breadcrumb-icon-button' onClick={handlePostClick} />
            }
            <NotificationPanel
                type={notificationType}
                title={notificationTitle}
                body={notificationMessage}
                visible={notificationVisible}
                setVisible={setNotificationVisible}/>
        </div>
    )
}

export default BreadcrumbButtonsContainer