import './EventPopup.css'
import { Popup } from "react-leaflet"
import { ReducedEvent } from "../../utils"
import { ReactComponent as PopupCloseIcon } from '../../assets/popup-close-icon.svg'
import { useTranslation } from 'react-i18next'
import { MouseEventHandler } from 'react'
import { Link } from 'react-router-dom'
import { EVENT_PATH } from '../../routes/routes'

interface EventPopupProps {
    event: ReducedEvent,
    closePopup: MouseEventHandler<HTMLSpanElement>
}

function EventPopup({event, closePopup}: EventPopupProps) {
    const {t} = useTranslation()

    return (
        <Popup className='event-popup'>
            <div className='event-popup-row event-popup-header'>
                <span className='event-popup-title'>{event.subtitle}</span>
                <span className='event-popup-value' onClick={closePopup}><PopupCloseIcon  style={{cursor: 'pointer'}} /></span>
            </div>
            <div className='event-popup-row'>
                <span className='event-popup-key'>{t('component__popup_event__origin_time')}</span>
                <span className='event-popup-value'>{event.originTime}</span>
            </div>
            <div className='event-popup-row'>
                <span className='event-popup-key'>{t('component__popup_event__latitude')}</span>
                <span className='event-popup-value'>{event.lat}</span>
            </div>
            <div className='event-popup-row'>
                <span className='event-popup-key'>{t('component__popup_event__longitude')}</span>
                <span className='event-popup-value'>{event.lon}</span>
            </div>
            <div className='event-popup-row'>
                <span className='event-popup-key'>{t('component__popup_event__mb')}</span>
                <span className='event-popup-value'>{event.mb}</span>
            </div>
            <div className='event-popup-row'>
                <span className='event-popup-key'>{t('component__popup_event__mwp')}</span>
                <span className='event-popup-value'>{event.mwp}</span>
            </div>
            <div className='event-popup-row'>
                <span className='event-popup-key'>{t('component__popup_event__mwpd')}</span>
                <span className='event-popup-value'>{event.mwpd}</span>
            </div>
            <div className='event-popup-row'>
                <span className='event-popup-key'>{t('component__popup_event__t50ex')}</span>
                <span className='event-popup-value'>{event.t50ex}</span>
            </div>
            <div className='event-popup-row'>
                <span className='event-popup-key'>{t('component__popup_event__t0')}</span>
                <span className='event-popup-value'>{event.t0}</span>
            </div>
            <div className='event-popup-row'>
                <span className='event-popup-key'>{t('component__popup_event__td')}</span>
                <span className='event-popup-value'>{event.td}</span>
            </div>
            <div className='event-popup-row event-popup-footer'>
                <Link reloadDocument to={EVENT_PATH.replace(':eventId', event.eventId.split('/').pop() ?? '')}>{t('component__popup_event__event_details')}</Link>
            </div>
        </Popup>
    )
}

export { EventPopup }