import React, {useState} from 'react'
import {
    IconButton
} from '@carbon/react'
import './DropdownFiltersButton.css'
import DropdownFilters from "../DropdownFilters/DropdownFilters";
import { ReactComponent as IconFilters } from '../../assets/filters.svg'
import {useTranslation} from "react-i18next";


const DropdownFiltersButton = function () {

    const [show, setShow] = useState(false)
    const {t} = useTranslation()

    return <>
        <div className='dd-container dropdown'>
            <IconButton className="filters-icon" enterDelayMs={10000000} label={t('sort')} onClick={() => setShow(!show)}>
                <IconFilters />
            </IconButton>
            {
                show &&
                    <DropdownFilters cancelCallback={() => setShow(false)} />
            }
        </div>
    </>
}

export {
    DropdownFiltersButton
};