import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {NewIntervalType} from "../../components/FiltersDateTimeSelector/FiltersDateTimeSelector";

export interface TimePickerState {
    show: boolean,
    // interval?: SerializableIntervalType,
    fullInterval: NewIntervalType
}

const defaultTimePickerState = {
    show: false,
    // interval: undefined,
    fullInterval: {days: 7}
} as TimePickerState

export const timePickerSlice = createSlice({
    name: 'timepicker',
    initialState: defaultTimePickerState,
    reducers: {
        closeDatePicker: (state) => {
            return {
                ...state,
                show: false,
            }
        },
        toggleDatePicker: (state) => {
            return {
                ...state,
                show: !state.show
            }
        },
        setStoreFullInterval: (state, actionTimePicker: PayloadAction<NewIntervalType>) => {
            return {
                ...state,
                fullInterval: actionTimePicker.payload
            }
        },
        resetTimePicker: (state) => {
            return {
                ...state,
                fullInterval: {days: 7}
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { toggleDatePicker, closeDatePicker, setStoreFullInterval, resetTimePicker } = timePickerSlice.actions

export default timePickerSlice.reducer