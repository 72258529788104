import axios, { AxiosInstance } from 'axios'

const TECTONIC_JSON_URL = process.env.REACT_APP_TECTONIC_JSON_URL ?? 'https://cdn.jsdelivr.net/gh/fraxen/tectonicplates@master/GeoJSON/PB2002_boundaries.json'

const networkClient: AxiosInstance = axios.create({
    baseURL: TECTONIC_JSON_URL,
    headers: {
      "Content-Type": "application/json",
    },
});

const getTectonicData = () => {
    return networkClient.get(TECTONIC_JSON_URL);
}

const TectonicDataClient = {
    getTectonicData
}

export { TectonicDataClient }