import { useEffect, useState } from 'react'
import { DoubleRowDate } from '../DoubleRowDate/DoubleRowDate'
import { DoubleRowRegion } from '../DoubleRowRegion/DoubleRowRegion'
import './CustomTable.css'
import HeaderCell from './HeaderCell'
import {
    EARTHQUAKE_LIST_TABLE,
    GenericEvent,
} from './TableComposer'
import {
    EarthquakeTableEvent,
    TableHeader,
    DATETIME_TAG,
    ZONE_TAG,
    ID_TAG,
    SORT_ASC,
    SORT_DESC,
    COORDINATES_TAG,
    CANCELLED_TAG, DEPTH_TAG, VERSION_TAG, MB_TAG, MWP_TAG, MWPD_TAG
} from './TableUtils'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxCustomHooks'
import { setFilters } from '../../redux/reducers/filters.slice'
import {evaluateHref, evaluateRow} from './utils'
import {DoubleRowGeneric} from "../DoubleRowRegion/DoubleRowGeneric";
import {Orderings} from "../../services/network";

interface CustomTableProps {
    headers: Array<TableHeader>,
    body: Array<EarthquakeTableEvent | any>,
    className: string[],
    type: string,
}

interface SortingProps {
    header: string,
    direction: number
}

const CustomTable = ({headers, body, className, type}: CustomTableProps) => {

    const [copyBody, setCopyBody] = useState(body)
    const [sortedBy, setSortedBy] = useState<SortingProps>({
        header: '',
        direction: SORT_DESC
    })

    const filters = useAppSelector((state) => state.filters)
    const dispatch = useAppDispatch()

    useEffect(() => setCopyBody(body),[body])
    useEffect(() => {
        if(type === EARTHQUAKE_LIST_TABLE) {
            setSortedBy({
                header: filters.sortHeader,
                direction: filters.sortDirection,
            })
        }
    }, [filters])

    const sortingCallbackHandler = (direction: number, tag: string) => {
        console.log('CustomTable => sortingCallbackHandler => ', direction, tag)

        if(type === EARTHQUAKE_LIST_TABLE) {
            const evaulateSortDirection = () => {
                return filters.sortHeader === tag
                    ? (direction === SORT_ASC ? SORT_ASC : SORT_DESC)
                    : SORT_ASC
            }

            const evaluateOrderBy = function() {
                if(tag === DATETIME_TAG) return direction === SORT_ASC ? Orderings.OriginOtAsc : Orderings.OriginOtDesc
                // if(tag === MAGNITUDE_TAG) return direction === SORT_ASC ? 'magnitude_mag-asc' : 'magnitude_mag-desc'
                if(tag === ID_TAG) return direction === SORT_ASC ? Orderings.EventIdAsc : Orderings.EventIdDesc
                if(tag === DEPTH_TAG) return direction === SORT_ASC ? Orderings.DepthAsc : Orderings.DepthDesc
                if(tag === VERSION_TAG) return direction === SORT_ASC ? Orderings.VersionAsc : Orderings.VersionDesc
                if(tag === MB_TAG) return direction === SORT_ASC ? Orderings.MbAsc : Orderings.MbDesc
                if(tag === MWP_TAG) return direction === SORT_ASC ? Orderings.MwpAsc : Orderings.MwpDesc
                if(tag === MWPD_TAG) return direction === SORT_ASC ? Orderings.MwpdAsc : Orderings.MwpdDesc
            }

            dispatch(setFilters({
                ...filters,
                orderby: evaluateOrderBy(),
                sortDirection: evaulateSortDirection(),
                sortHeader: tag,
            }))

            return
        }

        setSortedBy({
            header: tag,
            direction: tag === sortedBy.header ? direction : SORT_ASC // this is the new direction
        })
    }

    const buildTableRow = (element: GenericEvent, index: number, className: string[]) => {

        let key = `${type}-${index}`

        return  <>
            {
                evaluateRow(
                    key, type, `${className.map(c=> `row-${c}`)}`,
                    headers.map((h)=> buildTableCell(element, h.tag, h.cols)),
                    `${'id' in element ? element.id : ''}`,
                    evaluateHref(type, element)
                )
            }
        </>
    }

    const buildTableCell = (element: any, header: string, cols?: number) => {
        let key =`${element.id}-${header}`
        let c = cols ?? 1

        if(header === COORDINATES_TAG) {
            let tokens = element[header].split('#')
            return <DoubleRowGeneric key={key} title={tokens[0]} content={tokens[1]}/>
        }

        if(header === ID_TAG)
            return <DoubleRowGeneric key={key} title={element[header]} content={element[CANCELLED_TAG] ? 'Cancelled' : ''} />

        if(header === DATETIME_TAG)
            return <DoubleRowDate key={key} dateStr={element[header]} className={`cols-${c}`} />

        if(header === ZONE_TAG)
            return <DoubleRowRegion key={key} regionStr={element[header]} className={`cols-${c}`}/>

        return  <DoubleRowGeneric key={key} title={element[header]} content={''} />
    }

    const evaluateMarginRight = () => {
        let elem = document.getElementById(`${type}-content`)
        if(window.navigator.userAgent.indexOf('Firefox') === -1) {
            if(elem) {
                if(elem.scrollHeight > elem.clientHeight) {
                    return '8px'
                }
            }
        }

        return '0px'
    }

    return <>
        <div id={`${type}-header`}
             style={{
                 position: 'sticky',
                 top: '0',
                 minHeight: '26px',
                 background: '#161616',
             }}
        >
            <div className={className.join(' ')} >
                {
                    headers.map((h) =>
                        <HeaderCell
                            key={`header-${h.tag}`}
                            sortHeader={sortedBy.header}
                            sortDirection={sortedBy.direction}
                            {...h}
                            sortingCallback={sortingCallbackHandler}
                        />
                    )
                }
            </div>
        </div>

        <div id={`${type}-content`} style={{height: '0px'}}>
            {
                copyBody.map((event, index) => buildTableRow(event, index, className))
            }
        </div>
    </>
}

export default CustomTable