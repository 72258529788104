import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { RECTANGULAR_GEOFENCE } from '../../components/GeofenceSelector/GeofenceSelector'
import { DEFAULT_MIN_MAGNITUDE, DEFAULT_ORDER_BY, DEFAULT_LIMIT } from '../../utils'
import utc from 'dayjs/plugin/utc'
import {DATETIME_TAG, SORT_DESC} from "../../components/CustomTable/TableUtils";
import {Orderings} from "../../services/network";

dayjs.extend(utc)

export interface Filters {
    starttime?: string,
    endtime?: string,
    minlat?: string,
    maxlat?: string,
    minlon?: string,
    maxlon?: string,
    lat?: string,
    lon?: string,
    // radius?: string,
    minradiuskm?: string,
    maxradiuskm?: string,
    minmag?: string,
    maxmag?: string,
    mindepth?: string,
    maxdepth?: string,
    orderby?: Orderings,
    limit?: number,
    shape: string
    ref_key: string,
    ref_region: string,
    page: number,
    sortDirection: number,
    sortHeader: string,
}

export const defaultFilters: Filters = ({
    minmag: DEFAULT_MIN_MAGNITUDE,
    orderby: DEFAULT_ORDER_BY,
    limit: DEFAULT_LIMIT,
    starttime: dayjs.utc().subtract(7, 'day').format('YYYY-MM-DDT00:00:00.000Z'),
    endtime: undefined,
    shape: RECTANGULAR_GEOFENCE,
    ref_key: '',
    ref_region: '',
    page: 1,
    sortHeader: DATETIME_TAG,
    sortDirection: SORT_DESC,
})

export const filtersSlice = createSlice({
    name: 'filters',
    initialState: defaultFilters,
    reducers: {
        resetFilters: () => {
            return defaultFilters
        },
        setFilters: (state, actionFilters: PayloadAction<Filters>) => {
            return actionFilters.payload
        },
        removeTime: (state) => {
            return {
                ...state,
                starttime: dayjs.utc().subtract(7, 'day').format('YYYY-MM-DDT00:00:00.000Z'),
                endtime: undefined,
            }
        },
        removeMagnitude: (state) => {
            delete state.minmag
            delete state.maxmag
            return state
        },
        removeDepth: (state) => {
            return {
                ...state,
                mindepth: undefined,
                maxdepth: undefined,
            }
        },
        removeGeofence: (state) => {
            return {
                ...state,
                shape: RECTANGULAR_GEOFENCE,
                ref_key: '',
                ref_region: '',
                minlon: undefined,
                maxlon: undefined,
                lon: undefined,
                minlat: undefined,
                maxlat: undefined,
                lat: undefined,
                minradiuskm: undefined,
                maxradiuskm: undefined,
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { resetFilters, setFilters, removeTime, removeMagnitude, removeDepth, removeGeofence } = filtersSlice.actions

export default filtersSlice.reducer