import { ReactComponent as PrevIcon } from '../../assets/previous-slot-icon.svg'
import { ReactComponent as NextIcon } from '../../assets/next-slot-icon.svg'

type CalendarButtonType = 'prev' | 'next'

interface CalendarButtonProps {
    type: CalendarButtonType,
    onClick: any,
    enabled?: boolean
}

function CalendarButton({type, onClick, enabled}: CalendarButtonProps) {

    const getButtonColor = (enabled?: boolean) => {
        return enabled ? '#C6C6C6' : '#525252'
    }

    switch (type) {
        case 'prev':
            return(
                <div className={'calendar-button ' + (enabled ? 'calendar-button-enabled' : 'calendar-button-disabled')} onClick={() => enabled && onClick()}>
                    <PrevIcon fill={getButtonColor(enabled)} />
                </div>
            )
        case 'next':
            return(
                <div className={'calendar-button ' + (enabled ? 'calendar-button-enabled' : 'calendar-button-disabled')} onClick={() => enabled && onClick()}>
                    <NextIcon fill={getButtonColor(enabled)} />
                </div>
            )
    }
}

export { CalendarButton }