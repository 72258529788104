import { useTranslation } from 'react-i18next'
import './DropdownLayers.css'
import { Toggle } from '@carbon/react'

type DropdownLayersProps = {
    isEventDetail: boolean,
    phasesToggled: boolean,
    phasesHandleToggle: Function,
    ellipsoidToggled: boolean,
    ellipsoidHandleToggle: Function,
    tectonicPlatesToggled: boolean,
    tectonicPlatesHandleToggle: Function,
    recentEventsToggled: boolean,
    recentEventsHandleToggle: Function,
    stationsToggled: boolean,
    stationsHandleToggle: Function,
    stationsEventUnassToggled: boolean,
    stationsEventUnassHandleToggle: Function,
    stationsEventAssToggled: boolean,
    stationsEventAssHandleToggle: Function,
}

function DropdownLayers({
    isEventDetail,
    phasesToggled, 
    phasesHandleToggle, 
    ellipsoidToggled, 
    ellipsoidHandleToggle, 
    tectonicPlatesToggled, 
    tectonicPlatesHandleToggle, 
    recentEventsToggled, 
    recentEventsHandleToggle, 
    stationsToggled, 
    stationsHandleToggle,
    stationsEventUnassToggled,
    stationsEventUnassHandleToggle,
    stationsEventAssToggled,
    stationsEventAssHandleToggle,
}: DropdownLayersProps) {
    const {t} = useTranslation()

    return <>
        <div className="dropdown-menu-48">
            <div className='dropdown-menu-container'>
                <p className='title mb-8'>{t('component__dropdown_layers__title')}</p>
                <div className='divider'></div>
                <div className='dropdown-layers-overflowed' id="overflowed">
                    <div className='dropdown-label-toggle-container'>
                        <span className='drpdown-toggle-label'>{t('component__dropdown_layers__phases')}</span>
                        <Toggle
                            className='earlyest-toggle'
                            labelText=''
                            toggled={phasesToggled}
                            onToggle={phasesHandleToggle}
                            hideLabel={true}
                            id="toggle-phases"/>
                        <span className='drpdown-toggle-label'>{t('component__dropdown_layers__ellipsoid')}</span>
                        <Toggle
                            className='earlyest-toggle'
                            labelText=''
                            toggled={ellipsoidToggled}
                            onToggle={ellipsoidHandleToggle}
                            hideLabel={true}
                            id="toggle-ellipsoid"/>
                        <span className='drpdown-toggle-label'>{t('component__dropdown_layers__tectonic')}</span>
                        <Toggle
                            className='earlyest-toggle'
                            labelText=''
                            toggled={tectonicPlatesToggled}
                            onToggle={tectonicPlatesHandleToggle}
                            hideLabel={true}
                            id="toggle-tectonic"/>
                        <span className='drpdown-toggle-label'>{t('component__dropdown_layers__recent_events')}</span>
                        <Toggle
                            className='earlyest-toggle'
                            labelText=''
                            toggled={recentEventsToggled}
                            onToggle={recentEventsHandleToggle}
                            hideLabel={true}
                            id="toggle-events"/>
                        {!isEventDetail &&
                        <>
                        <span className='drpdown-toggle-label'>{t('component__dropdown_layers__stations')}</span>
                        <Toggle
                            className='earlyest-toggle'
                            labelText=''
                            toggled={stationsToggled}
                            onToggle={stationsHandleToggle}
                            hideLabel={true}
                            id="toggle-stations"/>
                        </>
                        }
                        {isEventDetail &&
                        <>
                        <span className='drpdown-toggle-label'>{t('component__dropdown_layers__stations_event_unass')}</span>
                        <Toggle
                            className='earlyest-toggle'
                            labelText=''
                            toggled={stationsEventUnassToggled}
                            onToggle={stationsEventUnassHandleToggle}
                            hideLabel={true}
                            id="toggle-unassociated-stations"/>
                        <span className='drpdown-toggle-label'>{t('component__dropdown_layers__stations_event_ass')}</span>
                        <Toggle
                            className='earlyest-toggle'
                            labelText=''
                            toggled={stationsEventAssToggled}
                            onToggle={stationsEventAssHandleToggle}
                            hideLabel={true}
                            id="toggle-associated-stations"/>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

export { DropdownLayers }