import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import log from 'loglevel'

const initialStateIsLoading: boolean = false

export const isLoadingSlice = createSlice({
  name: 'isLoading',
  initialState: initialStateIsLoading,
  reducers: {
    resetIsLoading: (state) => {
      log.debug('Redux => Resetting IsLoading')
      return initialStateIsLoading
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      log.debug('Redux => Setting IsLoading: ' + action.payload)
      return action.payload
    },
    toggleIsLoading: (state) => {
      log.debug('Redux => Toggling IsLoading')
      return !state
    }
  },
})

// Action creators are generated for each case reducer function
export const { resetIsLoading, setIsLoading, toggleIsLoading } = isLoadingSlice.actions

export const isLoading = (state: RootState) => state.isLoading

export default isLoadingSlice.reducer