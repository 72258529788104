import { PrimaryMagnitude } from "../../utils/eventReducedListCreator"

interface DoubleRowEventSummaryProps {
    lat: string,
    lon: string,
    depth: string,
    mb: string,
    mbStations: string,
    mwp: string,
    mwpStations: string,
    mwpd: string,
    mwpdStations: string,
    primaryMag: PrimaryMagnitude,
}

function DoubleRowEventSummary({lat, lon, depth,  mb, mbStations, mwp, mwpStations, mwpd, mwpdStations, primaryMag}: DoubleRowEventSummaryProps) {
    return (
        <div className='event-summary'>
            <div>
                <span className='event-summary-label'>Lat</span><span className='event-summary-value'>{lat}</span>
            </div>
            <div>
                <span className='event-summary-label'>Lon</span><span className='event-summary-value'>{lon}</span>
            </div>
            <div>
                <span className='event-summary-label'>Depth</span><span className='event-summary-value'>{depth} km</span>
            </div>
            <div>
                <span className='event-summary-label'>Mb</span><span className={`event-summary-value ${primaryMag === 'mb' && 'event-summary-value-primary'}`}>{mb} ({mbStations})</span>
            </div>
            <div>
                <span className='event-summary-label'>Mwp</span><span className={`event-summary-value ${primaryMag === 'mwp' && 'event-summary-value-primary'}`}>{mwp} ({mwpStations})</span>
            </div>
            <div>
                <span className='event-summary-label'>Mwpd</span><span className={`event-summary-value ${primaryMag === 'mwpd' && 'event-summary-value-primary'}`}>{mwpd} ({mwpdStations})</span>
            </div>
        </div>
    )
}

export { DoubleRowEventSummary }

