import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducedEventParameters } from '../../utils/eventReducedListCreator'
import { RootState } from '../store'

const initialStateReducedEventsList: ReducedEventParameters[] = []

export const reducedEventsListSlice = createSlice({
  name: 'reducedEventsList',
  initialState: initialStateReducedEventsList,
  reducers: {
    resetReducedEventsList: (state) => {
      return initialStateReducedEventsList
    },
    setReducedEventsList: (state, action: PayloadAction<ReducedEventParameters[]>) => {
      return action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setReducedEventsList, resetReducedEventsList } = reducedEventsListSlice.actions

export const getReducedEventsList = (state: RootState) => state.reducedEventsList

export default reducedEventsListSlice.reducer