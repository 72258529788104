import React from 'react'
import './EventMarker.css'
import { Marker } from 'react-leaflet'
import Leaflet from 'leaflet'

interface EventMarkerProps {
    markerColor: string,
    position: [number, number],
    riseOnHover: boolean,
    isSecondary?: boolean,
    children?: React.ReactNode,
}

const EventMarker = ({ markerColor, position, riseOnHover, isSecondary, children }: EventMarkerProps) => {
    const secondaryMarkerColor = `${markerColor}80`
    const markerHtml = `
        <div class="circle-container" style="background: ${secondaryMarkerColor};">
            <div class="inner-circle" style="border-color: ${markerColor};${isSecondary ? ` background: ${secondaryMarkerColor};"` : ''}"></div>
        </div>`

    return (
        <Marker
            position={position}
            riseOnHover={riseOnHover}
            zIndexOffset={1000}
            icon={new Leaflet.DivIcon({
                html: `${markerHtml}`,
                className: 'no-show',
                iconSize: [32, 32],
                iconAnchor: [16, 16],
                popupAnchor: [0, -16]
            })} >
            {children}
        </Marker>
    )
}

export default EventMarker
