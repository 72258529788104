import { MouseEventHandler } from "react"
import './EarlyestButton.css'

interface EarlyestButtonProps {
    style: 'primary' | 'secondary'
    text: string
    onClick: MouseEventHandler
}

function EarlyestButton({style, text, onClick}: EarlyestButtonProps) {
    const buttonStyle = 'earlyest-button ' + (style === 'primary' ? 'earlyest-button-primary' : 'earlyest-button-secondary')

    return (
        <div className={buttonStyle} onClick={onClick}>
            {text}
        </div>
    )
}

export { EarlyestButton }