import { useTranslation } from "react-i18next";
import React, {useEffect, useState} from 'react';
import { TextInput } from '@carbon/react';
import './GeofenceSelector.css'
import { isValidLatitude, isValidLongitude, isValidMinMaxLatitude, isValidMinMaxLongitude, isValidMinMaxRadius, isValidRadius, validNumeric } from "../../utils";
import { latitudeErrorText, longitudeErrorText, maxRadiusErrorText, minRadiusErrorText, radiusErrorText } from "../DropdownFilters/messages";
import { shouldHaveRadius } from "../DropdownFilters/utils";
import {EeDropdown, Option, SelectedItem} from "../EeDropdown/EeDropdown";


interface GeofenceSelectorProps {
    onUpdate: (reference: CoordinateRef, shape: string) => void
    shape: string
    selectedGeofence: CoordinateRef
}

export interface CoordinateRef {
    key: string,
    region: string,
    min_lat?: string,
    max_lat?: string,
    min_lon?: string,
    max_lon?: string,
    lat?: string,
    lon?: string,
    minradius?: string,
    maxradius?: string,
}

export const RECTANGULAR_GEOFENCE = 'rectangular'
export const CIRCLE_GEOFENCE = 'circle'
export const CROWN_GEOFENCE = 'crown'

const   GeofenceSelector = ({onUpdate, shape, selectedGeofence}: GeofenceSelectorProps) => {

    const {t} = useTranslation()
    const [geofenceShape, setGeofenceShape] = useState(shape)
    const [geofenceRef, setGeofenceRef] = useState<CoordinateRef>(selectedGeofence ?? {
        key: "",
        region: t('select_region'),
    })

    const geographic_area_type: Option[] = [
        {key: RECTANGULAR_GEOFENCE, value: t('component__dropdown_filters__geographic_rectangular')},
        {key: CROWN_GEOFENCE, value: t('component__dropdown_filters__geographic_crown')},
    ]

    const towns_list: Array<CoordinateRef> = [
        { key: "", region: t('select_region'), },
        { key: "world", region: t('world'), min_lat: "-90", max_lat: "90", min_lon: "-180", max_lon: "180", lat: "0", lon: "0" },
        { key: "mediterranean_region", region: t('mediterranean_region'), min_lat: "27", max_lat: "48", min_lon: "-7", max_lon: "37.5", lat: "37.5", lon: "15.25" }, 
        { key: "italy", region: t('italy'), min_lat: "35", max_lat: "49", min_lon: "5", max_lon: "20", lat: "42", lon: "12.5" },
    ]

    useEffect(() => onUpdate(geofenceRef, geofenceShape), [geofenceRef, geofenceShape])

    const update = (type: string, ref: CoordinateRef) => {
        let { key, region, min_lat, max_lat, min_lon, max_lon, lat, lon, } = ref
        if(type === RECTANGULAR_GEOFENCE) {
            setGeofenceRef({key, region, min_lat, max_lat, min_lon, max_lon})
        } else if(type === CIRCLE_GEOFENCE) {
            setGeofenceRef({key, region, lat, lon, minradius: '0'})
        } else {
            setGeofenceRef({key, region, lat, lon,})      
        }
    }

    const selectGeofenceShape = (data: SelectedItem) => {
        let shape = data.selectedItem.key
        setGeofenceShape(shape)
        if(shape === RECTANGULAR_GEOFENCE) {
            const ref = towns_list.find(c => c.region === geofenceRef.region)
            if(ref){
                update(shape, ref)
            }
        } else {
            setGeofenceRef({key: "", region: "",})
        }
    }
    
    const updateFilters = (key: string, value: string, resetRegion:boolean=false) => {
        // it resets the selected region on user input
        let minradius = undefined
        if(shape !== RECTANGULAR_GEOFENCE) {
            if(shape === CIRCLE_GEOFENCE)
                minradius = '0'
            else 
                minradius = geofenceRef.minradius
        }
        
        setGeofenceRef({
            ...geofenceRef, 
            minradius,
            [key]: value,            
            key: resetRegion ? "" : geofenceRef.key,
            region: resetRegion ? "" : geofenceRef.region,
        })
    }

    const setMinLatitude = (e: React.FormEvent<HTMLInputElement>) => updateFilters('min_lat', e.currentTarget.value, true)
    const setMaxLatitude = (e: React.FormEvent<HTMLInputElement>) => updateFilters('max_lat', e.currentTarget.value, true)
    const setMinLongitude = (e: React.FormEvent<HTMLInputElement>) => updateFilters('min_lon', e.currentTarget.value, true)
    const setMaxLongitude = (e: React.FormEvent<HTMLInputElement>) => updateFilters('max_lon', e.currentTarget.value, true)
    const setLatitude = (e: React.FormEvent<HTMLInputElement>) => updateFilters('lat', e.currentTarget.value, true)
    const setLongitude = (e: React.FormEvent<HTMLInputElement>) => updateFilters('lon', e.currentTarget.value, true)
    const setRadius = (e: React.FormEvent<HTMLInputElement>) => updateFilters('maxradius', e.currentTarget.value)
    const setMinRadius = (e: React.FormEvent<HTMLInputElement>) => updateFilters('minradius', e.currentTarget.value)
    const setMaxRadius = (e: React.FormEvent<HTMLInputElement>) => updateFilters('maxradius', e.currentTarget.value)

    const buildCircularGeofence = () => {
        return <>
        <div className='mg-label-container mb-32'>
            <div
                className='me-2 mg-label'>
                <p className='label mg-label mb-8'>{t('latitude')}</p>
                <TextInput
                    labelText=''
                    className="input"
                    id="test1"
                    hideLabel
                    placeholder={'e.g. 90.00'}
                    value={geofenceRef.lat ?? ''}
                    invalid={!isValidLatitude(geofenceRef.lat ?? '', !validNumeric(geofenceRef.maxradius ?? ''))}
                    invalidText={latitudeErrorText(geofenceRef.lat ?? '', undefined, geofenceRef.minradius, geofenceRef.maxradius, t)}
                    onChange={setLatitude}
                />
            </div>
            <div
                className='ms-2 me-2 mg-label'>
                <p className='label mg-label mb-8'>{t('longitude')}</p>
                <TextInput
                    labelText=''
                    className="input"
                    id="test2"
                    hideLabel
                    onChange={setLongitude}
                    placeholder={'e.g. 180.00'}
                    invalid={!isValidLongitude(geofenceRef.lon ?? '', !validNumeric(geofenceRef.maxradius ?? ''))}
                    invalidText={longitudeErrorText(geofenceRef.lon ?? '', undefined, geofenceRef.minradius, geofenceRef.maxradius, t)}
                    value={geofenceRef.lon ?? ''}
                />
            </div>
            <div
                className='ms-2 mg-label'>
                <p className='label mg-label mb-8'>{t('radius')}</p>
                <TextInput
                    labelText=''
                    className="input"
                    id="test3"
                    hideLabel
                    placeholder={'e.g. 800.00'}
                    value={geofenceRef.maxradius ?? ''}
                    invalid={!isValidRadius(geofenceRef.maxradius ?? '', !shouldHaveRadius(shape, geofenceRef.lat, geofenceRef.lon))}
                    invalidText={radiusErrorText(geofenceRef.minradius ?? '', geofenceRef.maxradius ?? '', t)}
                    onChange={setRadius}
                />
            </div>
        </div>
    </>
    }

    const buildRectangularGeofence = () => {
        return <>
        <div className='mg-label-container mt-24 mb-32'>
            <div
                className='me-2 mg-label'>
                <p className='label F4F4F4 mb-8'>{t('component__dropdown_filters__latmin')}</p>
                <TextInput
                    labelText=''
                    className="input"
                    id="test4"
                    hideLabel
                    placeholder={'e.g. -90.00'}
                    invalid={!(isValidLatitude(geofenceRef.min_lat ?? '', true) && isValidMinMaxLatitude(geofenceRef.min_lat ?? '', geofenceRef.max_lat ?? '', true))}
                    onChange={setMinLatitude}
                    value={selectedGeofence.min_lat ?? ''}
                    invalidText={latitudeErrorText(geofenceRef.min_lat ?? '', geofenceRef.max_lat ?? '', geofenceRef.minradius, geofenceRef.maxradius, t)}
                />
            </div>
            <div
                className='ms-2 me-2 mg-label'>
                <p className='label mg-label mb-8'>{t('component__dropdown_filters__latmax')}</p>
                <TextInput
                    labelText=''
                    className="input"
                    id="test5"
                    hideLabel
                    placeholder={'e.g. 90.00'}
                    onChange={setMaxLatitude}
                    invalid={!(isValidLatitude(geofenceRef.max_lat ?? '', true) && isValidMinMaxLatitude(geofenceRef.min_lat ?? '', geofenceRef.max_lat ?? '', true))}
                    value={selectedGeofence.max_lat ?? ''}
                    invalidText={latitudeErrorText(geofenceRef.min_lat ?? '', geofenceRef.max_lat ?? '', geofenceRef.minradius, geofenceRef.maxradius, t)}
                />
            </div>
        </div>
        <div className='mg-label-container mb-32'>
            <div
                className='me-2 mg-label'>
                <p className='label mg-label mb-8'>{t('component__dropdown_filters__lonmin')}</p>
                <TextInput
                    labelText=''
                    className="input"
                    id="test6"
                    hideLabel
                    placeholder={'e.g. -180.00'}
                    onChange={setMinLongitude}
                    value={geofenceRef.min_lon ?? ''}
                    invalid={!(isValidLongitude(geofenceRef.min_lon ?? '') && isValidMinMaxLongitude(geofenceRef.min_lon ?? '', geofenceRef.max_lon ?? ''))}
                    invalidText={longitudeErrorText(geofenceRef.min_lon ?? '', geofenceRef.max_lon ?? '', geofenceRef.minradius, geofenceRef.maxradius, t)}
                />
            </div>
            <div
                className='ms-2 me-2 mg-label'>
                <p className='label mg-label mb-8'>{t('component__dropdown_filters__lonmax')}</p>
                <TextInput
                    labelText=''
                    className="input"
                    id="test7"
                    hideLabel
                    placeholder={'e.g. 180.00'}
                    onChange={setMaxLongitude}
                    value={selectedGeofence.max_lon ?? ''}
                    invalid={!(isValidLongitude(geofenceRef.max_lon ?? '') && isValidMinMaxLongitude(geofenceRef.min_lon ?? '', geofenceRef.max_lon ?? ''))}
                    invalidText={longitudeErrorText(geofenceRef.min_lon ?? '', geofenceRef.max_lon ?? '', geofenceRef.minradius, geofenceRef.maxradius, t)}
                />
            </div>
        </div>
    </>
    }

    const buildCrownGeofence = () => {
        return <>
        <div className='mg-label-container mt-24 mb-32'>
            <div
                className='me-2 mg-label'>
                <p className='label mg-label mb-8'>{t('component__dropdown_filters__latitude')}</p>
                <TextInput
                    labelText=''
                    className="input"
                    id="test8"
                    hideLabel
                    placeholder={'e.g. 90.00'}
                    value={selectedGeofence.lat ?? ''}
                    onChange={setLatitude}
                    invalid={!isValidLatitude(geofenceRef.lat ?? '', !(validNumeric(geofenceRef.maxradius ?? '') || validNumeric(geofenceRef.minradius ?? '')))}
                    invalidText={latitudeErrorText(geofenceRef.lat ?? '', undefined, geofenceRef.minradius, geofenceRef.maxradius, t)}
                />
            </div>
            <div
                className='ms-2 me-2 mg-label'>
                <p className='label mg-label mb-8'>{t('component__dropdown_filters__longitude')}</p>
                <TextInput
                    labelText=''
                    className="input"
                    id="test9"
                    hideLabel
                    placeholder={'e.g. 180.00'}
                    value={selectedGeofence.lon ?? ''}
                    onChange={setLongitude}
                    invalid={!isValidLongitude(geofenceRef.lon ?? '', !(validNumeric(geofenceRef.maxradius ?? '') || validNumeric(geofenceRef.minradius ?? '')))}
                    invalidText={longitudeErrorText(geofenceRef.lon ?? '', undefined, geofenceRef.minradius, geofenceRef.maxradius, t)}
                />
            </div>
        </div>
        <div className='mg-label-container mb-32'>
            <div
                className='me-2 mg-label'>
                <p className='label mg-label mb-8'>{t('component__dropdown_filters__min_radius')}</p>
                <TextInput
                    labelText=''
                    className="input"
                    id="test10"
                    placeholder={'e.g. 0.00'}
                    onChange={setMinRadius}
                    value={selectedGeofence.minradius ?? ''}
                    invalid={!(isValidRadius(selectedGeofence.minradius ?? '', !shouldHaveRadius(shape, selectedGeofence.lat, selectedGeofence.lon)) && isValidMinMaxRadius(selectedGeofence.minradius ?? '', selectedGeofence.maxradius ?? '', !shouldHaveRadius(shape, selectedGeofence.lat, selectedGeofence.lon)))}
                    invalidText={minRadiusErrorText(selectedGeofence.minradius ?? '', selectedGeofence.maxradius ?? '', t)}
                />
            </div>
            <div
                className='ms-2 me-2 mg-label'>
                <p className='label mg-label mb-8'>{t('component__dropdown_filters__max_radius')}</p>
                <TextInput
                    labelText=''
                    className="input"
                    id="test11"
                    hideLabel
                    placeholder={'e.g. 800.00'}
                    onChange={setMaxRadius}
                    value={selectedGeofence.maxradius ?? ''}
                    invalid={!(isValidRadius(selectedGeofence.maxradius ?? '', !shouldHaveRadius(shape, selectedGeofence.lat, selectedGeofence.lon)))}
                    invalidText={maxRadiusErrorText(selectedGeofence.minradius ?? '', selectedGeofence.maxradius ?? '', t)}
                />
            </div>
        </div>
    </>
    }

    const buildGeofenceInput = () => {
        if(shape === RECTANGULAR_GEOFENCE)
            return buildRectangularGeofence()
        if(shape === CROWN_GEOFENCE)
            return buildCrownGeofence()
    }

    return <>
        <p className='subtitle mb-16'>{t('component__dropdown_filters__where')}</p>
        <p className='label mb-8'>{t('component__dropdown_filters__where_type_label')}</p>

        <EeDropdown
            key={`ee-dropdown-geofence-selector`}
            id={'geofence-selector'}
            selectedItem={geographic_area_type.findIndex((gType) => gType.key === shape )}
            label={'where_type_label'}
            options={geographic_area_type}
            callback={selectGeofenceShape}
            />
        {
            buildGeofenceInput()
        }
    </>
}

export default GeofenceSelector