import './StationsHeader.css'
import { ReactComponent as ShowMapIcon } from '../../assets/show-map-icon.svg'
import { ReactComponent as ShowListIcon } from '../../assets/show-list-icon.svg'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

interface StationsHeaderProps {
    showMap: boolean,
    setShowMap: Dispatch<SetStateAction<boolean>>,
    totalStations?: number,
    totalActiveStations?: number
}
function StationsHeader({showMap, setShowMap, totalStations, totalActiveStations}: StationsHeaderProps) {
    const {t} = useTranslation()
    return (
        <div className={`stations-header-container ${showMap ? ' stations-header-container-gradient' : ' stations-header-container-no-gradient'}`}>
            <div className='stations-header-title'>
                <span className='stations-header-title-name'>{t('component__stations_header__stations')}</span>
                <div></div>
                {showMap ?
                <>
                <span className='stations-header-title-cta' onClick={() => setShowMap(false)}>{t('component__stations_header__show_list')}</span>
                <ShowListIcon className='stations-header-title-icon' onClick={() => setShowMap(false)} />
                </>
                :
                <>
                <span className='stations-header-title-cta' onClick={() => setShowMap(true)}>{t('component__stations_header__show_map')}</span>
                <ShowMapIcon className='stations-header-title-icon' onClick={() => setShowMap(true)} />
                </>
                }
            </div>
            <div className='stations-header-sub'>
                <span className='stations-header-sub-text stations-header-sub-text-hl'>{totalStations ?? '0'}</span>
                <span className='stations-header-sub-text'>{t('component__stations_header__total_stations')}</span>
                <span className='stations-header-sub-text stations-header-sub-text-hl'>{totalActiveStations ?? '0'}</span>
                <span className='stations-header-sub-text'>{t('component__stations_header__total_active_stations')}</span>
            </div>
        </div>
    )
}

export { StationsHeader }