import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxCustomHooks';
import { setNowTime } from '../../redux/reducers/nowTime.slice';
import { DATE_FORMAT_DEFAULT_WITH_UTC } from '../../utils'
import './RealtimeClock.css'

function RealtimeClock() {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const { i18n } = useTranslation()
    const nowTime = useAppSelector((state) => state.nowTime)

    dayjs.extend(utc)
    dayjs.locale(i18n.language)

    const refreshClock = () => {
        dispatch(setNowTime(dayjs.utc().toISOString()))
    }

    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000)
        return () => clearInterval(timerId)
    }, [])

    return (
        <span className='clock-component'>
            <span className='clock-component-pre'>{t('component__realtimeclock__now')}</span><span className='clock-component-value'>{dayjs.utc(nowTime).format(DATE_FORMAT_DEFAULT_WITH_UTC)}</span>
        </span>
    )
}
export { RealtimeClock }