import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks"
import './FiltersBox.css'
import { useTranslation } from "react-i18next"
import { chipText, evaluateDatetimeChip, evaluateDepthChip, evaluateGeofenceChip, evaluateMagnitudeChip } from "./filtersBoxUtils"
import {
    removeDepth,
    removeGeofence,
    removeMagnitude,
    removeTime,
    resetFilters
} from "../../redux/reducers/filters.slice"
import {
    Button
} from '@carbon/react';
import {batch} from "react-redux";
import {resetTimePicker} from "../../redux/reducers/timepicker.slice";

interface StrippedFilters {
    time?: string,
    magnitude?: string,
    geofence?: string,
    depth?: string,
    version?: string,
}

const FiltersBox = () => {

    const {t} = useTranslation()
    const filters = useAppSelector((state) => state.filters)
    const [localFilters, setLocalFilters] = useState<StrippedFilters>({})
    const dispatch = useAppDispatch()

    useEffect(() => {
        let stripped:StrippedFilters = {}

        let datetimeChip = evaluateDatetimeChip(filters)
        if(datetimeChip) {
            stripped = {
                ...stripped,
                [datetimeChip[0]]: datetimeChip[1]
            }
        }


        // minmag and maxmag are not both mandatory
        let magnitudeChip = evaluateMagnitudeChip(filters)
        if(magnitudeChip){
            stripped = { ...stripped, 'magnitude': magnitudeChip }
        }

        // mindepth and maxdepth are not both mandatory
        let depthChip = evaluateDepthChip(filters)
        if(depthChip){
            stripped = { ...stripped, 'depth': depthChip }
        }

        let geofenceChip = evaluateGeofenceChip(filters, t)
        if(geofenceChip){
            stripped = { ...stripped, 'geofence': geofenceChip }
        }

        setLocalFilters(stripped)
    }, [filters])

    const removeFilter = function (chip: string) {
        switch (chip) {
            case 'component__filters_box__time_utc':
                batch(() => {
                    dispatch(resetTimePicker())
                    dispatch(removeTime())
                })
                break;
            case 'magnitude':
                dispatch(removeMagnitude())
                break;
            case 'depth':
                dispatch(removeDepth())
                break;
            case 'geofence':
                dispatch(removeGeofence())
                break
            default:
                console.log('not implemented', chip)
                return
        }
    }

    return(
        <div className="filterbox">
            <div className="filterbox-container">
                {
                    Object.entries(localFilters).map((e, index) =>
                        <div key={index} className={'filterbox-chip'} onClick={() => removeFilter(e[0])}>
                            <span className="title">{t(e[0])}: </span>
                            <span className="text">{chipText(e[1], t)}</span>
                            <span className={"text"}>X</span>
                        </div>
                    )
                }
            </div>
            <Button
                className="bea-btn-transparent remove-button"
                onClick={() => {
                    batch(() => {
                        dispatch(resetFilters())
                        dispatch(resetTimePicker())
                    })
                }}
            >
                {t('component__filters_box__remove_filters')}
            </Button>
        </div>
    )
}

export {
    FiltersBox
}