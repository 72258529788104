import dayjs from 'dayjs'
import 'dayjs/locale/it'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './DoubleRowDate.css'

interface DoubleRowDateParams {
    dateStr: string,
    className?: string,
    changeTimezone?: boolean
}

function DoubleRowDate({dateStr, className}: DoubleRowDateParams) {
    const { i18n } = useTranslation();
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.locale(i18n.language)

    let [date, setDate] = useState('--')
    let [time, setTime] = useState('--')

    useEffect(() => {
        let d = dayjs(dateStr).tz('UTC')
        setDate(d.format('YYYY-MM-DD'))
        setTime(`${d.format('HH:mm:ss.SSS')}`)
    }, [dateStr])


    return (
        <div className={`doublerowdate-container ${className}`}>
            <span className='doublerowdate-date'>{date}</span>
            <span className='doublerowdate-time'>{time}</span>
        </div>
    )
}

export { DoubleRowDate }