import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Channel, Station } from '../../services/network'
import { EeTable, EeTableDataRow, EeTableHeader } from '../EeTable/EeTable'
import { LatencyDisplay } from '../StationsTable/LatencyDisplay'
import './StationTable.css'

interface StationTableProps {
    station: Station
}

function StationTable({station}: StationTableProps) {
    const {t} = useTranslation()

    const generateChannelsDataRow = (channels?: Channel[]): EeTableDataRow[] => {
        if (!channels || channels.length === 0) return []
        return channels.map((channel) => ({
            cells: {
                id: channel.name ?? '--',
                chan: channel.name ?? '--',
                streams: channel.streams ?? '--',
                hz: channel.hz ?? '--',
                lat: channel.lat ?? '--',
                lon: channel.lon ?? '--',
                elev: channel.elev ?? '--',
                cmpAz: channel.cmpAz ?? '--',
                cmpDip: channel.cmpDip ?? '--',
                gain: channel.gain ?? '--',
                staCorr: channel.staCorr ?? '--',
                latency: {
                    value: <LatencyDisplay latency={+(channel.latency ?? '-1')} chans={[]} />,
                    sortableValue: +(channel.latency ?? '-1')
                },
                nUnassoc: channel.nUnassoc ?? '--',
                qualityWt: channel.qualityWt ?? '--',
                notContigLevel: channel.notContigLevel ?? '--',
                conflictDtLevel: channel.conflctDtlevel ?? '--',
            }
        }))
    }

    const headers: EeTableHeader[] = [
        {key: 'chan', label: t('component__station_table__chan'), sortable: true, spaceFraction: 2},
        {key: 'streams', label: t('component__station_table__streams'), sortable: true, spaceFraction: 3},
        {key: 'hz', label: t('component__station_table__hz'), sortable: true, spaceFraction: 2},
        {key: 'lat', label: t('component__station_table__lat'), sortable: true, spaceFraction: 3},
        {key: 'lon', label: t('component__station_table__lon'), sortable: true, spaceFraction: 3},
        {key: 'elev', label: t('component__station_table__elev'), sortable: true, spaceFraction: 3},
        {key: 'cmpAz', label: t('component__station_table__cmp_az'), sortable: true, spaceFraction: 3},
        {key: 'cmpDip', label: t('component__station_table__cmp_dip'), sortable: true, spaceFraction: 3},
        {key: 'gain', label: t('component__station_table__gain'), sortable: true, spaceFraction: 4},
        {key: 'staCorr', label: t('component__station_table__sta_corr'), sortable: true, spaceFraction: 3},
        {key: 'latency', label: t('component__station_table__latency'), sortable: true, spaceFraction: 3},
        {key: 'nUnassoc', label: t('component__station_table__n_unassoc'), sortable: true, spaceFraction: 3},
        {key: 'qualityWt', label: t('component__station_table__quality_wt'), sortable: true, spaceFraction: 3},
        {key: 'notContigLevel', label: t('component__station_table__not_contig_level'), sortable: true, spaceFraction: 4},
        {key: 'conflictDtLevel', label: t('component__station_table__conflict_dt_level'), sortable: true, spaceFraction: 4},
    ]

    const data: EeTableDataRow[] = useMemo(() => generateChannelsDataRow(station.chans), [station])

    return(
        <EeTable
            className='chans-table'
            headers={headers}
            data={data} />
    )
}

export { StationTable }