/* tslint:disable */
/* eslint-disable */
/**
 * EarlyEst Dashboard API
 * All the APIs of the EarlyEst Dashboard
 *
 * The version of the OpenAPI document: 2.16.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const Orderings = {
    OriginOtAsc: 'origin_ot-asc',
    OriginOtDesc: 'origin_ot-desc',
    MagnitudeMagAsc: 'magnitude_mag-asc',
    MagnitudeMagDesc: 'magnitude_mag-desc',
    EventIdAsc: 'event_id-asc',
    EventIdDesc: 'event_id-desc',
    MbAsc: 'mb-asc',
    MbDesc: 'mb-desc',
    MwpAsc: 'mwp-asc',
    MwpDesc: 'mwp-desc',
    MwpdAsc: 'mwpd-asc',
    MwpdDesc: 'mwpd-desc',
    VersionAsc: 'version-asc',
    VersionDesc: 'version-desc',
    DepthAsc: 'depth-asc',
    DepthDesc: 'depth-desc'
} as const;

export type Orderings = typeof Orderings[keyof typeof Orderings];



