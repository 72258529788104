import React, {useEffect, useState} from 'react';
import './FiltersDateTimeSelector.css'
import { useTranslation } from "react-i18next";
import {DatePicker, DatePickerInput, TextInput} from '@carbon/react'
import moment from "moment";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxCustomHooks";
import {setStoreFullInterval} from "../../redux/reducers/timepicker.slice";
import {validInterval} from "./utils";
import {EeDropdown, Option} from "../EeDropdown/EeDropdown";

export interface RelativeRange {
    days: number
}

export interface AbsoluteRange {
    startDate: string,
    startHour: string,
    endDate?: string,
    endHour?: string
}

export type NewIntervalType = RelativeRange | AbsoluteRange

export interface RelativeIntervalType {
    days?: number
}

export interface SerializableAbsolute {
    startDate: string | null,
    endDate: string | null,
}

export type SerializableIntervalType = SerializableAbsolute | RelativeIntervalType

interface DateTimeSelectorProps {
    onUpdate: (interval: NewIntervalType) => void;
}

interface SelectedItem {
    selectedItem: Option
}

// memo:
// in teoria il default dovrebbe essere 7 days

const FiltersDateTimeSelector = function({onUpdate}: DateTimeSelectorProps) {

    const {t} = useTranslation()
    const [initialItem, setInitialItem] = useState<number>(0)

    const [startDate, setStartDate] = useState('')
    const [tmpStartDate, setTmpStartDate] = useState<string>('')
    const [isChangingStartDate, setIsChangingStartDate] = useState(false)
    
    const [startHour, setStartHour] = useState('')
    const [tmpStartHour, setTmpStartHour] = useState<string>('')
    const [isChangingStartHour, setIsChangingStartHour] = useState(false)
    
    const [endDate, setEndDate] = useState<string|undefined>('')
    const [endHour, setEndHour] = useState<string|undefined>('')
    const [tmpEndHour, setTmpEndHour] = useState<string>('')
    const [isChangingEndHour, setIsChangingEndHour] = useState(false)
    const [invalid, setInvalid] = useState(false)

    const interval = useAppSelector((state) => state.timepicker.fullInterval)
    const dispatch = useAppDispatch()

    const findOptionIndex = function(key: string): number {
        let index = options.findIndex((o) => o.key === key)
        return index >= 0 ? index : 0;
    }

    // Here we set the initial state of the dropdown.
    // It should be necessary in further requests
    // useEffect[interval]
    useEffect(() => {
        const tag = `FiltersDateTimeSelector => useEffect[interval] =>`
        if(!interval) {
            console.log(tag, 'no interval')
            setInitialItem(0)
            return
        }

        console.log(tag, interval)

        if('days' in interval) {
            if (interval.days) {
                setInitialItem(findOptionIndex(`${interval.days}`))
            }
            return
        }

        if('startDate' in interval) {
            console.log(tag, 'startDate')
            setInitialItem(options.length - 1)
            return
        }

        console.log(tag, 'nothing')
    }, [interval])

    // Here we populate the boxes accordingly to the interval selected
    // useEffect[interval] 2
    useEffect(() => {
        console.log('FiltersDateTimeSelector => useEffect[interval] 2 =>', interval)
        if(!interval) {
            console.log('FiltersDateTimeSelector => useEffect[interval] 2 => no interval')
            return;
        }
        console.log('FiltersDateTimeSelector => useEffect[interval] 2 => ', interval)
        if('days' in interval) {
            setStartDate(moment().subtract(interval.days, 'days').format('DD/MM/YYYY'))
            setStartHour('00:00:00')
            setEndDate(moment().format('DD/MM/YYYY'))
            setEndHour( '23:59:59')
        } else if ('startDate' in interval) {
            setStartDate(interval.startDate)
            setStartHour(interval.startHour)
            setEndDate(interval?.endDate ?? '')
            setEndHour( interval?.endHour ?? '')
        }
    }, [interval])

    // useEffect[interval] 3
    useEffect(() => {
        if(!interval) {
            console.log('FiltersDateTimeSelector => useEffect[interval] 3 => no interval')
            return;
        }

        setInvalid(!validInterval(interval))
    }, [interval])

    const handleStartDateSelection = function(selected: SelectedItem) {
        let {key} = selected.selectedItem
        console.log('FiltersDateTimeSelector => handleStartDateSelection =>', key)
        if(key === 'custom') {
            const _interval = {
                startDate: startDate,
                startHour: startHour,
                endDate: endDate,
                endHour: endHour
            }
            console.log('FiltersDateTimeSelector => _interval =>', _interval)
            dispatch(setStoreFullInterval(_interval))
        } else{
            dispatch(setStoreFullInterval({days: Number(key)}))
            setEndDate(moment().format('DD/MM/YYYY'))
            setEndHour( '23:59:59')
        }
        setInitialItem(findOptionIndex(key))
    }

    const handleStartDateInput = (dates: any) => {
        // this is triggered only when the date is typed
        const [inputDate] = dates
        console.log('FiltersDateTimeSelector => handleStartDateInput =>', inputDate as Date)
        let text = moment(inputDate).format('DD/MM/YYYY')
        console.log('FiltersDateTimeSelector => handleStartDateInput =>', text)
        setInitialItem(options.length -1)

        dispatch(setStoreFullInterval({
            startDate: text,
            startHour,
            endDate,
            endHour
        }))
    }

    const handleEndDateSelection = (dates: any) => {
        // this is triggered only when the date is typed
        const [inputDate] = dates
        console.log('FiltersDateTimeSelector => handleEndDateSelection => inputDate =>', inputDate as Date)
        let text = ''
        let _endHour: string = ''
        if(inputDate){
            text = moment(inputDate).format('DD/MM/YYYY')
            if(endHour) {
                _endHour = endHour === '' ? '23:59:59' : endHour
            } else {
                _endHour = '23:59:59'
            }
        }

        setInitialItem(options.length -1)

        dispatch(setStoreFullInterval({
            startDate,
            startHour,
            endDate: text,
            endHour: _endHour
        }))
    }

    const handleStartDateBlur = () => {
        // this is triggered only when the date is typed
        console.log('FiltersDateTimeSelector => handleStartDateBlur => startDate =>', startDate)
        console.log('FiltersDateTimeSelector => handleStartDateBlur => tmpStartDate =>', tmpStartDate)
    }

    const handleStartDateFocus = () => {
        // this is triggered only when the date is typed
        console.log('FiltersDateTimeSelector => handleStartDateFocus =>')
        setIsChangingStartDate(true)
        // if new date is different from old
    }

    const handleStartHourFocus = () => {
        // this is triggered only when the Hour is typed
        console.log('FiltersDateTimeSelector => handleStartHourFocus =>')
        setTmpStartHour(startHour)
        setIsChangingStartHour(true)
        // if new Hour is different from old
    }

    const handleStartHourSelection = (e: React.FormEvent<HTMLInputElement>) => {
        // this is triggered only when the date is typed
        console.log('FiltersDateTimeSelector => handleStartDateInput =>', e.currentTarget.value)
        setInitialItem(options.length -1)

        setTmpStartHour(e.currentTarget.value)
    }

    const handleStartHourBlur = () => {
        // this is triggered only when the date is typed
        console.log('FiltersDateTimeSelector => handleStartHourBlur => startHour =>', startHour)
        console.log('FiltersDateTimeSelector => handleStartHourBlur => tmpStartHour =>', tmpStartHour)
        if(validHour(tmpStartHour, true)) {
            console.log('FiltersDateTimeSelector => handleStartHourBlur => valid hour')
            setStartHour(tmpStartHour)
            dispatch(setStoreFullInterval({
                startDate,
                startHour: tmpStartHour,
                endDate,
                endHour
            }))
        } else {
            console.log('FiltersDateTimeSelector => handleStartHourBlur => invalid hour')
        }
        setIsChangingStartHour(false)
        setTmpStartHour('')
    }

    const handleEndHourFocus = () => {
        // this is triggered only when the Hour is typed
        console.log('FiltersDateTimeSelector => handleStartHourFocus =>')
        setTmpEndHour(endHour ?? '')
        setIsChangingEndHour(true)
        // if new Hour is different from old
    }

    const handleEndHourSelection = (e: React.FormEvent<HTMLInputElement>) => {
        // this is triggered only when the date is typed
        console.log('FiltersDateTimeSelector => handleEndHourSelection =>', e.currentTarget.value)
        setTmpEndHour(e.currentTarget.value)
    }

    const handleEndHourBlur = () => {
        // this is triggered only when the date is typed
        console.log('FiltersDateTimeSelector => handleEndHourBlur => endHour =>', endHour)
        console.log('FiltersDateTimeSelector => handleEndHourBlur => tmpEndHour =>', tmpEndHour)
        if(validHour(tmpEndHour, false)) {
            setEndHour(tmpEndHour)
            dispatch(setStoreFullInterval({
                startDate,
                startHour,
                endDate,
                endHour: tmpEndHour
            }))
        }
        setIsChangingEndHour(false)
        setTmpEndHour('')
    }

    /**
     *
     * @param hour
     * @param isStartDate true if we're checking startHour; false if we're checking endHour
     */
    const validHour = function(hour: string, isStartDate: boolean): boolean {
        if(hour === '') {
            if(isStartDate) {
                if(!startDate) return true
                if(startDate === '') return true
            } else {
                console.log('FiltersDateTimeSelector => validHour => endDate =>', endDate)
                if(!endDate) return true
                if(endDate === '') return true
            }
            return false
        } else {
            if(isStartDate) {
                if(startDate === '') return false
            } else {
                if(!endDate) return false
                if(endDate === '') return false
            }
        }

        if(!hour.match(/\d{2}:\d{2}:\d{2}/g))
            return false
        let tokens = hour.split(':').map((h) => Number(h))
        if(tokens.length !== 3) return false
        if(tokens[0] < 0 || tokens[0] > 23) return false
        if(tokens[1] < 0 || tokens[1] > 59) return false
        if(tokens[2] < 0 || tokens[2] > 59) return false
        return true
    }

    const invalidStartDate = function (): boolean {
        if(isChangingStartDate) {
            console.log('FiltersDateTimeSelector => invalidStartDate => tmp =>', tmpStartDate)
            console.log('FiltersDateTimeSelector => invalidStartDate => tmp =>', moment(`${tmpStartDate.split('/').reverse().join('-')}`))
            return !moment(`${tmpStartDate.split('/').reverse().join('-')}`).isValid()
        } else {
            console.log('FiltersDateTimeSelector => invalidStartDate => actual =>', startDate)
            console.log('FiltersDateTimeSelector => invalidStartDate => actual =>', moment(`${startDate.split('/').reverse().join('-')}`))
            return !moment(`${startDate.split('/').reverse().join('-')}`).isValid()
        }
    }

    const options: Option[] = [
        { key: "7", value: t('component__dropdown_filters__interval_7_days') },
        { key: "30", value: t('component__dropdown_filters__interval_30_days') },
        { key: "90", value: t('component__dropdown_filters__interval_90_days') },
        { key: "365", value: t('component__dropdown_filters__interval_365_days') },
        { key: "custom", value: t('component__dropdown_filters__interval_custom') },
    ]

    return(
        <>
            <p className='subtitle mb-16'>{t('component__dropdown_filters__timestamp')}</p>
            <p className='label mb-8'>{t('component__dropdown_filters__select_interval')}</p>
            <EeDropdown
                id={'interval-selector'}
                label={'select_interval'}
                callback={handleStartDateSelection}
                options={options}
                selectedItem={initialItem >= 0 ? initialItem : 0}
            />

            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: '16px'}}>
                <DatePicker
                    dateFormat={'d/m/Y'}
                    datePickerType="single"
                    onChange={handleStartDateInput}
                    onBlur={handleStartDateBlur}
                    onFocus={handleStartDateFocus}
                    value={startDate}
                >
                    <DatePickerInput
                        placeholder="dd/mm/yyyy"
                        labelText={t('component__dropdown_filters__start_date')}
                        id="date-picker-single"
                        invalid={invalid}
                        invalidText={t('component__dropdown_filters__invalid_input')}
                    />
                </DatePicker>

                <TextInput
                    id={"start-hour"}
                    placeholder={'hh:mm:ss'}
                    labelText={t('component__dropdown_filters__start_hour')}
                    value={isChangingStartHour ? tmpStartHour : startHour}
                    onChange={handleStartHourSelection}
                    onBlur={handleStartHourBlur}
                    onFocus={handleStartHourFocus}
                    invalid={(isChangingStartHour ? !validHour(tmpStartHour, true) : !validHour(startHour, true)) || invalid}
                    invalidText={t('component__dropdown_filters__invalid_input')}
                    />
            </div>

            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: '16px', marginBottom: '24px'}}>
                <DatePicker
                    dateFormat={'d/m/Y'}
                    datePickerType="single"
                    value={endDate}
                    onChange={handleEndDateSelection}
                >
                    <DatePickerInput
                        placeholder="dd/mm/yyyy"
                        labelText={t('component__dropdown_filters__end_date')}
                        id="date-picker-single"
                        invalid={invalid}
                        invalidText={t('component__dropdown_filters__invalid_input')}
                    />
                </DatePicker>

                <TextInput
                    id={"end-hour"}
                    placeholder={'hh:mm:ss'}
                    value={isChangingEndHour ? tmpEndHour : endHour}
                    labelText={t('component__dropdown_filters__end_hour')}
                    onChange={handleEndHourSelection}
                    onBlur={handleEndHourBlur}
                    onFocus={handleEndHourFocus}
                    invalid={(isChangingEndHour ? !validHour(tmpEndHour, false) : !validHour(endHour ?? '', false)) || invalid}
                    invalidText={t('component__dropdown_filters__invalid_input')}
                />
            </div>
        </>
    );
}

export {
    FiltersDateTimeSelector
}