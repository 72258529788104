import './CustomTable.css'
import { ReactComponent as SortNormalIcon } from '../../assets/sort-normal.svg'
import { ReactComponent as SortAsclIcon } from '../../assets/sort-asc.svg'
import { ReactComponent as SortDesclIcon } from '../../assets/sort-desc.svg'
import { useTranslation } from 'react-i18next'
import {DATETIME_TAG, SORT_ASC, SORT_DESC} from "./TableUtils";

export interface HeaderCellProps {
    stringHeader: string,
    sortable: boolean,
    sortingCallback?: (direction: number, key: string) => void
    tag: string,
    sortHeader: string,
    sortDirection: number,
}

const HeaderCell = ({stringHeader, sortable, sortHeader, sortDirection, sortingCallback, tag}: HeaderCellProps) => {

    const {t} = useTranslation()
    const id = `header-${tag}`
    const textid = `text-${id}`

    const handleSort = () => {
        console.log('HeaderCell => handleSort =>', sortDirection)
        if(sortable) {
            if(sortingCallback) {       
                if(tag !== sortHeader) {
                    console.log('HeaderCell => handleSort => new =>', SORT_ASC)
                    sortingCallback(SORT_ASC, tag)
                } else {      
                    if(sortDirection === SORT_ASC){
                        console.log('HeaderCell => handleSort => new =>', SORT_DESC)
                        sortingCallback(SORT_DESC, tag)
                    } else {
                        console.log('HeaderCell => handleSort => new =>', SORT_ASC)
                        sortingCallback(SORT_ASC, tag)
                    } 
                }
            }
            return
        }
    }

    const sortableIcon = () => {
        if(!sortable) {
            return <></>
        }
        if(sortHeader !== tag) {
            return <SortNormalIcon />
        }
        if(sortDirection === SORT_ASC)
            return <SortAsclIcon />
        if(sortDirection === SORT_DESC)
            return <SortDesclIcon />
    }
    
    return <div
                id={id}
                className={`
                    empty-header-container 
                    ${sortable ? 'sortable' : ''} 
                    ellipse`
                } onClick={handleSort}>
            <span id={textid} className='header-text'>
                {tag === DATETIME_TAG ?  t(`${stringHeader}_utc`): t(stringHeader)}
            </span>
            {sortableIcon()}
        </div>
}

export default HeaderCell