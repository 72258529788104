import { ApiApi } from "./network"
import { EventParametersApi } from "./network/api/event-parameters-api"
import { RecentEventsApi } from "./network/api/recent-events-api"
import { StationsApi } from "./network/api/stations-api"

const BASE_URL = process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:8080'
/**
 * Utility function to get an active instance of the EventParametersApi
 * 
 * @returns An active instance of the EventParametersApi
 */
const eventParametersApi = (): EventParametersApi => {
    return new EventParametersApi(undefined, BASE_URL)
}

const recentEventsApi = (): RecentEventsApi => {
    return new RecentEventsApi(undefined, BASE_URL)
}

const stationsApi = (): StationsApi => {
    return new StationsApi(undefined, BASE_URL)
}

const apiApi = (): ApiApi => {
    return new ApiApi(undefined, BASE_URL)
}

export const ApiClient = {
    eventParametersApi,
    recentEventsApi,
    stationsApi,
    apiApi
}