import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { EarlyestMap } from '../../components/EarlyestMap/EarlyestMap'
import { EventDetailFragment } from '../../components/EventDetailFragment/EventDetailFragment'
import { Timeline } from '../../components/Timeline/Timeline'
import { useAppSelector } from '../../hooks/reduxCustomHooks'
import { EventParametersEventInner } from '../../services/network'
import { ReducedEvent } from '../../utils/eventReducedListCreator'
import './EventDetail.css'

interface EventDetailProps {
    eventId: string,
    previousLocation: 'dashboard' | 'eventList'
}

function EventDetail() {
    const { eventId, previousLocation } = useParams<keyof EventDetailProps>() as EventDetailProps
    const currentEventParameters = useAppSelector((state) => state.currentEventParameters)
    const reducedEventParameters = useAppSelector((state) => state.reducedEvents)
    const [reducedEvent, setReducedEvent] = useState<ReducedEvent>()
    const [event, setEvent] = useState<EventParametersEventInner>()
    const [eventParametersId, setEventParametersId] = useState<string>()

    useEffect(() => {
        // Set the single event in the state
        setReducedEvent(reducedEventParameters?.events.filter((event) => event.eventId.split('/').pop() === eventId)[0])
        setEvent(currentEventParameters.event?.filter((event) => event.publicID?.split('/').pop() === eventId)[0])
        setEventParametersId(currentEventParameters.publicID?.split('/').pop() ?? '')
    }, [reducedEventParameters, currentEventParameters])

    return (
        <div className='event-detail-container'>
            <EventDetailFragment event={event} reducedEvent={reducedEvent} eventParametersId={eventParametersId} />
            <EarlyestMap events={reducedEventParameters?.events ?? []} creationTime={currentEventParameters.creationInfo?.creationTime} eventDetail={reducedEvent} isEventDetail={true} />
            <Timeline eventId={eventId} className='timeline-event-detail' />
        </div>
    )
}

export { EventDetail }