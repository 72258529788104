import log from 'loglevel'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PicksTable } from '../../components/PicksTable/PicksTable'
import { Timeline } from '../../components/Timeline/Timeline'
import { useAppSelector } from '../../hooks/reduxCustomHooks'
import { ReducedPick, reducedPickCreator } from '../../utils/eventReducedListCreator'
import './Picks.css'

function Picks() {
    const {t} = useTranslation()
    const currentEventParameters = useAppSelector((state) => state.currentEventParameters)
    const reducedEvents = useAppSelector((state) => state.reducedEvents)
    const [currentPicks, setCurrentPicks] = useState<ReducedPick[]>([])

    useEffect(() => {
        log.debug('Picks page => currentEventParameters => ', currentEventParameters)
        setCurrentPicks(reducedPickCreator(currentEventParameters, reducedEvents))
    }, [currentEventParameters, reducedEvents])

    return (
        <div className='picks-container'>
            <div className='picks-title'>{t('page__picks__title')}</div>
            <div className='picks-summary'>
                <span className='picks-summary-text picks-summary-text-hl'>{currentPicks.length} </span>
                <span className='picks-summary-text'>{t('page__picks__summary_text')}</span>
            </div>
            <PicksTable picks={currentPicks} />
            <Timeline className='timeline-picks' />
        </div>
    )
}

export { Picks }