import { EARTHQUAKE_LIST_TABLE, GenericEvent } from "./TableComposer"
import {EarthquakeTableEvent} from "./TableUtils"
import { EVENT_PATH } from '../../routes/routes'
import { Link } from "react-router-dom"

export const evaluateHref = function(type: string, event: GenericEvent): string {

    if(type === EARTHQUAKE_LIST_TABLE)
        return `${EVENT_PATH.replace(':eventId', (event as EarthquakeTableEvent).id)}`

    return '#'
}

/*
 * this function is used to distinguish the cases when a row in a table should be an anchor tag or a div tag
 */
export const evaluateRow = (
    key: string, type: string, className: string,
    children: JSX.Element[],
    elementId: string,
    href?: string
) => {

        return (<Link
            key={key}
            to={href ?? '#'}
            state='eventList'
            className={className}>
            {
                children
            }
        </Link>)
}
