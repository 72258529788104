import { eventReducedListCreator, ReducedEvent } from "./eventReducedListCreator"
import {AxiosError, AxiosResponse} from "axios";
import {RequiredError} from "../services/network/base";

////////////// GENERAL ////////////// 
export const DATE_FORMAT_DEFAULT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_FORMAT_DEFAULT_SKELETON = '----/--/-- --:--:--'
export const DATE_FORMAT_DEFAULT_SKELETON_LETTERS = 'yyyy/mm/dd, hh:mm:ss'
export const TIME_FORMAT_DEFAULT_SKELETON = '--:--:--'
export const DATE_FORMAT_DEFAULT_WITH_UTC = DATE_FORMAT_DEFAULT + ' (UTC)'
export const DATE_FORMAT_DEFAULT_SKELETON_WITH_UTC = DATE_FORMAT_DEFAULT_SKELETON + ' (UTC)'
export const TIME_FORMAT_DEFAULT = 'HH:mm:ss'
export const JUST_DATE_FORMAT_DEFAULT = 'YYYY-MM-DD'
export const DATE_FORMAT_FULL_MONTH_NAME = 'MMMM YYYY'
export const DATE_FORMAT_DAY_WEEK_NAME = 'dd'

export const EVENT_TITLE_DEFAULT_SKELETON = `${DATE_FORMAT_DEFAULT_SKELETON} - Version --`

export const EARLYEST_VERSION_NUMBER = '0.0.20'
export const EARLYEST_VERSION_DATE = '2024-03-06'
export const EARLYEST_VERSION_SKELETON = '-.-.- (---.--.--)'

export const EVENT_COLORS = [
        '#B06CFC',
        '#9EF0F0',
        '#009D9A',
        '#FF8389',
        '#42BE65',
        '#FCF4D6',
        '#82CFFF',
        '#4589FF',
        '#EE5396',
        '#08BDBA',
        '#BE95FF',
    ]

interface StringBooleanMap {
    [key: string]: boolean
}

interface StringNumberMap {
    [key: string]: number
}

interface StringStringMap {
    [key: string]: string
}

interface NumberNumberMap {
    [key: number]: number
}

const formatNumberToFixed = (fixedDecimals: number, numberQuantity?: string | number, divideBy?: number) => {
    let quantityParsed = typeof numberQuantity === 'string' ? +(numberQuantity ?? 0) : (numberQuantity ?? 0)
    
    if (divideBy) quantityParsed = quantityParsed / divideBy

    return quantityParsed.toFixed(fixedDecimals)
}

export type { ReducedEvent, StringBooleanMap, StringNumberMap, StringStringMap, NumberNumberMap }
export { eventReducedListCreator, formatNumberToFixed }

////////////// GENERAL //////////////
export const DEFAULT_ORDER_BY = 'origin_ot-desc'
export const DEFAULT_LIMIT = 50

////////////// MAGNITUDE //////////////
export const DEFAULT_MIN_MAGNITUDE = "2"
export const DEFAULT_MAX_MAGNITUDE = "10"
export const MIN_ACCEPTABLE_MAGNITUDE = 0
export const MAX_ACCEPTABLE_MAGNITUDE = 10

export const isValidMagnitude = (mag: string, empty:boolean = true) => {
    return isValid(mag, MIN_ACCEPTABLE_MAGNITUDE, MAX_ACCEPTABLE_MAGNITUDE, empty);
}

export const isValidMinMaxMagnitude = (min: string, max: string, empty:boolean = true) => {
    // Number() is better then parseFloat() since conversion is stricter
    // e.g: isNan(parseFloat("3.a")) -> true, isNan(Number("3.a")) -> false
    return isValidMinMax(min,max, MIN_ACCEPTABLE_MAGNITUDE, MAX_ACCEPTABLE_MAGNITUDE, empty);
}

////////////// COORDINATES //////////////
export const MIN_ACCEPTABLE_LATITUDE = -90
export const MAX_ACCEPTABLE_LATITUDE = 90
export const MIN_ACCEPTABLE_LONGITUDE = -180
export const MAX_ACCEPTABLE_LONGITUDE = 180

export const isValidLatitude = (lat: string, canBeEmpty:boolean = true) => {
    // Situation Number('') -> 0 but parseFloat('') -> NaN
    return isValid(lat, MIN_ACCEPTABLE_LATITUDE, MAX_ACCEPTABLE_LATITUDE, canBeEmpty);
}

export const isValidLongitude = (lon: string, canBeEmpty:boolean = true) => {
    return isValid(lon, MIN_ACCEPTABLE_LONGITUDE, MAX_ACCEPTABLE_LONGITUDE, canBeEmpty);
}

export const isValidMinMaxLatitude = function(min: string, max: string, canBeEmpty:boolean = true) {
    return isValidMinMax(min,max, MIN_ACCEPTABLE_LATITUDE, MAX_ACCEPTABLE_LATITUDE, canBeEmpty);
}

export const isValidMinMaxLongitude = function(min: string, max: string, canBeEmpty:boolean = true) {
    return isValidMinMax(min, max, MIN_ACCEPTABLE_LONGITUDE, MAX_ACCEPTABLE_LONGITUDE, canBeEmpty);
}

////////////// RADIUS //////////////
export const DEFAULT_MIN_RADIUS = 0
export const DEFAULT_MAX_RADIUS = 800

export const isValidRadius = (radius: string, empty:boolean = true) => {
    return isValid(radius, DEFAULT_MIN_RADIUS, DEFAULT_MAX_RADIUS, empty);
}

export const isValidMinMaxRadius = function(min: string, max: string, canBeEmpty:boolean = true) {
    return isValidMinMax(min,max, DEFAULT_MIN_RADIUS, DEFAULT_MAX_RADIUS, canBeEmpty);
}

////////////// DEPTH //////////////
export const DEFAULT_MIN_DEPTH = -1
export const DEFAULT_MAX_DEPTH = 1000

export const isValidDepth = (depth: string, canBeEmpty:boolean = true) => {
    return isValid(depth, DEFAULT_MIN_DEPTH, DEFAULT_MAX_DEPTH, canBeEmpty);
}

export const isValidMinMaxDepth = (min: string, max: string, canBeEmpty:boolean = true) => {
    return isValidMinMax(min, max, DEFAULT_MIN_RADIUS, DEFAULT_MAX_DEPTH, canBeEmpty);
}


////////////// TYPEORIGIN.VALUE //////////////
export const DEFAULT_MIN_TYPEORIGIN = Number.NEGATIVE_INFINITY
export const DEFAULT_MAX_TYPEORIGIN = Number.POSITIVE_INFINITY

export const isValidTypeOriginWhereIn = function(typeOrigin: string, canBeEmpty:boolean = true): boolean{
    if(typeOrigin === '') return canBeEmpty

    console.log('isValidTypeOriginWhereIn => typeOrigin =>', typeOrigin)

    // we check that there are no unwanted characters
    console.log('isValidTypeOriginWhereIn => typeOrigin => match 1 =>', typeOrigin.match(/[^\d, -]/g))
    let occurrences = typeOrigin.match(/[^\d, -]/g)
    if(occurrences === null || occurrences === undefined || occurrences.length === 0){

        // we check that there is no repetition of commas or whitespaces or dashes
        console.log('isValidTypeOriginWhereIn => typeOrigin => match 2 =>', typeOrigin.match(/, *,|-{2,}/g))
        occurrences = typeOrigin.match(/, *,|-{2,}/g)
        if(occurrences === null || occurrences === undefined || occurrences.length === 0){

            // we check that the input as this format '1, 2, -32, 4'. No limit to the token
            console.log('isValidTypeOriginWhereIn => typeOrigin => match 3 =>', typeOrigin.match(/(\d+)(,{1} {1}-?\d+)*/g))
            occurrences = typeOrigin.match(/(\d+)(,{1} {1}-?\d+)*/g)
            if(!(occurrences === null || occurrences === undefined || occurrences.length === 0)){
                //  prevent that the user ends the the input as '1, ' or as '1,' or as '-'
                return !(typeOrigin.endsWith(' ') || typeOrigin.endsWith(',') || typeOrigin.endsWith('-'))
            }
        }
    }

    // TODO: some edge cases:
    // any combination of commas and dash and whitespace

    return false
}

export const isValidTypeOrigin = (typeOrigin: string, canBeEmpty:boolean = true) => {
    return isValidInteger(typeOrigin, DEFAULT_MIN_TYPEORIGIN, DEFAULT_MAX_TYPEORIGIN, canBeEmpty);
}

export const isValidMinMaxTypeOrigin = (min: string, max: string, canBeEmpty:boolean = true) => {
    return isValidMinMaxInteger(min, max, DEFAULT_MIN_TYPEORIGIN, DEFAULT_MAX_TYPEORIGIN, canBeEmpty);
}

export const stationArrivalAndMagnitude = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1654_34475)">
<path d="M14.9635 12.4453C15.4066 13.1099 14.9302 14 14.1315 14L1.86852 14C1.06982 14 0.59343 13.1099 1.03647 12.4453L7.16795 3.24808C7.56377 2.65434 8.43623 2.65434 8.83205 3.24808L14.9635 12.4453Z" fill="#4589FF"/>
<path opacity="0.4" d="M14.5475 12.7227C14.769 13.0549 14.5308 13.5 14.1315 13.5L1.86852 13.5C1.46917 13.5 1.23097 13.0549 1.45249 12.7227L7.58397 3.52543C7.78189 3.22856 8.21811 3.22856 8.41602 3.52543L14.5475 12.7227Z" stroke="#F4F4F4"/>
</g>
<g clip-path="url(#clip1_1654_34475)">
<path d="M1.03647 12.4453C0.59343 13.1099 1.06982 14 1.86852 14L14.1315 14C14.9302 14 15.4066 13.1099 14.9635 12.4453L8.83205 3.24808C8.43623 2.65434 7.56377 2.65434 7.16795 3.24808L1.03647 12.4453Z" fill="#EE5396"/>
<path opacity="0.4" d="M1.45249 12.7227C1.23097 13.0549 1.46917 13.5 1.86852 13.5L14.1315 13.5C14.5308 13.5 14.769 13.0549 14.5475 12.7227L8.41603 3.52543C8.21811 3.22856 7.78189 3.22856 7.58398 3.52543L1.45249 12.7227Z" stroke="#F4F4F4"/>
</g>
<defs>
<clipPath id="clip0_1654_34475">
<rect width="8" height="12" fill="white" transform="translate(0 2)"/>
</clipPath>
<clipPath id="clip1_1654_34475">
<rect width="8" height="12" fill="white" transform="matrix(-1 0 0 1 16 2)"/>
</clipPath>
</defs>
</svg>`

export const stationMagnitude = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.9635 12.4453C15.4066 13.1099 14.9302 14 14.1315 14L8 14L1.86852 14C1.06982 14 0.59343 13.1099 1.03647 12.4453L7.16795 3.24808C7.56377 2.65434 8.43623 2.65434 8.83205 3.24808L14.9635 12.4453Z" fill="#4589FF"/>
<path opacity="0.4" d="M14.5475 12.7227C14.769 13.0549 14.5308 13.5 14.1315 13.5L1.86852 13.5C1.46917 13.5 1.23097 13.0549 1.45249 12.7227L7.58397 3.52543C7.78189 3.22856 8.21811 3.22856 8.41602 3.52543L14.5475 12.7227Z" stroke="#F4F4F4"/>
</svg>`

export const stationArrival = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.9635 12.4453C15.4066 13.1099 14.9302 14 14.1315 14L1.86852 14C1.06982 14 0.59343 13.1099 1.03647 12.4453L7.16795 3.24808C7.56377 2.65434 8.43623 2.65434 8.83205 3.24808L14.9635 12.4453Z" fill="#EE5396"/>
<path opacity="0.4" d="M14.5475 12.7227C14.769 13.0549 14.5308 13.5 14.1315 13.5L1.86852 13.5C1.46917 13.5 1.23097 13.0549 1.45249 12.7227L7.58397 3.52543C7.78189 3.22856 8.21811 3.22856 8.41602 3.52543L14.5475 12.7227Z" stroke="#F4F4F4"/>
</svg>`

export const convertTime = (timezone: string, dateStr?: string) => {
    if(!dateStr) return '--'

    return `${dateStr.replaceAll('T', ' ').replaceAll(/\+\d+:\d+/g, '')}`
}

export const pxToRem = (px: number) => {
    // 16px : 1em = px : xem
    // xem = (1em * px) / 16px = px / 16px
    return px / 16;
}


////////////// GENERIC //////////////
export const validNumeric = (n: string) => {
    if(n === '') return false
    return !isNaN(Number(n));
}

export const isValidInteger = function(v: string, min: number, max: number, canBeEmpty: boolean): boolean {
    if(v === '') {
        // if canBeEmpty === true: then the value v is accepted
        // otherwise not
        return canBeEmpty
    }

    // we check if the value v is a numeric value
    if(validNumeric(v)) {
        // if it is not a floating point number
        if(!v.includes('.')){
            // and if it fits between the interval min/max
            return (Number(v) >= min && Number(v) <= max)
        }
    }
    return false
}

export const isValid = function(v: string, min: number, max: number, canBeEmpty: boolean): boolean {
    if(v === '') {
        // if canBeEmpty === true: then the value v is accepted
        // otherwise not
        return canBeEmpty
    }

    // we check if the value v is a numeric value
    if(validNumeric(v)) {
        // and if it fits between the interval min/max
        return (Number(v) >= min && Number(v) <= max)
    }
    return false
}

export const isValidMinMax = function(min: string, max: string, limitMin: number, limitMax: number, canBeEmpty: boolean): boolean {
    // checking for empty values case
    if(max === '' && min === '')
        return canBeEmpty;
    if(max === '' && !canBeEmpty)
        return false
    if(min === '' && !canBeEmpty)
        return false;
    if(min === '' && max !== '')
        return isValid(max, limitMin, limitMax, false)
    if(min !== '' && max === '')
        return isValid(min, limitMin, limitMax, false)
    // at this point we should have the situation where both values are not empty.
    // In this case we force the canBeEmpty to `false` so that we should not have conversion problems
    if(isValid(min, limitMin, limitMax, false) && isValid(max, limitMin, limitMax, false)) {
        return Number(min) <= Number(max);
    }
    return false
}
export const isValidMinMaxInteger = function(min: string, max: string, limitMin: number, limitMax: number, canBeEmpty: boolean): boolean {
    // checking for empty values case
    if(max === '' && min === '')
        return canBeEmpty;
    if(max === '' && !canBeEmpty)
        return false
    if(min === '' && !canBeEmpty)
        return false;
    if(min === '' && max !== '')
        return isValid(max, limitMin, limitMax, false)
    if(min !== '' && max === '')
        return isValid(min, limitMin, limitMax, false)
    // at this point we should have the situation where both values are not empty.
    // In this case we force the canBeEmpty to `false` so that we should not have conversion problems
    if(isValidInteger(min, limitMin, limitMax, false) && isValidInteger(max, limitMin, limitMax, false)) {
        return Number(min) <= Number(max);
    }
    return false
}
////////////// /GENERIC //////////////

export const buildError = function(e: AxiosError<RequiredError>, httpCodeCallback: (code: number) => void): string {
    if(e.message === 'Network Error'){
        httpCodeCallback(500)
        return e.message
    }

    let response = (e.response as AxiosResponse)
    httpCodeCallback(response.status)
    if(response){
        if(response.data){
            if(response.data.errors){
                return Object.entries(response.data.errors).map((e) => e[1]).join('<br />')
            } else if (response.data.debug) {
                return response.data.debug.message
            }
        }
    }

    return 'Unknown error'
}