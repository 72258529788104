import { useTranslation } from 'react-i18next'
import { FocalMechanismHash, FocalMechanismPolarity } from '../../services/network'
import './EventDetailFocalMechanism.css'
import { ReactComponent as FocalMechanismPlaceholder } from '../../assets/focal-mechanism-placeholder.svg'
import { formatNumberToFixed } from '../../utils'
import { useEffect, useState } from 'react'
import log from 'loglevel'
import { MopadClient } from '../../services/MopadClient'

interface EventDetailFocalMechanismProps {
    type: 'polarity' | 'polarity_waveform' | 'hash',
    dataRaw: FocalMechanismPolarity | FocalMechanismHash,
}

function EventDetailFocalMechanism({type, dataRaw}: EventDetailFocalMechanismProps) {
    const [data, setData] = useState<FocalMechanismPolarity | FocalMechanismHash>({})
    const {t} = useTranslation()

    useEffect(() => {
        log.trace(`FocalMechanism => Type: ${type} - Data: ${JSON.stringify(dataRaw)}`)

        if (type === 'hash' && (
            (dataRaw.nodalPlanes?.nodalPlane1?.strike?.value === 999 && dataRaw.nodalPlanes?.nodalPlane1?.rake?.value === 999 && dataRaw.nodalPlanes?.nodalPlane1?.dip?.value === 99) ||
            (dataRaw.nodalPlanes?.nodalPlane2?.strike?.value === 999 && dataRaw.nodalPlanes?.nodalPlane2?.rake?.value === 999 && dataRaw.nodalPlanes?.nodalPlane2?.dip?.value === 99)
        )) {
            setData({})
            return
        }

        if ((type === 'polarity' || type === 'polarity_waveform') && (
            (dataRaw.nodalPlanes?.nodalPlane1?.strike?.value === 0 && dataRaw.nodalPlanes?.nodalPlane1?.dip?.value === 0 && dataRaw.nodalPlanes?.nodalPlane1?.rake?.value === 0) ||
            (dataRaw.nodalPlanes?.nodalPlane2?.strike?.value === 0 && dataRaw.nodalPlanes?.nodalPlane2?.dip?.value === 0 && dataRaw.nodalPlanes?.nodalPlane2?.rake?.value === 0) ||
            dataRaw.stationPolarityCount === 0
        )) {
            setData({})
            return
        }

        setData(dataRaw)
    }, [dataRaw, type])

    let fmTitle

    switch (type) {
        case 'polarity_waveform':
            fmTitle = t('component__event_detail_fm__title_polarity_waveform')
            break
        case 'hash':
            fmTitle = t('component__event_detail_fm__title_hash')
            break
        case 'polarity':
        default:
            fmTitle = t('component__event_detail_fm__title_polarity')
            break
    }

    return (
        <div className='data-detail-four-column'>
            <div className='fm-data-container'>
                <div className='fm-data-title'>
                    {fmTitle}
                </div>
                <div className='fm-data-section'>
                    <div className='fm-data-section-title'>{t('component__event_detail_fm__np1')}</div>
                    <div className='fm-data-section-data'>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__strike')}</span><span className='fm-data-section-data-value'>{data.nodalPlanes?.nodalPlane1?.strike?.value ? formatNumberToFixed(1, data.nodalPlanes?.nodalPlane1?.strike?.value) : '--'}°</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__dip')}</span><span className='fm-data-section-data-value'>{data.nodalPlanes?.nodalPlane1?.dip?.value ? formatNumberToFixed(1, data.nodalPlanes?.nodalPlane1?.dip?.value) : '--'}°</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__rake')}</span><span className='fm-data-section-data-value'>{data.nodalPlanes?.nodalPlane1?.rake?.value ? formatNumberToFixed(1, data.nodalPlanes?.nodalPlane1?.rake?.value) : '--'}°</span>
                    </div>
                </div>
                <div className='fm-data-section'>
                    <div className='fm-data-section-title'>{t('component__event_detail_fm__np2')}</div>
                    <div className='fm-data-section-data'>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__strike')}</span><span className='fm-data-section-data-value'>{data.nodalPlanes?.nodalPlane2?.strike?.value ? formatNumberToFixed(1, data.nodalPlanes?.nodalPlane2?.strike?.value) : '--'}°</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__dip')}</span><span className='fm-data-section-data-value'>{data.nodalPlanes?.nodalPlane2?.dip?.value ? formatNumberToFixed(1, data.nodalPlanes?.nodalPlane2?.dip?.value) : '--'}°</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__rake')}</span><span className='fm-data-section-data-value'>{data.nodalPlanes?.nodalPlane2?.rake?.value ? formatNumberToFixed(1, data.nodalPlanes?.nodalPlane2?.rake?.value) : '--'}°</span>
                    </div>
                </div>
                <div className='fm-data-section'>
                    <div className='fm-data-section-title'>{t('component__event_detail_fm__quality')}</div>
                    <div className='fm-data-section-data'>
                        {(type === 'polarity' || type === 'polarity_waveform') &&
                        <>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__az_gap')}</span><span className='fm-data-section-data-value'>{data.azimuthalGap ? formatNumberToFixed(1, data.azimuthalGap) : '--'}°</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__sta_pol_count')}</span><span className='fm-data-section-data-value'>{data.stationPolarityCount ? formatNumberToFixed(0, data.stationPolarityCount) : '--'}</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__misfit_wc')}</span><span className='fm-data-section-data-value'>{('misfitWeightCount' in data) ? data.misfitWeightCount ?? '--' : '--'}</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__misfit')}</span><span className='fm-data-section-data-value'>{data.misfit ? formatNumberToFixed(3, data.misfit) : '--'}</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__sta_dist_rat')}</span><span className='fm-data-section-data-value'>{data.stationDistributionRatio ? formatNumberToFixed(3, data.stationDistributionRatio) : '--'}</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__mean_dist_p')}</span><span className='fm-data-section-data-value'>{('meanDistP' in data) && data.meanDistP ? formatNumberToFixed(3, data.meanDistP) : '--'}</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__mean_dist_t')}</span><span className='fm-data-section-data-value'>{('meanDistT' in data) && data.meanDistT ? formatNumberToFixed(3, data.meanDistT) : '--'}</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__quality_code')}</span><span className='fm-data-section-data-value'>{data.qualityCode ?? '--'}</span>
                        </>
                        }
                        {type === 'hash' &&
                        <>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__az_gap')}</span><span className='fm-data-section-data-value'>{data.azimuthalGap ? formatNumberToFixed(1, data.azimuthalGap) : '--'}°</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__sta_pol_count')}</span><span className='fm-data-section-data-value'>{data.stationPolarityCount ? formatNumberToFixed(0, data.stationPolarityCount) : '--'}</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__misfit')}</span><span className='fm-data-section-data-value'>{data.misfit ? formatNumberToFixed(3, data.misfit) : '--'}</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__sta_dist_rat')}</span><span className='fm-data-section-data-value'>{data.stationDistributionRatio ? formatNumberToFixed(3, data.stationDistributionRatio) : '--'}</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__rms_ang_diff_acc_pref')}</span><span className='fm-data-section-data-value'>{('rmsAngDiffAccPref' in data) ? data.rmsAngDiffAccPref ?? '--' : '--'}</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__frac_acc_30_deg_pref')}</span><span className='fm-data-section-data-value'>{('fracAcc30degPref' in data) ? data.fracAcc30degPref ?? '--' : '--'}</span><span className='fm-data-section-data-value'>-</span>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__quality_code')}</span><span className='fm-data-section-data-value'>{data.qualityCode ?? '--'}</span>
                        </>
                        }
                    </div>
                </div>
                <div className='fm-data-section'>
                    <div className='fm-data-section-title'>{t('component__event_detail_fm__method')}</div>
                    <div className='fm-data-section-data'>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__method_label')}</span><span className='fm-data-section-data-value'>{data.methodID ?? '--'}</span>
                    </div>
                </div>
                <div className='fm-data-section'>
                    <div className='fm-data-section-title'>{t('component__event_detail_fm__id')}</div>
                    <div className='fm-data-section-data'>
                        <span className='fm-data-section-data-label'>{t('component__event_detail_fm__id_label')}</span><span className='fm-data-section-data-value'>{data.publicID?.split('BETA').pop() ?? '--'}</span>
                    </div>
                </div>
            </div>
            <div className='fm-image-container'>
                {data.nodalPlanes?.nodalPlane1 ?
                <img height='164px' width='164px'
                    src={MopadClient.getMopadImgUrl({
                    strike: data.nodalPlanes.nodalPlane1.strike?.value ?? 0,
                    dip: data.nodalPlanes.nodalPlane1.dip?.value ?? 0,
                    rake: data.nodalPlanes.nodalPlane1.rake?.value ?? 0
                })} />
                :
                <FocalMechanismPlaceholder />
                }
            </div>
        </div>
    )
}

export { EventDetailFocalMechanism }