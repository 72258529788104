/* tslint:disable */
/* eslint-disable */
/**
 * EarlyEst Dashboard API
 * All the APIs of the EarlyEst Dashboard
 *
 * The version of the OpenAPI document: 2.16.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { EventParametersByDate } from '../model';
// @ts-ignore
import { EventParametersByEvent } from '../model';
// @ts-ignore
import { RawEventParametersFile } from '../model';
/**
 * EventParametersApi - axios parameter creator
 * @export
 */
export const EventParametersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return all the relevant EventParameters existing in the DB
         * @param {string} [startdate] The start date for an EventParameters
         * @param {string} [enddate] The start date for an EventParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventParameters: async (startdate?: string, enddate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/event-parameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startdate !== undefined) {
                localVarQueryParameter['startdate'] = (startdate as any instanceof Date) ?
                    (startdate as any).toISOString() :
                    startdate;
            }

            if (enddate !== undefined) {
                localVarQueryParameter['enddate'] = (enddate as any instanceof Date) ?
                    (enddate as any).toISOString() :
                    enddate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return all the EventParameters containing the event whose ID is passed as a parameter
         * @param {string} eventId The ID of the Event that shall be contained inside the EventParameters
         * @param {string} [lastKnownEventDate] The ISO String of the date of the last know occurrence of the Event in the EventParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventParametersWithEvent: async (eventId: string, lastKnownEventDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getEventParametersWithEvent', 'eventId', eventId)
            const localVarPath = `/event-parameters/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lastKnownEventDate !== undefined) {
                localVarQueryParameter['lastKnownEventDate'] = lastKnownEventDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return the raw XML file for the EventParameters whose ID is passed as a parameter
         * @param {string} eventParametersId The ID of the desired EventParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawEventParametersFile: async (eventParametersId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventParametersId' is not null or undefined
            assertParamExists('getRawEventParametersFile', 'eventParametersId', eventParametersId)
            const localVarPath = `/event-parameters/{eventParametersId}/raw`
                .replace(`{${"eventParametersId"}}`, encodeURIComponent(String(eventParametersId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventParametersApi - functional programming interface
 * @export
 */
export const EventParametersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventParametersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return all the relevant EventParameters existing in the DB
         * @param {string} [startdate] The start date for an EventParameters
         * @param {string} [enddate] The start date for an EventParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventParameters(startdate?: string, enddate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventParametersByDate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventParameters(startdate, enddate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventParametersApi.getEventParameters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Return all the EventParameters containing the event whose ID is passed as a parameter
         * @param {string} eventId The ID of the Event that shall be contained inside the EventParameters
         * @param {string} [lastKnownEventDate] The ISO String of the date of the last know occurrence of the Event in the EventParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventParametersWithEvent(eventId: string, lastKnownEventDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventParametersByEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventParametersWithEvent(eventId, lastKnownEventDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventParametersApi.getEventParametersWithEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Return the raw XML file for the EventParameters whose ID is passed as a parameter
         * @param {string} eventParametersId The ID of the desired EventParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRawEventParametersFile(eventParametersId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RawEventParametersFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRawEventParametersFile(eventParametersId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventParametersApi.getRawEventParametersFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventParametersApi - factory interface
 * @export
 */
export const EventParametersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventParametersApiFp(configuration)
    return {
        /**
         * 
         * @summary Return all the relevant EventParameters existing in the DB
         * @param {string} [startdate] The start date for an EventParameters
         * @param {string} [enddate] The start date for an EventParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventParameters(startdate?: string, enddate?: string, options?: any): AxiosPromise<EventParametersByDate> {
            return localVarFp.getEventParameters(startdate, enddate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return all the EventParameters containing the event whose ID is passed as a parameter
         * @param {string} eventId The ID of the Event that shall be contained inside the EventParameters
         * @param {string} [lastKnownEventDate] The ISO String of the date of the last know occurrence of the Event in the EventParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventParametersWithEvent(eventId: string, lastKnownEventDate?: string, options?: any): AxiosPromise<EventParametersByEvent> {
            return localVarFp.getEventParametersWithEvent(eventId, lastKnownEventDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return the raw XML file for the EventParameters whose ID is passed as a parameter
         * @param {string} eventParametersId The ID of the desired EventParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRawEventParametersFile(eventParametersId: string, options?: any): AxiosPromise<RawEventParametersFile> {
            return localVarFp.getRawEventParametersFile(eventParametersId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventParametersApi - interface
 * @export
 * @interface EventParametersApi
 */
export interface EventParametersApiInterface {
    /**
     * 
     * @summary Return all the relevant EventParameters existing in the DB
     * @param {string} [startdate] The start date for an EventParameters
     * @param {string} [enddate] The start date for an EventParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventParametersApiInterface
     */
    getEventParameters(startdate?: string, enddate?: string, options?: RawAxiosRequestConfig): AxiosPromise<EventParametersByDate>;

    /**
     * 
     * @summary Return all the EventParameters containing the event whose ID is passed as a parameter
     * @param {string} eventId The ID of the Event that shall be contained inside the EventParameters
     * @param {string} [lastKnownEventDate] The ISO String of the date of the last know occurrence of the Event in the EventParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventParametersApiInterface
     */
    getEventParametersWithEvent(eventId: string, lastKnownEventDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<EventParametersByEvent>;

    /**
     * 
     * @summary Return the raw XML file for the EventParameters whose ID is passed as a parameter
     * @param {string} eventParametersId The ID of the desired EventParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventParametersApiInterface
     */
    getRawEventParametersFile(eventParametersId: string, options?: RawAxiosRequestConfig): AxiosPromise<RawEventParametersFile>;

}

/**
 * EventParametersApi - object-oriented interface
 * @export
 * @class EventParametersApi
 * @extends {BaseAPI}
 */
export class EventParametersApi extends BaseAPI implements EventParametersApiInterface {
    /**
     * 
     * @summary Return all the relevant EventParameters existing in the DB
     * @param {string} [startdate] The start date for an EventParameters
     * @param {string} [enddate] The start date for an EventParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventParametersApi
     */
    public getEventParameters(startdate?: string, enddate?: string, options?: RawAxiosRequestConfig) {
        return EventParametersApiFp(this.configuration).getEventParameters(startdate, enddate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return all the EventParameters containing the event whose ID is passed as a parameter
     * @param {string} eventId The ID of the Event that shall be contained inside the EventParameters
     * @param {string} [lastKnownEventDate] The ISO String of the date of the last know occurrence of the Event in the EventParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventParametersApi
     */
    public getEventParametersWithEvent(eventId: string, lastKnownEventDate?: string, options?: RawAxiosRequestConfig) {
        return EventParametersApiFp(this.configuration).getEventParametersWithEvent(eventId, lastKnownEventDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return the raw XML file for the EventParameters whose ID is passed as a parameter
     * @param {string} eventParametersId The ID of the desired EventParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventParametersApi
     */
    public getRawEventParametersFile(eventParametersId: string, options?: RawAxiosRequestConfig) {
        return EventParametersApiFp(this.configuration).getRawEventParametersFile(eventParametersId, options).then((request) => request(this.axios, this.basePath));
    }
}

