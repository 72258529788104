import { TFunction } from "i18next";
import { MIN_ACCEPTABLE_MAGNITUDE, MAX_ACCEPTABLE_MAGNITUDE, validNumeric, DEFAULT_MIN_DEPTH, DEFAULT_MAX_DEPTH, MIN_ACCEPTABLE_LATITUDE, MAX_ACCEPTABLE_LATITUDE, MIN_ACCEPTABLE_LONGITUDE, MAX_ACCEPTABLE_LONGITUDE, DEFAULT_MIN_RADIUS, DEFAULT_MAX_RADIUS, isValidInteger, isValidTypeOriginWhereIn } from "../../utils";

const OK_MESSAGE = 'ok'

////////////// MAGNITUDES //////////////
const minMagnitudeMessage = function(min: string, empty:boolean = true): string {
    return numericMessage(min, MIN_ACCEPTABLE_MAGNITUDE, MAX_ACCEPTABLE_MAGNITUDE, empty)
}

const maxMagnitudeMessage = function(max: string, empty:boolean = true): string {
    return numericMessage(max, MIN_ACCEPTABLE_MAGNITUDE, MAX_ACCEPTABLE_MAGNITUDE, empty)
}

export const minMagErrorText = (min: string, max: string, t: TFunction) => {
    return filterAndJoinMessages(
        [minMagnitudeMessage(min, true), minMaxMessage(min, max, true)],
        m => t(m, {min: MIN_ACCEPTABLE_MAGNITUDE, max: MAX_ACCEPTABLE_MAGNITUDE})
    )
}

export const maxMagErrorText = (min: string, max: string, t: TFunction) => {
    return filterAndJoinMessages(
        [maxMagnitudeMessage(max, true), minMaxMessage(min, max, true)],
        m => t(m, {min: MIN_ACCEPTABLE_MAGNITUDE, max: MAX_ACCEPTABLE_MAGNITUDE})
    )
}
////////////// /MAGNITUDES //////////////

////////////// DEPTHS //////////////
const minDepthMessage = function(min: string, empty:boolean = true): string {
    return numericMessage(min, DEFAULT_MIN_DEPTH, DEFAULT_MAX_DEPTH, empty)
}

const maxDepthMessage = function(max: string, empty:boolean = true): string {
    return numericMessage(max, DEFAULT_MIN_DEPTH, DEFAULT_MAX_DEPTH, empty)
}

export const minDepthErrorText = (min: string, max: string, t: TFunction) => {
    return filterAndJoinMessages(
        [minDepthMessage(min, true), minMaxMessage(min, max, true)],
        m => t(m, {min: DEFAULT_MIN_DEPTH, max: DEFAULT_MAX_DEPTH})
    )
}

export const maxDepthErrorText = (min: string, max: string, t: TFunction) => {
    return filterAndJoinMessages(
        [maxDepthMessage(max, true), minMaxMessage(min, max, true)],
        m => t(m, {min: DEFAULT_MIN_DEPTH, max: DEFAULT_MAX_DEPTH})
    )
}
////////////// /DEPTHS //////////////

////////////// RADIUS //////////////
const minRadiusMessage = function(min: string, empty:boolean = true): string {
    return numericMessage(min, DEFAULT_MIN_RADIUS, DEFAULT_MAX_RADIUS, empty)
}

const maxRadiusMessage = function(max: string, empty:boolean = true): string {
    return numericMessage(max, DEFAULT_MIN_RADIUS, DEFAULT_MAX_RADIUS, empty)
}

const zeroMinRadiusMessage = function(radius: string): string {
    return radius === '0' ? OK_MESSAGE : 'component__dropdown_filters__min_radius_0_error'
}

// used for crown geofence
export const minRadiusErrorText = (min: string, max: string, t: TFunction) => {
    return filterAndJoinMessages(
        [minRadiusMessage(min, false), minMaxMessage(min, max, false)],
        m => t(m, {min: DEFAULT_MIN_RADIUS, max: DEFAULT_MAX_RADIUS})
    )
}

// used for crown geofence
export const maxRadiusErrorText = (min: string, max: string, t: TFunction) => {
    return filterAndJoinMessages(
        [maxRadiusMessage(max, false), minMaxMessage(min, max, false)],
        m => t(m, {min: DEFAULT_MIN_RADIUS, max: DEFAULT_MAX_RADIUS})
    )
}

// used for circular geofence
export const radiusErrorText = function(min: string, max: string, t: TFunction): string {
    return filterAndJoinMessages(
        [zeroMinRadiusMessage(min), maxRadiusMessage(max, false)],
        m => t(m, {min: DEFAULT_MIN_RADIUS, max: DEFAULT_MAX_RADIUS})
    )
}
////////////// /RADIUS //////////////


////////////// COORDINATES //////////////
const latitudeMessage = function(latitude: string, empty:boolean = true): string {
    return numericMessage(latitude, -90, 90, empty)
}

const longitudeMessage = function(longitude: string, empty:boolean = true): string {
    return numericMessage(longitude, -180, 180, empty)
}

const radiusNotEmpty = function(radius: string): string {
    if(validNumeric(radius))
        return 'component__dropdown_filters__value_required'
    return OK_MESSAGE
}

export const latitudeErrorText = function(min: string, max: string|undefined,  minradius: string|undefined, maxradius: string|undefined, t: TFunction): string {
    let radiusError = radiusNotEmpty(maxradius ?? '')
    if(radiusError !== 'component__dropdown_filters__value_required')
        radiusError = radiusNotEmpty(minradius ?? '')
    return filterAndJoinMessages(
        [
            latitudeMessage(min ?? '', true),
            latitudeMessage(max ?? '', true),
            minMaxMessage(min, max ?? '', true),
            radiusError
        ],
        m => t(m, {min: MIN_ACCEPTABLE_LATITUDE, max: MAX_ACCEPTABLE_LATITUDE})
    )
}

export const longitudeErrorText = function(min: string, max: string|undefined,  minradius: string|undefined, maxradius: string|undefined, t: TFunction): string {
    let radiusError = radiusNotEmpty(maxradius ?? '')
    if(radiusError !== 'component__dropdown_filters__value_required')
        radiusError = radiusNotEmpty(minradius ?? '')
    return filterAndJoinMessages(
        [
            longitudeMessage(min ?? '', true),
            longitudeMessage(max ?? '', true),
            minMaxMessage(min, max ?? '', true),
            radiusError
        ],
        m => t(m, {min: MIN_ACCEPTABLE_LONGITUDE, max: MAX_ACCEPTABLE_LONGITUDE})
    )
}
////////////// /COORDINATES //////////////

////////////// VERSION ///////////////

export const typeOriginErrorText = function(min: string, max: string|undefined,  wherein: string|undefined, t: TFunction): string {

    return filterAndJoinMessages(
        [
            numericMessageInteger(min ?? '', Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY, true),
            numericMessageInteger(max ?? '', Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY, true),
            minMaxMessage(min ?? '', max ?? '', true),
            isValidTypeOriginWhereIn(wherein ?? '', true) ? OK_MESSAGE : 'component__dropdown_filters__value_required',
        ],
        m => t(m, {min: Number.NEGATIVE_INFINITY, max: Number.POSITIVE_INFINITY})
    )
}
////////////// /VERSION //////////////


////////////// GENERIC //////////////
const minMaxMessage = function (min: string, max: string, empty:boolean = true): string {
    // Number() is better then parseFloat() since conversion is stricter
    // e.g: isNan(parseFloat("3.a")) -> true, isNan(Number("3.a")) -> false    
    if(empty) {
        if(min === '' || max === '')
            return OK_MESSAGE
    }
    return Number(min) <= Number(max) ? OK_MESSAGE : 'component__dropdown_filters__min_max_error'
}

const numericMessage = function(value: string, min: number, max: number, empty:boolean = true): string {
    if(value === ''){
        // empty string is a valid value
        return empty ? OK_MESSAGE : 'component__dropdown_filters__value_required';
    }

    if(validNumeric(value)){
        let numericValue = Number(value)
        if(numericValue > max)
            return 'component__dropdown_filters__max_value_required'

        if(numericValue < min)
            return 'component__dropdown_filters__min_value_required'

        return OK_MESSAGE
    }

    return 'component__dropdown_filters__numeric_value_required';
}

const numericMessageInteger = function(value: string, min: number, max: number, empty:boolean = true): string {
    if(value === ''){
        // empty string is a valid value
        return empty ? OK_MESSAGE : 'component__dropdown_filters__value_required';
    }

    if(isValidInteger(value, min, max, empty)){
        let numericValue = Number(value)
        if(numericValue > max)
            return 'component__dropdown_filters__max_value_required'

        if(numericValue < min)
            return 'component__dropdown_filters__min_value_required'

        return OK_MESSAGE
    }

    return 'component__dropdown_filters__numeric_value_required';
}

const filterAndJoinMessages = function(array: string[], predicate: (input: string) => string): string {
    return array
        .filter(m => m !== OK_MESSAGE)
        .map(predicate)
        .join(', ')
}

