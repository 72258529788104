import { EventDetailData } from './EventDetailData'
import './EventDetailFragment.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DASHBOARD_PATH, EVENTS_PATH } from '../../routes/routes'
import { ReactComponent as IconBack } from '../../assets/back-icon.svg'
import { EVENT_TITLE_DEFAULT_SKELETON, ReducedEvent } from '../../utils'
import log from 'loglevel'
import { IconButton } from '../IconButton/IconButton'
import { EventParametersEventInner } from '../../services/network'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import BreadcrumbButtonsContainer from './BreadcrumbButtonsContainer'

interface EventDetailFragmentProps {
    event?: EventParametersEventInner,
    reducedEvent?: ReducedEvent,
    eventParametersId?: string
}
function EventDetailFragment({event, reducedEvent, eventParametersId}: EventDetailFragmentProps) {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const location = useLocation()
    const [previousLocation, setPreviousLocation] = useState<'dashboard' | 'eventList'>()

    log.debug('EventDetailFragment => ', reducedEvent)

    useEffect(() => {
        if (location.state === 'eventList') {
            setPreviousLocation('eventList')
        } else {
            setPreviousLocation('dashboard')
        }
    }, [location])

    return (
        <div className='event-detail-data-container'>
            <div className='breadcrumb-container'>
                <IconButton icon={() => <IconBack/>} onClick={() => navigate(previousLocation === 'dashboard' ? DASHBOARD_PATH : EVENTS_PATH, {replace: false})}/>
                <div className='breadcrumb-container-internal'>
                    <span className='breadcrumb-item-rtd'>
                        <Link to={previousLocation === 'dashboard' ? DASHBOARD_PATH : EVENTS_PATH}>
                            {previousLocation === 'dashboard' ? t('component__event_detail_fragment__dashboard') : t('component__event_detail_fragment__event_list')}
                        </Link>
                    </span>
                    <span className='breadcrumb-item-separator'>/</span>
                    <span className='breadcrumb-item-event-title'>{reducedEvent?.title ?? EVENT_TITLE_DEFAULT_SKELETON}</span>
                    {reducedEvent?.cancelled &&
                    <div>
                        <span className='breadcrumb-cancelled'>{t('component__event_detail_fragment__cancelled')}</span>
                    </div>
                    }
                </div>
                <BreadcrumbButtonsContainer eventParametersId={eventParametersId} />
            </div>
            <div className='event-detail-subtitle'>{reducedEvent?.subtitle ?? '--'}</div>
            <EventDetailData event={event} reducedEvent={reducedEvent} />
        </div>
    )
}

export { EventDetailFragment }