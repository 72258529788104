import log from 'loglevel'
import { useEffect, useState } from 'react'
import { StationsTable } from '../../components/StationsTable/StationsTable'
import { StationMap } from '../../components/StationMap/StationMap'
import { ApiClient } from '../../services/ApiClient'
import { ParsedStations, ParsedStationsReduced } from '../../services/network'
import './Stations.css'
import { StationsHeader } from '../../components/StationsHeader/StationsHeader'

function Stations() {
    const [stationsData, setStationsData] = useState<ParsedStations>()
    const [stationsReducedData, setStationsReducedData] = useState<ParsedStationsReduced>()
    const [showMap, setShowMap] = useState<boolean>(true)
    
    const getStationsReducedData = async () => {
        try {
            const res = await ApiClient.stationsApi().getStationsReduced()
            setStationsReducedData(res.data)
        } catch (e) {
            log.debug('EarlyestMap => Request of stations reduced data failed => ', e)
        }
    }

    const getStationsData = async () => {
        try {
            const res = await ApiClient.stationsApi().getStations()
            setStationsData(res.data)
        } catch (e) {
            log.debug('EarlyestMap => Request of stations data failed => ', e)
        }
    }

    useEffect(() => {
        getStationsReducedData()
        getStationsData()
    }, [])

    return (
        <div className='stations-container'>
            <StationsHeader 
                showMap={showMap} 
                setShowMap={setShowMap}
                totalStations={stationsData?.totalStations}
                totalActiveStations={stationsData?.totalActiveStations} />
            {showMap ? 
            <StationMap stationsData={stationsReducedData}/>
            :
            <StationsTable stations={stationsReducedData?.stations}/>
            }
        </div>
    )
}

export { Stations }