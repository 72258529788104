import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

const initialStateCancelledEvents: string[] = []

export const cancelledEventsSlice = createSlice({
  name: 'cancelledEvents',
  initialState: initialStateCancelledEvents,
  reducers: {
    resetCancelledEvents: (state) => {
      return initialStateCancelledEvents
    },
    setCancelledEvents: (state, action: PayloadAction<string[]>) => {
      return action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCancelledEvents, resetCancelledEvents } = cancelledEventsSlice.actions

export const getCancelledEvents = (state: RootState) => state.cancelledEvents

export default cancelledEventsSlice.reducer