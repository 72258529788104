import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './services/i18n'
import reportWebVitals from './reportWebVitals'
import log from 'loglevel'
import { Loader } from './components/Loader/Loader'

// Set log based on the environment
if (process.env.NODE_ENV === 'production') {
  log.disableAll()
} else {
  // log.enableAll()
  log.setLevel('debug')
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Loader/>}>
          <Routes>
            <Route path='/*' element={<App/>} />
          </Routes> 
        </Suspense>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
