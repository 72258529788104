import { useTranslation } from "react-i18next"
import './EarlyestNavbar.css'
import { ReactComponent as EarlyestLogo } from '../../assets/earlyest-logo.svg'
import { Link } from "react-router-dom"
import { RealtimeClock } from "../RealtimeClock/RealtimeClock"
import { CreationTimeViewer } from "../CreationTimeViewer/CreationTimeViewer"
import { EARLYEST_VERSION_DATE, EARLYEST_VERSION_NUMBER } from "../../utils"
import { useAppSelector } from "../../hooks/reduxCustomHooks"
import { TooltipProvider } from "../TooltipProvider/TooltipProvider"
import { DifferenceTime } from "../DifferenceTime/DifferenceTime"

function EarlyestNavbar() {
    const {t} = useTranslation()
    const currentEventParameters = useAppSelector((state) => state.currentEventParameters)

    return(
        <div className="navbar" >
            <Link to='/'>
                <EarlyestLogo/>
            </Link>
            <TooltipProvider
                tooltipFragments={[
                    {prefix: t('navbar__title__tooltip_prefix'), value: t('navbar__title__tooltip_value', {currentVersion: EARLYEST_VERSION_NUMBER, currentVersionDate: EARLYEST_VERSION_DATE})}
                ]}>
                <h1>{t('navbar__title')}</h1>
            </TooltipProvider>
            <RealtimeClock/>
            <CreationTimeViewer/>
            <DifferenceTime/>
        </div>
    )
}

export { EarlyestNavbar }