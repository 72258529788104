import './DropdownDatePicker.css'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_DAY_WEEK_NAME, DATE_FORMAT_FULL_MONTH_NAME, JUST_DATE_FORMAT_DEFAULT } from '../../utils';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CalendarButton } from './CalendarButton';
import { EarlyestButton } from '../EarlyestButton/EarlyestButton';
import { DateTimeForm } from './DateTimeForm';
import { useAppDispatch } from '../../hooks/reduxCustomHooks';
import { setqueryInterval } from '../../redux/reducers/queryInterval.slice';
import { setIsSystemLive } from '../../redux/reducers/isSystemLive.slice';

interface DropdownDatePickerProps {
    startDate: string,
    setShow: Dispatch<SetStateAction<boolean>>
} 

function DropdownDatePicker({startDate, setShow}: DropdownDatePickerProps) {
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>()
    const [activeDate, setActiveDate] = useState<dayjs.Dayjs>()
    const [now, setNow] = useState<dayjs.Dayjs>()
    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation()
    
    dayjs.extend(utc)
    dayjs.locale(i18n.language)

    useEffect(() => {
        setSelectedDate(dayjs.utc(startDate))
        setActiveDate(dayjs.utc(startDate))
        setNow(dayjs.utc())
    }, [])

    const handlePrevClick = () => {
        setSelectedDate(selectedDate?.subtract(1, 'month'))
    }

    const handleNextClick = () => {
        setSelectedDate(selectedDate?.add(1, 'month'))
    }

    const handleDayClick = (day: number, month: number, year: number) => {
        setActiveDate(selectedDate?.date(day).month(month).year(year))
    }

    const handleConfirmClick = () => {
        dispatch(setIsSystemLive(false))
        dispatch(setqueryInterval({
            startDate: activeDate?.second(0).toISOString() ?? dayjs.utc().subtract(59, 'minutes').second(0).toISOString(),
            endDate: activeDate?.add(59, 'minutes').second(59).toISOString() ?? dayjs.utc().second(59).toISOString()
        }))
        setShow(false)
    }

    const getMonthControlBar = () => {
        return (
            <>
            <CalendarButton type='prev' enabled={true} onClick={() => handlePrevClick()}/>
            <span className='calendar-header'>{selectedDate?.format(DATE_FORMAT_FULL_MONTH_NAME) ?? ''}</span>
            <CalendarButton type='next' enabled={
                ((selectedDate?.year() ?? 0) < (now?.year() ?? 0)) || ((selectedDate?.year() ?? 0) === (now?.year() ?? 0)) && ((selectedDate?.month() ?? 0) < (now?.month() ?? 0))
                } 
                onClick={() => handleNextClick()}/>
            </>
        )
    }

    const getDayHeaderBar = () => {
        const daysInit: string[] = []

        for (let i = 0; i < 7; i++) {
            daysInit.push(now?.day(i).format(DATE_FORMAT_DAY_WEEK_NAME).charAt(0).toUpperCase() ?? '')
        }

        return (
            <>
            {daysInit.map((dayName, index) => <span key={'dayofweek-' + index} className='calendar-day-header-element'>{dayName}</span>)}
            </>
        )
    }

    const getDaysGrid = () => {
        const currentSelectedMonthLength = selectedDate?.daysInMonth() ?? 0
        const currentMonthStart = selectedDate?.date(1).day() ?? 0
        const prevSelectedMonthLength = selectedDate?.subtract(1, 'month').daysInMonth() ?? 0
        const nextSelectedMonthLength = selectedDate?.add(1, 'month').daysInMonth() ?? 0

        const activeDay = activeDate?.date()
        const activeMonth = activeDate?.month()
        const activeYear = activeDate?.year()

        const days = []
        for (let i = 0; i < currentMonthStart; i++) {
            const dayOfPrevMonth = prevSelectedMonthLength - (currentMonthStart - i - 1)
            days.push(<span key={'prevmonth-' + dayOfPrevMonth} className='calendar-element calendar-element-disabled'>{dayOfPrevMonth}</span>)
        }

        for (let j = 1; j < currentSelectedMonthLength + 1; j++) {
            const selectedMonth = selectedDate?.month() ?? 0
            const selectedYear = selectedDate?.year() ?? 0

            days.push(
                <span 
                  key={'currmonth-' + j}
                  className={'calendar-element calendar-element-enabled ' + ((activeDay === j && activeMonth === selectedMonth && activeYear === selectedYear) ? 'calendar-element-selected' : '')} 
                  onClick={() => handleDayClick(j, selectedMonth, selectedYear)}>
                    {j}
                </span>
            )
        }

        const daysLength = days.length

        for (let k = 1; k < 42 - (daysLength - 1); k++) {
            days.push(<span key={'nextmonth-' + k} className='calendar-element calendar-element-disabled'>{k}</span>)
        }

        return (
            <>
            {days}
            </>
        )
    }

    const getInputBar = () => {
        return (
            <DateTimeForm
                setSelectedDate={setSelectedDate}
                activeDate={activeDate ?? dayjs.utc()} 
                setActiveDate={setActiveDate} />
        )
    }

    const getControlBar = () => {
        return (
            <div className='calendar-control-bar'>
                <EarlyestButton text={t('component__dropdown_date_picker__dismiss')} style='secondary' onClick={() => setShow(false)}/>
                <EarlyestButton text={t('component__dropdown_date_picker__confirm')} style='primary' onClick={handleConfirmClick}/>
                {/* TODO: Check that the datetime set is not in the future */}
                {/* TODO: Check for date or time errors before submitting */}
            </div>
        )
    }


    return(
        <div className='dropdown-date-picker-container'>
            <div className='calendar-internal-container'>
                {getMonthControlBar()}
                {getDayHeaderBar()}
                {getDaysGrid()}
                {getInputBar()}
            </div>
            {getControlBar()}
        </div>
    )
}

export { DropdownDatePicker }