import axios, { AxiosInstance } from 'axios'

const ELLIPSOID_URL = process.env.REACT_APP_ELLIPSOID_URL ?? 'http://localhost:8085/api/make_ee_ellipsoid?'

interface EllipsoidRequest {
    lat: number,
    lon: number,
    delta?: number, // A good default value is 10
    xx: number,
    xy: number,
    yy: number
}

const networkClient: AxiosInstance = axios.create({
    baseURL: ELLIPSOID_URL,
    headers: {
      "Content-Type": "application/json",
    },
});

const getEllipsoidData = (request: EllipsoidRequest) => {
    return networkClient.get(`${ELLIPSOID_URL}lat=${request.lat}&lon=${request.lon}&delta=${request.delta ?? 10}&xx=${request.xx}&xy=${request.xy}&yy=${request.yy}`);
}

const EllipsoidDataClient = {
    getEllipsoidData
}

export { EllipsoidDataClient }