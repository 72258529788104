import React, { useState, MouseEvent, useEffect, useMemo } from 'react'
import log from 'loglevel'
import './TooltipProvider.css'

interface TooltipFragment {
    prefix: string,
    value: string,
    prefixClass?: string,
    valueClass?: string
}

interface TooltipProviderProps {
    fixed?: boolean,
    delay?: number,
    orientation?: 'left' | 'right'
    tooltipFragments: TooltipFragment[],
    children?: React.ReactNode
}

function TooltipProvider({fixed, delay, orientation = 'left', tooltipFragments, children}: TooltipProviderProps) {
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const [tooltipPosition, setTooltipPosition] = useState<number[]>([0,0])

    const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
        if (!fixed) setTooltipPosition([e.clientX, e.clientY])
    }

    const handleMouseLeave = (e: MouseEvent<HTMLDivElement>) => {
        setShowTooltip(false)
    }

    const handleMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
        setTooltipPosition([e.clientX, e.clientY])
        setShowTooltip(true)
    }

    const evaluateTooltipLength = (fragments: TooltipFragment[]) => {
        // There's a little bit of heuristic here...
        const coefficient = 8 // This is an estimate pixel per char
        let length = 9 // This is to take into account white spaces
        fragments.forEach(fragment => {
            length += fragment.prefix.length
            length += fragment.value.length
        })
        return length * coefficient
    }

    const tooltipLength = useMemo(() => evaluateTooltipLength(tooltipFragments), [tooltipFragments])

    useEffect(() => {
        if (delay) {
            if (showTooltip) {
                const tooltipTimer = setTimeout(() => setTooltipVisible(true), delay)
                return () => clearTimeout(tooltipTimer)
            } else {
                setTooltipVisible(false)
            }
        } else {
            setTooltipVisible(showTooltip)
        }
    }, [showTooltip])

    return (
        <div className='tooltip-provider' 
             onMouseLeave={handleMouseLeave} 
             onMouseMove={handleMouseMove}
             onMouseEnter={handleMouseEnter}>
            {tooltipVisible && 
            <div className='tooltip-container' style={{top: `${tooltipPosition[1]+20}px`, left: `${orientation === 'left' ? tooltipPosition[0]+20 : tooltipPosition[0]-tooltipLength}px`}}>
                {tooltipFragments.map((fragment) => (
                    <React.Fragment key={fragment.prefix}>
                    <span className={`${fragment.prefixClass ? fragment.prefixClass : 'tooltip-fragment-prefix'}`}>{fragment.prefix}</span>
                    <span className={`${fragment.valueClass ? fragment.valueClass : 'tooltip-fragment-value'}`}>{fragment.value}</span>
                    </React.Fragment>
                ))}
            </div>
            }

            {children}

        </div>    
    )
}

export type { TooltipFragment }
export { TooltipProvider }