import { Link } from 'react-router-dom'
import './InfoPage.css'
import infoPageBackground from '../../assets/info-page-background.png'
import { INFO_PATH } from '../../routes/routes'
import { EARLYEST_VERSION_DATE, EARLYEST_VERSION_NUMBER } from '../../utils'
import { useEffect, useState } from 'react'
import { ApiClient } from '../../services/ApiClient'
import log from 'loglevel'

function InfoPage() {
    const [apiVersion, setApiVersion] = useState<string>('xx.xx.xx')

    const getApiVersion = async () => {
        try {
            const res = await ApiClient.apiApi().getApiVersion()
            setApiVersion(res.data)
        } catch (e) {
            log.debug('InfoPage => Request of API version failed => ', e)
        }
    }

    useEffect(() => {
        getApiVersion()
    }, [])

    return (
        <div className='info-page-container'>
            <div className='info-page-header' style={{backgroundImage: `url(${infoPageBackground})`}}>
                <span>Earthquake Rapid Location System with</span>
                <span>Estimation of Tsunamigenesis</span>
            </div>
            <div className='info-page-sidebar'>
                <Link to={{pathname: INFO_PATH, hash: '#contributors'}}><span>Contributors</span></Link>
                <Link to={{pathname: INFO_PATH, hash: '#sw-version'}}>Software version</Link>
                <Link to={{pathname: INFO_PATH, hash: '#be-wa-version'}}>BE & Webapp version</Link>
                <Link to={{pathname: INFO_PATH, hash: '#further-info'}}>Further information</Link>
            </div>
            <div className='info-page-body'>
                <div className='info-page-body-item'>
                    <div className='info-page-body-item-title' id='contributors'>Contributors</div>
                    <div className='info-page-body-item-content'>
                    Early-est at INGV is managed by Valentino Lauciani. This work is supported by INGV - Centro Nazionale Terremoti institutional funds and by the EC n.262330 NERA 2010-2014 project. INGV (ingv.it), IRIS DMC (www.iris.edu), GFZ Data Archive (geofon.gfz-potsdam.de) and GEOSCOPE (geoscope.ipgp.fr) provided access to waveforms used in this and cited studies. 
                    </div>
                </div>
                <div className='info-page-body-item'>
                    <div className='info-page-body-item-title' id='sw-version'>Software version</div>
                    <div className='info-page-body-item-content'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </div>
                </div>
                <div className='info-page-body-item'>
                    <div className='info-page-body-item-title' id='be-wa-version'>BE & Webapp version</div>
                    <div className='info-page-body-item-content'>
                    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                    <span>EarlyEst WebApp Version: v{EARLYEST_VERSION_NUMBER} ({EARLYEST_VERSION_DATE})</span>
                    <span>EarlyEst API Version: v{apiVersion}</span>
                    </div>
                </div>
                <div className='info-page-body-item'>
                    <div className='info-page-body-item-title' id='further-info'>Further information</div>
                    <div className='info-page-body-item-content'>
                        <span>Times and dates shown refer to the Coordinated Universal Time (UTC) System, to accommodate the timekeeping differences that arise between atomic time (which is derived from atomic clocks) and solar time (which is derived from astronomical measurements of Earth's rotation on its axis relative to the Sun).</span>
                        <span>This site is experimental and the result of research work. It provides information from a fully automatic procedure that provides very rapid estimation of earthquake location, magnitude, focal mechanism and tsunamigenic potential. The results are NOT reviewed by a seismologist. It is NOT to be used for any operative procedure toward tsunami alert or warning. Relaunching of the information here contained does not make INGV liable of any responsibility and it remains full responsibility of the person/institution/agency carrying it out.</span>
                        <span>Specifically, the earthquake derived tsunami potential is based on the value of the Td*T50Ex Warning Level. This evaluation only concerns the likelihood that this earthquake generated a regional or larger scale tsunami. This evaluation DOES NOT concern the size and effects of a possible tsunami, which depend on details of the earthquake source, ocean bathymetry, coastal distances and population density, and many other factors.) This evaluation DOES NOT apply to and DOES NOT EXCLUDE the possibility that this earthquake generated a local tsunami, which can be destructive along coasts within a 100 km or more from the earthquake epicenter.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { InfoPage }