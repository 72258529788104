import { useState, MouseEvent } from 'react'
import './SideButton.css'

interface SideButtonProps {
    selected: boolean,
    icon: (fillcolor: string) => JSX.Element,
    onClick: Function,
    tooltipText?: string
}

function SideButton({selected, icon, onClick, tooltipText}: SideButtonProps) {
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const [tooltipPosition, setTooltipPosition] = useState<number[]>([0,0])

    const handleMouseLeave = (e: MouseEvent<HTMLDivElement>) => {
        setShowTooltip(false)
    }

    const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
        setTooltipPosition([e.clientX, e.clientY])
    }

    const handleMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
        setTooltipPosition([e.clientX, e.clientY])
        setShowTooltip(true)
    }

    const selectedColor = '#FF832B'
    const unselectedColor = '#C6C6C6'

    return(
        <div 
            className={'sidebutton ' + (selected && 'sidebutton-selected')} 
            onClick={() => onClick()}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            onMouseEnter={handleMouseEnter}>
            {tooltipText && showTooltip && 
            <div className='sidebutton-tooltip-container' style={{top: `${tooltipPosition[1]+20}px`, left: `${tooltipPosition[0]+20}px`}}>
                    <span className='sidebutton-tooltip-value'>{tooltipText}</span>
            </div>
            }
            {icon(selected ? selectedColor : unselectedColor)}
        </div>
    )
}

export { SideButton }