import './IntervalViewer.css'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT_DEFAULT_WITH_UTC } from '../../utils'
import { ReactComponent as CalendarIcon } from '../../assets/calendar-icon.svg'
import { useAppSelector } from '../../hooks/reduxCustomHooks'
import { DropdownDatePicker } from '../DropdownDatePicker/DropdownDatePicker'

interface IntervalViewerProps {
    enabled: boolean
}

function IntervalViewer({enabled}: IntervalViewerProps) {
    const [date, setDate] = useState<string>()
    const [show, setShow] = useState<boolean>(false)
    const isSystemLive = useAppSelector((state) => state.isSystemLive)
    const { i18n } = useTranslation()

    const queryInterval = useAppSelector((state) => state.queryInterval)
    
    dayjs.extend(utc)
    dayjs.locale(i18n.language)

    const formatDate = (date: string) => {
        return dayjs.utc(date).format(DATE_FORMAT_DEFAULT_WITH_UTC)
    }

    const handleClick = () => {
        if (enabled) {
            setShow(!show)
        }
    }

    return(
        <div className='interval-viewer-container'>
            <span className={'interval-viewer ' + (show ? 'interval-viewer-active' : '')} onClick={handleClick}>
                {formatDate(queryInterval.startDate)}   <CalendarIcon />
            </span>
            {show && 
                <DropdownDatePicker startDate={queryInterval.startDate} setShow={setShow} />
            }
        </div>
    )
}

export { IntervalViewer }