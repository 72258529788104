import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

const initialStateNowTime: string = ''

export const nowTimeSlice = createSlice({
  name: 'nowTime',
  initialState: initialStateNowTime,
  reducers: {
    resetNowTime: (state) => {
      return initialStateNowTime
    },
    setNowTime: (state, action: PayloadAction<string>) => {
      return action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { resetNowTime, setNowTime } = nowTimeSlice.actions

export const getnowTime = (state: RootState) => state.nowTime

export default nowTimeSlice.reducer