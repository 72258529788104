import { useTranslation } from "react-i18next"

interface DoubleRowEventTitleProps {
    color: string,
    title: string,
    subtitle: string,
    cancelled: boolean
}

function DoubleRowEventTitle({color, title, subtitle, cancelled}: DoubleRowEventTitleProps) {
    const {t} = useTranslation()
    return (
        <div className='event-title' style={{borderLeft: `4px solid ${color}`}}>
            <div className='event-title-main'>
                {title}
                {cancelled &&
                <span className='event-title-cancelled'>{t('component__double_row_event_title__cancelled')}</span>
                }
            </div>
            <span className='event-title-sub'>{subtitle}</span>
        </div>
    )
}

export { DoubleRowEventTitle }