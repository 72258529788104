import { debug } from "console"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import log from "loglevel"
import { DATE_FORMAT_DEFAULT, EVENT_COLORS, formatNumberToFixed, JUST_DATE_FORMAT_DEFAULT, StringNumberMap, TIME_FORMAT_DEFAULT } from "."
import { EventParameters, EventParametersEventInner, EventParametersEventInnerDiscriminantInner, EventParametersEventInnerMagnitudeInner, EventParametersEventInnerOriginArrivalInner, EventParametersEventInnerPickInner, RecentEvent, Station, StationReduced } from "../services/network"
import { NONAME } from "dns"

type PrimaryMagnitude = 'mb' | 'mwp' | 'mwpd' | 'none'

interface EventStation {
    stationKey: string,
    arrivalTime: string,
    mb: string,
    mwp: string,
    mwpd: string,
    t50ex: string,
    t50: string,
    td: string,
    azimuth: string,
    distance: string
}

interface StringEventStationMap {
    [key: string]: EventStation
}

interface ReducedEvent {
    id: string,
    title: string,
    subtitle: string,
    originTime: string,
    lat: string,
    lon: string,
    depth: string,
    depthUnc: string,
    mb: string,
    mbLowUnc: string,
    mbUpUnc: string,
    mbStations: string,
    mwp: string,
    mwpLowUnc: string,
    mwpUpUnc: string,
    mwpStations: string,
    mwpd: string,
    mwpdLowUnc: string,
    mwpdUpUnc: string,
    mwpdStations: string,
    primaryMag: PrimaryMagnitude,
    t0: string,
    t0LowUnc: string,
    t0UpUnc: string,
    t0Stations: string,
    t50ex: string,
    t50exStations: string,
    td: string,
    tdStations: string,
    originMinHorUnc: string,
    originMaxHorUnc: string,
    originAzMaxHorUnc: string,
    eventId: string,
    originId: string,
    stations: StringEventStationMap,
    color: string,
    positionIndex: number,
    cancelled: boolean,
    xx: string,
    xy: string,
    yy: string
}

interface ReducedEventParameters {
    creationTime: string,
    version: string,
    duration: string,
    columnStart: number,
    columnSpan: number,
    events: ReducedEvent[]
}

interface ReducedPick {
    eventId: string,
    originId: string,
    pickId: string,
    arrivalTime: string,
    arrivalDate: string,
    mb: string,
    mwp: string,
    mwpd: string,
    t50: string,
    t50ex: string,
    td: string,
    t0: string,
    status: string,
    color: string
}

dayjs.extend(utc)

const titleGenerator = (originTime?: string, seqNum?: string) => {
    return `${dayjs.utc(originTime ?? '--').format(DATE_FORMAT_DEFAULT)} - Version ${seqNum ?? '--'}`
}

const subtitleGenerator = (region?: string) => {
    return `${region ?? '--'}`
}

const extractMagnitudes = (event: EventParametersEventInner): { 
    mb: EventParametersEventInnerMagnitudeInner | undefined, 
    mwp: EventParametersEventInnerMagnitudeInner | undefined,
    mwpd: EventParametersEventInnerMagnitudeInner | undefined
} => {
    let mb: EventParametersEventInnerMagnitudeInner | undefined = undefined
    let mwp: EventParametersEventInnerMagnitudeInner | undefined = undefined
    let mwpd: EventParametersEventInnerMagnitudeInner | undefined = undefined

    for (let magnitude of event.magnitude ?? []) {
        switch (magnitude.type) {
            case 'mb':
                mb = magnitude
                break
            case 'Mwp':
                mwp = magnitude
                break
            case 'Mwpd':
                mwpd = magnitude
                break
        }
    }

    return { mb, mwp, mwpd}
}

const extractDiscriminant = (discType: string, discriminants?: EventParametersEventInnerDiscriminantInner[]): EventParametersEventInnerDiscriminantInner | undefined => {
    return discriminants?.filter((discriminant) => discriminant.type === discType)[0] ?? undefined
}

const extractStations = (picks: EventParametersEventInnerPickInner[], arrivals: EventParametersEventInnerOriginArrivalInner[]): StringEventStationMap => {
    const stations: StringEventStationMap = {}
    
    log.debug(`extractStations => Extracting stations from ${picks.length} picks and ${arrivals.length} arrivals`)

    picks.forEach((pick, pickIndex) => {
        const waveformID = pick.waveformID
        const net = waveformID?.networkCode ?? '--'
        const sta = waveformID?.stationCode ?? '--'
        let loc = waveformID?.locationCode ?? '--'
        // const chan = waveformID?.channelCode ?? '--'
 
        if (loc.length === 1) loc = `0${loc}`

        log.trace(`extractStations => Processing pick ${pickIndex} of ${picks.length}`)

        const arrival = arrivals.find((arrival) => arrival.pickID === pick.publicID)
        // log.trace('extractStations => All arrivals => ', arrivals)
        // log.trace('extractStations => Arrival found => ', arrival)
        log.trace('extractStations => Arrival found => ', arrival?.publicID ?? 'NoID')

        const stationKey = `${net}.${sta}.${loc}`//.${chan}
        stations[stationKey.toUpperCase()] = {
            stationKey,
            arrivalTime: dayjs.utc(pick.time?.value).format(DATE_FORMAT_DEFAULT),
            mb: arrival?.mb ? formatNumberToFixed(2, arrival?.mb) : '--',
            mwp: arrival?.Mwp ? formatNumberToFixed(2, arrival?.Mwp) : '--',
            mwpd: arrival?.MwpdCorr ? formatNumberToFixed(2, arrival?.MwpdCorr) : '--',
            t50ex: pick.T50Ex ? formatNumberToFixed(1, pick.T50Ex) : '--',
            t50: pick.T50 ? formatNumberToFixed(1, pick.T50) : '--',
            td: pick.Td ? formatNumberToFixed(1, pick.Td) : '--',
            azimuth: arrival?.azimuth ? formatNumberToFixed(2, arrival?.azimuth) : '--',
            distance: arrival?.distance ? formatNumberToFixed(2, arrival?.distance) : '--'
        }
    })
    return stations
}

const evaluatePositionIndex = (eventPositionIndexList: StringNumberMap, eventId: string) => {
    const positionIndex = eventPositionIndexList[eventId]
    if (positionIndex) {
        // Return the position if it is already in the list
        return positionIndex
    } else {
        // Assign the new position to the event (based on the amount of previous events) and return it
        const newPosition = Object.keys(eventPositionIndexList).length + 1
        eventPositionIndexList[eventId] = newPosition
        return newPosition
    }
}

const stationToStationReduced = (station: Station): StationReduced => {
    return {
        net: station.net ?? '--',
        sta: station.sta ?? '--',
        loc: station.loc ?? '--',
        chans: station.chans?.map(chan => {return {name: chan.name, latency: chan.latency}}) ?? [],
        lat: station.lat ?? '--',
        lon: station.lon ?? '--',
        latency: station.latency ?? '--'
    }
}

const evaluatePrimaryMag = (mb: number, mbStations: number, mwp: number, mwpStations: number, mwpd: number, mwpdStations: number): PrimaryMagnitude => {
    if (mwpStations >= 6 && mwpdStations >= 6 && mwp > 7.2) {
        return 'mwpd'
    }

    if (mwpStations >= 6 && mwp >= 5.0) {
        return 'mwp'
    }

    if (mbStations >= 6) {
        return 'mb'
    }

    return 'none'
}

const recentEventToReducedEvent = (recentEvent: RecentEvent): ReducedEvent => {
    return {
        id: '--',
        title: '--',
        subtitle: subtitleGenerator(recentEvent.region),
        originTime: dayjs.utc(recentEvent.originTime).format(DATE_FORMAT_DEFAULT),
        lat: formatNumberToFixed(2, recentEvent.lat),
        lon: formatNumberToFixed(2, recentEvent.lon),
        depth: '--',
        depthUnc: '--',
        mb: recentEvent.mb ? formatNumberToFixed(2, recentEvent.mb) : '--',
        mbLowUnc: '--',
        mbUpUnc: '--',
        mbStations: '--',
        mwp: recentEvent.mwp ? formatNumberToFixed(2, recentEvent.mwp) : '--',
        mwpLowUnc: '--',
        mwpUpUnc: '--',
        mwpStations: '--',
        mwpd: recentEvent.mwpd ? formatNumberToFixed(2, recentEvent.mwpd) : '--',
        mwpdLowUnc: '--',
        mwpdUpUnc: '--',
        mwpdStations: '--',
        primaryMag: 'none',
        t0: recentEvent.t0 ? formatNumberToFixed(1, recentEvent.t0) : '--',
        t0LowUnc: '--',
        t0UpUnc: '--',
        t0Stations: '--',
        t50ex: recentEvent.t50ex ? formatNumberToFixed(1, recentEvent.t50ex) : '--',
        t50exStations: '--',
        td: recentEvent.td ? formatNumberToFixed(1, recentEvent.td) : '--',
        tdStations: '--',
        originMinHorUnc: '--',
        originMaxHorUnc: '--',
        originAzMaxHorUnc: '--',
        eventId: recentEvent.eventId ?? '--',
        originId: recentEvent.originId ?? '--',
        stations: {},
        color: '--',
        positionIndex: -1,
        cancelled: recentEvent.cancelled ?? false,
        xx: '--',
        xy: '--',
        yy: '--'
    }
}

const eventReducedListCreator = (eventParametersList: EventParameters[], cancelledEvents: string[]): ReducedEventParameters[] => {

    const eventPositionIndexList: StringNumberMap = {}
    let totalSlotsUsed = 0
    
    log.debug(`eventReducedListCreator => Processing a total of ${eventParametersList.length} EventParameters`)

    const reducedEventsList = eventParametersList.map((eventParameters, index, list) => {
        const columnStart = totalSlotsUsed + 1
        let columnSpan
        let duration

        log.debug(`eventReducedListCreator => EventParameters ${index} of ${eventParametersList.length} is being processed, contains ${eventParameters.event?.length ?? 0} Events`)

        if (index === list.length - 1) {
            columnSpan = 60 - totalSlotsUsed // This sets the slot minimum width to 1 minute. If you want to change that, change the "60" here and set an appropriate number of columns in the CSS grid of the Timeline
            duration = 'unknown'
        } else {
            const timeSinceNext = dayjs.utc(dayjs.utc(list[index+1].creationInfo?.creationTime)).diff(eventParameters.creationInfo?.creationTime, 'second')
            columnSpan = timeSinceNext < 60 ? 1 : Math.floor(timeSinceNext / 60) // This sets the slot minimum width to 1 minute. If you want to change that, change the "60" here and set an appropriate number of columns in the CSS grid of the Timeline
            duration = timeSinceNext < 60 ? `${timeSinceNext}s` : `${Math.floor(timeSinceNext / 60)}m${timeSinceNext % 60}s`
        }

        totalSlotsUsed = totalSlotsUsed + columnSpan

        const reducedEvents: ReducedEvent[] = eventParameters.event?.map((event, eventIndex): ReducedEvent => {
            log.debug(`eventReducedListCreator => Event ${eventIndex} of ${eventParameters.event?.length ?? 0} is being processed, contains ${event.pick?.length ?? 0} picks and ${event.origin?.arrival?.length ?? 0} arrivals`)

            const {mb, mwp, mwpd} = extractMagnitudes(event)
            const t0 = extractDiscriminant('T0', event.discriminant)
            const t50ex = extractDiscriminant('T50Ex', event.discriminant)
            const td = extractDiscriminant('Td', event.discriminant)
            const positionIndex = evaluatePositionIndex(eventPositionIndexList, event.publicID?.split('/')?.pop() ?? '')
            const stations = extractStations(event.pick ?? [], event.origin?.arrival ?? [])
            const cancelled = cancelledEvents.includes(event.publicID?.split('/')?.pop() ?? '0')
        
            return {
                id: event._id ?? '--',
                title: titleGenerator(event.origin?.time?.value, event.origin?.quality?.report_count),
                subtitle: subtitleGenerator(event.origin?.region),
                originTime: dayjs.utc(event.origin?.time?.value).format(DATE_FORMAT_DEFAULT),
                lat: formatNumberToFixed(4, event.origin?.latitude?.value),
                lon: formatNumberToFixed(4, event.origin?.longitude?.value),
                depth: formatNumberToFixed(1, event.origin?.depth?.value, 1000),
                depthUnc: formatNumberToFixed(1, event?.origin?.depth?.uncertainty, 1000),
                mb: mb?.mag?.value ? formatNumberToFixed(2, mb?.mag?.value) : '--',
                mbLowUnc: mb?.mag?.lowerUncertainty ? formatNumberToFixed(2, mb?.mag?.lowerUncertainty) : '--',
                mbUpUnc: mb?.mag?.upperUncertainty ? formatNumberToFixed(2, mb?.mag?.upperUncertainty) : '--',
                mbStations: mb?.stationCount ?? '0',
                mwp: mwp?.mag?.value ? formatNumberToFixed(2, mwp?.mag?.value) : '--',
                mwpLowUnc: mwp?.mag?.lowerUncertainty ? formatNumberToFixed(2, mwp?.mag?.lowerUncertainty) : '--',
                mwpUpUnc: mwp?.mag?.upperUncertainty ? formatNumberToFixed(2, mwp?.mag?.upperUncertainty) : '--',
                mwpStations: mwp?.stationCount ?? '0',
                mwpd: mwpd?.mag?.value ? formatNumberToFixed(2, mwpd?.mag?.value) : '--',
                mwpdLowUnc: mwpd?.mag?.lowerUncertainty ? formatNumberToFixed(2, mwpd?.mag?.lowerUncertainty) : '--',
                mwpdUpUnc: mwpd?.mag?.upperUncertainty ? formatNumberToFixed(2, mwpd?.mag?.upperUncertainty) : '--',
                mwpdStations: mwpd?.stationCount ?? '0',
                primaryMag: evaluatePrimaryMag(+(mb?.mag?.value ?? '0'), +(mb?.stationCount ?? '0'), +(mwp?.mag?.value ?? '0'), +(mwp?.stationCount ?? '0'), +(mwpd?.mag?.value ?? '0'), +(mwpd?.stationCount ?? '0')),
                t0: t0?.disc?.value ? formatNumberToFixed(1, t0?.disc?.value) : '--',
                t0LowUnc: t0?.disc?.lowerUncertainty ? formatNumberToFixed(1, t0?.disc?.lowerUncertainty) : '--',
                t0UpUnc: t0?.disc?.upperUncertainty ? formatNumberToFixed(1, t0?.disc?.upperUncertainty) : '--',
                t0Stations: t0?.stationCount ?? '0',
                t50ex: t50ex?.disc?.value ? formatNumberToFixed(2, t50ex?.disc?.value) : '--',
                t50exStations: t50ex?.stationCount ?? '0',
                td: td?.disc?.value ? formatNumberToFixed(2, td?.disc?.value) : '--',
                tdStations: td?.stationCount ?? '0',
                originMinHorUnc: event.origin?.originUncertainty?.minHorizontalUncertainty ? formatNumberToFixed(2, event.origin?.originUncertainty?.minHorizontalUncertainty) : '--',
                originMaxHorUnc: event.origin?.originUncertainty?.maxHorizontalUncertainty ? formatNumberToFixed(2, event.origin?.originUncertainty?.maxHorizontalUncertainty) : '--',
                originAzMaxHorUnc: event.origin?.originUncertainty?.azimuthMaxHorizontalUncertainty ? formatNumberToFixed(2, event.origin?.originUncertainty?.azimuthMaxHorizontalUncertainty) : '--',
                eventId: event.publicID ?? '--',
                originId: event.origin?.publicID ?? '--',
                stations,
                positionIndex, // Set the position index of the event
                color: EVENT_COLORS[positionIndex % EVENT_COLORS.length], // Set the color of the event based on the position
                cancelled,
                xx: event.origin?.originUncertainty?.covariance?.xx ? formatNumberToFixed(2, event.origin?.originUncertainty?.covariance?.xx) : '--',
                xy: event.origin?.originUncertainty?.covariance?.xy ? formatNumberToFixed(2, event.origin?.originUncertainty?.covariance?.xy) : '--',
                yy: event.origin?.originUncertainty?.covariance?.yy ? formatNumberToFixed(2, event.origin?.originUncertainty?.covariance?.yy) : '--'
            }
        }) ?? []

        return {
            creationTime: eventParameters.creationInfo?.creationTime ?? '',
            version: eventParameters.creationInfo?.version ?? '--',
            duration,
            columnStart,
            columnSpan,
            events: reducedEvents
        }
    }) ?? []

    return reducedEventsList

}

const reducedPickCreator = (eventParameters: EventParameters, reducedEvents: ReducedEventParameters): ReducedPick[] => {
    const reducedPicks: ReducedPick[] = []
    const events = eventParameters.event ?? []
    
    events.forEach((event, eventIndex) => {
        const eventPicks = event.pick ?? []
        const eventArrivals = event.origin?.arrival ?? []

        eventArrivals.forEach(arrival => {
            const pickForArrival = eventPicks.find(pick => arrival.pickID === pick.publicID)

            reducedPicks.push({
                eventId: event.publicID?.split('/')?.pop() ?? '--',
                originId: event.origin?.publicID?.split('/')?.pop() ?? '--',
                pickId: pickForArrival?.publicID?.split('/').pop() ?? '--',
                arrivalTime: dayjs.utc(pickForArrival?.time?.value).format(TIME_FORMAT_DEFAULT),
                arrivalDate: dayjs.utc(pickForArrival?.time?.value).format(JUST_DATE_FORMAT_DEFAULT),
                mb: arrival.mb ?? '--',
                mwp: arrival.Mwp ?? '--',
                mwpd: arrival.MwpdCorr ?? '--',
                t50: pickForArrival?.T50 ?? '--',
                t50ex: pickForArrival?.T50Ex ?? '--',
                td: pickForArrival?.Td ?? '--',
                t0: pickForArrival?.T0 ?? '--',
                status: '--', // TODO: [INGV] Where do we get this?
                color: reducedEvents.events.find(reducedEvent => reducedEvent.eventId === event.publicID)?.color ?? '#FFFFFF'
            })
        });
    })

    log.debug('reducedPickCreator => Computed Picks => ', reducedPicks)
    
    return reducedPicks
}

export type { EventStation, StringEventStationMap, ReducedEvent, ReducedEventParameters, ReducedPick, PrimaryMagnitude }
export { eventReducedListCreator, recentEventToReducedEvent, stationToStationReduced, reducedPickCreator }