import axios, { AxiosInstance } from 'axios'

const TTIME_URL = process.env.REACT_APP_TTIME_URL ?? 'http://localhost:8083/api/get_phase_circle?'

interface TTimeRequest {
    lat: number,
    lon: number,
    depth: number,
    time: number,
    phases: string[],
    azimuthInterval: number
}

const networkClient: AxiosInstance = axios.create({
    baseURL: TTIME_URL,
    headers: {
      "Content-Type": "application/json",
    },
});

const getTTimeData = (request: TTimeRequest) => {
    return networkClient.get(`${TTIME_URL}lat=${request.lat}&lon=${request.lon}&depth=${request.depth}&time=${request.time}&phases=${request.phases.join(',')}&azimuth_interval=${request.azimuthInterval}`);
}

const TTimeDataClient = {
    getTTimeData
}

export { TTimeDataClient }