import 'dayjs/locale/it'
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import './DoubleRowRegion.css'

interface DoubleRowRegionParams {
    regionStr: string,
    className?: string,
}

function DoubleRowRegion({regionStr, className}: DoubleRowRegionParams) {

    let region = regionStr;
    let country = '';

    if(regionStr.indexOf('[') > 0){
        region = regionStr.substring(0, regionStr.indexOf('[') - 1)
        country = regionStr.substring(regionStr.indexOf('[') + 1, regionStr.indexOf(']'))
    }

    return (
        <CustomTooltip placement="top" title={regionStr} arrow>                    
            <div className={country === '' ? `doublerowregion-container-single h-36 ${className}` : `doublerowregion-container ${className ?? ''}`}>
                <span className={`doublerowregion-region`}>{region}</span>
                {country !== '' && <span className='doublerowregion-country'>{country}</span>}
            </div>
        </CustomTooltip>
    )
}

export { DoubleRowRegion }
