import { useTranslation } from 'react-i18next'
import { ReducedEvent } from '../../utils'
import { Accordion } from '../Accordion/Accordion'
import { Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement
} from 'chart.js'
import { EventParametersEventInner } from '../../services/network'
import { useAppSelector } from '../../hooks/reduxCustomHooks'
import { Loading } from '@carbon/react'
import { EventDetailFocalMechanism } from './EventDetailFocalMechanism'
import { useMemo } from 'react'
import { DecisionTable } from '../DecisionTable/DecisionTable'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement
  )

interface EventDetailDataProps {
    event?: EventParametersEventInner
    reducedEvent?: ReducedEvent
}

interface ChartsValues {
    t0IntervalsCounts: number[],
    mbIntervalsCounts: number[],
    mwpIntervalsCounts: number[],
    mwpdIntervalsCounts: number[]
}

function EventDetailData({event, reducedEvent}: EventDetailDataProps) {
    const {t} = useTranslation()
    const isLoading = useAppSelector((state) => state.isLoading)

    // Set the intervals, min/max range and labels for T0 and the magnitudes
    const t0Interval = 10
    const t0MinValue = 0
    const t0MaxValue = 500
    const magnitudeInterval = 0.2
    const magnitudeMinValue = 3.5
    const magnitudeMaxValue = 9.5
    const t0Labels: string[] = useMemo(() => {
        const tmpT0Labels = []
        for (let i = Math.floor(t0MinValue / t0Interval); i <= Math.floor(t0MaxValue / t0Interval); i++) {
            tmpT0Labels.push(`${(i * t0Interval).toFixed(0)}`)
        }
        return tmpT0Labels
    }, [])
    const magnitudeLabels: string[] = useMemo(() => {
        const tmpMagnitudeLabels = []
        for (let i = Math.floor(magnitudeMinValue / magnitudeInterval); i <= Math.floor(magnitudeMaxValue / magnitudeInterval); i++) {
            tmpMagnitudeLabels.push(`${(i * magnitudeInterval).toFixed(1)}`)
        }
        return tmpMagnitudeLabels
    }, [])

    const chartsValues: ChartsValues = useMemo(() => {

        // Initialize the intervals count
        let t0IntervalsCounts: {[interval: number]: number} = {}
        let mbIntervalsCounts: {[interval: number]: number} = {}
        let mwpIntervalsCounts: {[interval: number]: number} = {}
        let mwpdIntervalsCounts: {[interval: number]: number} = {}
        for (let i = Math.floor(t0MinValue / t0Interval); i <= Math.floor(t0MaxValue / t0Interval); i++) {
            t0IntervalsCounts[i] = 0
        }
        for (let i = Math.floor(magnitudeMinValue / magnitudeInterval); i <= Math.floor(magnitudeMaxValue / magnitudeInterval); i++) {
            mbIntervalsCounts[i] = 0
            mwpIntervalsCounts[i] = 0
            mwpdIntervalsCounts[i] = 0
        }

        // Populate the T0 intervals counts
        event?.pick?.forEach(pickInner => {
            if (pickInner.T0 != null && !isNaN(parseFloat(pickInner.T0))) {
                const t0Value = parseFloat(pickInner.T0)
                if (t0Value >= t0MinValue && t0Value <= t0MaxValue) {
                    const t0IntervalIndex = Math.floor(t0Value / t0Interval)
                    t0IntervalsCounts[t0IntervalIndex]++
                }
            }
        })

        // Populate the magnitudes intervals counts
        event?.origin?.arrival?.forEach(arrivalInner => {
            if (arrivalInner.mb != null && !isNaN(parseFloat(arrivalInner.mb))) {
                const mbValue = parseFloat(arrivalInner.mb)
                if (mbValue >= magnitudeMinValue && mbValue <= magnitudeMaxValue) {
                    const mbIntervalIndex = Math.floor(mbValue / magnitudeInterval)
                    mbIntervalsCounts[mbIntervalIndex]++
                }
            }
            if (arrivalInner.Mwp != null && !isNaN(parseFloat(arrivalInner.Mwp))) {
                const mwpValue = parseFloat(arrivalInner.Mwp)
                if (mwpValue >= magnitudeMinValue && mwpValue <= magnitudeMaxValue) {
                    const mwpIntervalIndex = Math.floor(mwpValue / magnitudeInterval)
                    mwpIntervalsCounts[mwpIntervalIndex]++
                }
            }
            if (arrivalInner.MwpdCorr != null && !isNaN(parseFloat(arrivalInner.MwpdCorr))) {
                const mwpdValue = parseFloat(arrivalInner.MwpdCorr)
                if (mwpdValue >= magnitudeMinValue && mwpdValue <= magnitudeMaxValue) {
                    const mwpdIntervalIndex = Math.floor(mwpdValue / magnitudeInterval)
                    mwpdIntervalsCounts[mwpdIntervalIndex]++
                }
            }
        })

        return {
            t0IntervalsCounts: Object.values(t0IntervalsCounts),
            mbIntervalsCounts: Object.values(mbIntervalsCounts),
            mwpIntervalsCounts: Object.values(mwpIntervalsCounts),
            mwpdIntervalsCounts: Object.values(mwpdIntervalsCounts)
        }
    }, [event])

    const chartOptions = {
        maintainAspectRatio: false,
        indexAxis: 'y' as const,
        scales: {
            y: {
            beginAtZero: true,
            categoryPercentage: 1,
            barPercentage: 0.95,
            maxBarThickness: 12,
            grid: {color: '#262626'}
            },
            x: { grid: {color: '#262626'} }
        },
        layout: { padding: -4 },
        plugins: {
            legend: {display: false},
            tooltip: {enabled: false}
        }
    }

    const mbData = {
        labels: magnitudeLabels,
        datasets: [{
          data: chartsValues.mbIntervalsCounts,
             backgroundColor: '#3DDBD9',
        }]
    }

    const mwpData = {
        labels: magnitudeLabels,
        datasets: [{
          data: chartsValues.mwpIntervalsCounts,
             backgroundColor: '#3DDBD9',
        }]
    }

    const mwpdData = {
        labels: magnitudeLabels,
        datasets: [{
          data: chartsValues.mwpdIntervalsCounts,
             backgroundColor: '#3DDBD9',
        }]
    }

    const t0Data = {
        labels: t0Labels,
        datasets: [{
          data: chartsValues.t0IntervalsCounts,
             backgroundColor: '#3DDBD9',
        }]
    }

    // TODO: Magnitude on Y axis, intervals of 0.2; Number of stations on X axis. T0, value on Y axis, intervals of 10; number of stations on X axis

    if (isLoading) {
        return  <div className='event-detail-data-list event-detail-data-loading'>
                    <Loading className='event-detail-data-loading-child' description="Loading indicator" withOverlay={false} small={true} />
                    <span className='event-detail-data-loading-child'> {t('component__event_list__placeholder_loading')}</span>
                </div>
    }

    return (
        <div className='event-detail-data-list'>
            <Accordion title={t('component__event_detail_data__origin')}>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__event_detail_data__latitude')}</div>
                    <div className='data-detail-label'>{t('component__event_detail_data__longitude')}</div>
                    <div className='data-detail-label'>{t('component__event_detail_data__depth')}</div>
                    <div className='data-detail-label'>{t('component__event_detail_data__quality')}</div>
                    <div className='data-detail-value'>{reducedEvent?.lat ?? '--'}</div>
                    <div className='data-detail-value'>{reducedEvent?.lon ?? '--'}</div>
                    <div className='data-detail-value'>{t('component__event_detail_data__depth_value', {depth: reducedEvent?.depth ?? '--', uncertainty: reducedEvent?.depthUnc ?? '--'})}</div>
                    <div className='data-detail-value'>{event?.origin?.quality?.qualityIndicators?.qualityCode ?? '--'}</div>
                </div>
            </Accordion>
            <Accordion title={t('component__event_detail_data__magnitude')}>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__event_detail_data__mb')}</div>
                    <div className='data-detail-label'>{t('component__event_detail_data__mwp')}</div>
                    <div className='data-detail-label'>{t('component__event_detail_data__mwpd')}</div>
                    <div className='data-detail-label'>{t('component__event_detail_data__t0')}</div>
                    <div className='data-detail-value'>
                        <span className={`data-mag-value${reducedEvent?.primaryMag === 'mb' && ' data-mag-value-primary'}`}>
                            {t('component__event_detail_data__m_value', {mValue: reducedEvent?.mb ?? '--'})}
                        </span>
                        <span className='data-detail-value-small'>
                            {t('component__event_detail_data__m_unc', {mLowUnc: reducedEvent?.mbLowUnc ?? '--', mUpUnc: reducedEvent?.mbUpUnc ?? '--'})}
                        </span>
                    </div>
                    <div className='data-detail-value'>
                        <span className={`data-mag-value${reducedEvent?.primaryMag === 'mwp' && ' data-mag-value-primary'}`}>
                            {t('component__event_detail_data__m_value', {mValue: reducedEvent?.mwp ?? '--'})}
                        </span>
                        <span className='data-detail-value-small'>
                            {t('component__event_detail_data__m_unc', {mLowUnc: reducedEvent?.mwpLowUnc ?? '--', mUpUnc: reducedEvent?.mwpUpUnc ?? '--'})}
                        </span>
                    </div>
                    <div className='data-detail-value'>
                        <span className={`data-mag-value${reducedEvent?.primaryMag === 'mwpd' && ' data-mag-value-primary'}`}>
                            {t('component__event_detail_data__m_value', {mValue: reducedEvent?.mwpd ?? '--'})}
                        </span>
                        <span className='data-detail-value-small'>
                            {t('component__event_detail_data__m_unc', {mLowUnc: reducedEvent?.mwpdLowUnc ?? '--', mUpUnc: reducedEvent?.mwpdUpUnc ?? '--'})}
                        </span>
                    </div>
                    <div className='data-detail-value'>
                        <span className='data-mag-value'>
                            {t('component__event_detail_data__t_value', {tValue: reducedEvent?.t0 ?? '--'})}
                        </span>
                        <span className='data-detail-value-small'>
                            {t('component__event_detail_data__t_unc', {tLowUnc: reducedEvent?.t0LowUnc ?? '--', tUpUnc: reducedEvent?.t0UpUnc ?? '--'})}
                        </span>
                    </div>
                    <div className='data-detail-subvalue'>{t('component__event_detail_data__stations_used', {numberOfStations: reducedEvent?.mbStations ?? '--'})}</div>
                    <div className='data-detail-subvalue'>{t('component__event_detail_data__stations_used', {numberOfStations: reducedEvent?.mwpStations ?? '--'})}</div>
                    <div className='data-detail-subvalue'>{t('component__event_detail_data__stations_used', {numberOfStations: reducedEvent?.mwpdStations ?? '--'})}</div>
                    <div className='data-detail-subvalue'>{t('component__event_detail_data__stations_used', {numberOfStations: reducedEvent?.t0Stations ?? '--'})}</div>
                    <div className='chart-container'><Bar options={chartOptions} data={mbData} /></div>
                    <div className='chart-container'><Bar options={chartOptions} data={mwpData} /></div>
                    <div className='chart-container'><Bar options={chartOptions} data={mwpdData} /></div>
                    <div className='chart-container'><Bar options={chartOptions} data={t0Data} /></div>
                </div>
            </Accordion>
            <Accordion title={t('component__event_detail_data__decision_table')}>
                <DecisionTable reducedEvent={reducedEvent} />
            </Accordion>
            <Accordion title={t('component__event_detail_data__focal_mechanisms')}>
                {event?.focalMechanismPolarity &&
                <EventDetailFocalMechanism type='polarity' dataRaw={event.focalMechanismPolarity}/>
                }
                {event?.focalMechanismPolarityWaveform &&
                <EventDetailFocalMechanism type='polarity_waveform' dataRaw={event.focalMechanismPolarityWaveform}/>
                }
                {event?.focalMechanismHash &&
                <EventDetailFocalMechanism type='hash' dataRaw={event.focalMechanismHash}/>
                }
            </Accordion>
            <Accordion title={t('component__event_detail_data__more_info')}>
                <div className='data-detail-four-column'>
                        <div className='data-detail-label'>{t('component__event_detail_data__event_id')}</div>
                        <div className='data-detail-label'>{t('component__event_detail_data__origin_id')}</div>
                        <div className='data-detail-label'></div>
                        <div className='data-detail-label'></div>
                        <div className='data-detail-value'>{reducedEvent?.eventId?.split('/').pop() ?? '--'}</div>
                        <div className='data-detail-value'>{reducedEvent?.originId?.split('/').pop() ?? '--'}</div>
                        <div className='data-detail-value'></div>
                        <div className='data-detail-value'></div>
                </div>
            </Accordion>
        </div>
    )
}

export { EventDetailData }