import './EarlyestMap.css'
import 'leaflet/dist/leaflet.css'
import Leaflet, { LatLng, LatLngTuple, LeafletMouseEvent, Map as LeafletMap } from 'leaflet'
import { MapContainer, Marker, GeoJSON, TileLayer, ZoomControl } from "react-leaflet"
import log from 'loglevel'
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react'
import { ReducedEvent, StringBooleanMap } from '../../utils'
import { TectonicDataClient } from '../../services/TectonicDataClient'
import { EarlyestDropdown } from '../EarlyestDropdown/EarlyestDropdown'
import { ReactComponent as IconLayers } from '../../assets/layers-icon.svg'
import { DropdownLayers } from '../DropdownLayers/DropdownLayers'
import { ApiClient } from '../../services/ApiClient'
import { ParsedStationsReduced, RecentEvent } from '../../services/network'
import { useAppSelector } from '../../hooks/reduxCustomHooks'
import { EventPopup } from './EventPopup'
import { StationMarker } from './StationMarker'
import { recentEventToReducedEvent } from '../../utils/eventReducedListCreator'
import { TTimeDataClient } from '../../services/TTimeClient'
import { EllipsoidDataClient } from '../../services/EllipsoidClient'
import MapCenterButton from './MapCenterButton'
import EventMarker from './EventMarker'
import OfflineBanner from '../OfflineBanner/OfflineBanner'
import { CoordinatesBox } from './CoordinatesBox'

interface EarlyestMapProps {
    events: ReducedEvent[],
    creationTime?: string,
    isEventDetail?: boolean,
    eventDetail?: ReducedEvent,
}

interface EventMinimum {
    eventId: string,
    eventColor: string,
    eventTime: string,
    eventTitle: string
}

interface StringEventMinimumMap {
    [key: string]: EventMinimum[]
}

interface StringPhasesDataMap {
    [key: string]: any
}

interface StringEllipsoidDataMap {
    [key: string]: any
}

function EarlyestMap({events, creationTime, isEventDetail, eventDetail}: EarlyestMapProps) {
    const isSystemLive = useAppSelector((state) => state.isSystemLive)
    const [phasesData, setPhasesData] = useState<StringPhasesDataMap>({})
    const [showPhases, setShowPhases] = useState<boolean>(isEventDetail ? true : false)
    const [ellipsoidData, setEllipsoidData] = useState<StringEllipsoidDataMap>({})
    const [showEllipsoid, setShowEllipsoid] = useState<boolean>(false)
    const [tectonicData, setTectonicData] = useState<any>(undefined)
    const [showTectonic, setShowTectonic] = useState<boolean>(true) // Is default for both the Dashboard and the Event Detail page
    const [recentEventsData, setRecentEventsData] = useState<Array<RecentEvent>>([])
    const [showRecentEvents, setShowRecentEvents] = useState<boolean>(!isEventDetail ? true : false)
    const [showStations, setShowStations] = useState<boolean>(false)
    const [showAssociatedStations, setShowAssociatedStations] = useState<boolean>(isEventDetail ? true : false)
    const [showUnassociatedStations, setShowUnassociatedStations] = useState<boolean>(false)
    const [stationsData, setStationsData] = useState<ParsedStationsReduced | undefined>(undefined)
    const [associatedStations, setAssociatedStations] = useState<StringBooleanMap>({})
    const [eventsForStations, setEventsForStations] = useState<StringEventMinimumMap>({})
    const [shouldUpdateMapBounds, setShouldUpdateMapBounds] = useState<boolean>(false)
    const [firstTimeUpdateMapBounds, setFirstTimeUpdateMapBounds] = useState<boolean>(true)
    const map = createRef<LeafletMap>()
    const { t, i18n } = useTranslation()
    dayjs.extend(utc)
    dayjs.locale(i18n.language)

    // TODO: Investigate here https://earthquake.usgs.gov/earthquakes/map/?extent=-82.85338,-3112.38281&extent=84.33698,-2707.38281&settings=true
    const defaultMapBounds: LatLngTuple[] = useMemo(() => [[33, -10], [50, 38]], []);

    const updateMapBounds = (currentEvents?: ReducedEvent[]) => {
        if (currentEvents === undefined || !(firstTimeUpdateMapBounds || shouldUpdateMapBounds)) return
        let arrayBounds: LatLngTuple[] = currentEvents.map(event => [+event.lat, +event.lon])

        if(arrayBounds.length !== 0){ // to avoid crash on empty bounds
            console.log('Dashboard => arrayBounds => new bounds to apply')
        } else {
            console.log('Dashboard => arrayBounds => appliyng default')
            arrayBounds = defaultMapBounds
        }
        map.current?.flyToBounds(new Leaflet.LatLngBounds(arrayBounds), {maxZoom: 5})
        setFirstTimeUpdateMapBounds(false)
    }

    const getPhasesData = (events: ReducedEvent[]) => {
        try {
            let newPhasesData: StringPhasesDataMap = {}

            if (creationTime) {
                events.map(async (event) => {
                    const res = await TTimeDataClient.getTTimeData({
                        lat: +event.lat,
                        lon: +event.lon,
                        depth: +event.depth,
                        time: dayjs.utc(creationTime).diff(dayjs.utc(event.originTime), 'seconds'),
                        phases: ['P', 'S'],
                        azimuthInterval: 0.5
                    })

                    newPhasesData[event.eventId] = res.data.data
                })
            }

            setPhasesData(newPhasesData)
        } catch (e) {
            log.debug('EarlyestMap => Request of phases data failed => ', e)
        }
    }

    const getEllipsoidData = (events: ReducedEvent[]) => {
        try {
            let newEllipsoidData: StringEllipsoidDataMap = {}

            if (creationTime) {
                events.forEach(async (event) => {
                    try {
                        const res = await EllipsoidDataClient.getEllipsoidData({
                            lat: +event.lat,
                            lon: +event.lon,
                            xx: +event.xx,
                            xy: +event.xy,
                            yy: +event.yy
                        })

                        newEllipsoidData[event.eventId] = res.data
                    } catch (e) {
                        log.debug('EarlyestMap => Request of ellipsoid data failed => ', e)
                    }
                })
            }
            
            setEllipsoidData(newEllipsoidData)
        } catch (e) {
            log.debug('EarlyestMap => Request of ellipsoid data failed => ', e)
        }
    }

    const getStationsData = async () => {
        try {
            const res = await ApiClient.stationsApi().getStationsReduced()
            setStationsData(res.data)
        } catch (e) {
            log.debug('EarlyestMap => Request of stations data failed => ', e)
        }
    }

    const getTectonicData = async () => {
        try {
            const res = await TectonicDataClient.getTectonicData()
            setTectonicData(res.data)
        } catch (e) {
            log.debug('EarlyestMap => Request of tectonic date failed => ', e)
        }
    }

    const getRecentEventsData = async () => {
        try {
            log.debug('EarlyestMap => Fetching recent sismicity data...')
            const res = await ApiClient.recentEventsApi().getRecentEvents()
            setRecentEventsData(res.data)
        } catch (e) {
            log.debug('EarlyestMap => Request of recent events failed => ', e)
        }
    }

    const flattenEventsStations = (events?: ReducedEvent[]) => {
        const newAssociatedStations: StringBooleanMap = {}
        const newEventsForStations: StringEventMinimumMap = {}
        if (events) {
            for (let event of events) {
                Object.keys(event.stations).forEach((key) => {
                    newAssociatedStations[key] = event.stations[key] !== undefined
                    const eventMinimum = {
                        eventId: event.eventId,
                        eventColor: event.color,
                        eventTime: event.originTime,
                        eventTitle: event.title
                    }

                    newEventsForStations[key] = newEventsForStations[key] ? [...newEventsForStations[key], eventMinimum] : [eventMinimum]
                })
            }
        }
        setAssociatedStations(newAssociatedStations)
        setEventsForStations(newEventsForStations)
    }

    const closePopup = useCallback(() => map.current?.closePopup(), [map])

    useEffect(() => {
        if (showRecentEvents) getRecentEventsData()
    }, [showRecentEvents])

    useEffect(() => {
        if (showStations) getStationsData()
    }, [showStations])

    useEffect(() => {
        if (showPhases) getPhasesData(events)
    }, [showPhases, events])

    useEffect(() => {
        if (showEllipsoid) getEllipsoidData(events)
    }, [showEllipsoid, events])

    useEffect(() => {
        const eventDetailArray = eventDetail ? [eventDetail] : undefined
        updateMapBounds(isEventDetail ? eventDetailArray : events)
        flattenEventsStations(isEventDetail ? eventDetailArray : events)
     }, [events, eventDetail])

    const handleUpdateMousePosition = useCallback((callback: (latlng: L.LatLng) => void) => {
        // Assuming you have access to the map instance:
        map.current?.on('mousemove', (e: L.LeafletMouseEvent) => {
            callback(e.latlng)
        })
    }, [map])

    useEffect(() => {
        getTectonicData()
        getStationsData()
        setFirstTimeUpdateMapBounds(true)
    }, [])
    
    const mapContainer = useMemo(() => (
        <MapContainer 
            className='dashboard-map'
            zoomControl={false}
            scrollWheelZoom={true}
            zoom={7}
            // minZoom={3} 
            // maxZoom={19}
            // maxBounds={[[-90, -Infinity], [90, Infinity]]}
            bounds={defaultMapBounds}
            attributionControl={false /* Follow your coscience here... :) */} 
            style={
                isEventDetail ?
                {height: 'calc(100vh - 56px - 56px)', width: 'calc(100vw - 56px - 24px - 776px)'} : 
                {height: 'calc(100vh - 56px - 56px)', width: 'calc(100vw - 56px - 416px)'} /* 56px is the size of the navbar, the sidebar and the timeline, 24px is the column gap */
            }
            ref={map}>
            <ZoomControl position='bottomright'/>
            <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/Tile/{z}/{y}/{x}"
                attribution='&copy; <a href="https://www.arcgis.com/index.html">ArcGIS Online</a> contributors'
            />
            {/* This is probably useless (or worse, harmful), but if needed can easily be added back
            <TileLayer
                url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-labels/{z}/{x}/{y}{r}.png"
            /> */}
            {showTectonic && tectonicData &&
                <GeoJSON data={tectonicData} style={{color: '#FFB784', weight: 2}} />
            }
            {!isEventDetail && showStations && stationsData?.stations && stationsData.stations.map((station) => 
                <StationMarker
                    key={`${station.net}.${station.sta}.${station.loc}`}
                    station={station}
                    isStationAssociated={associatedStations[`${station.net}.${station.sta}.${station.loc}`]}
                    closePopup={closePopup}
                    isSystemLive={isSystemLive}
                    isEventDetail={isEventDetail}
                    eventsForStation={eventsForStations[`${station.net}.${station.sta}.${station.loc}`]}
                    eventDetail={eventDetail} />
            )}
            {isEventDetail && showAssociatedStations && stationsData?.stations && stationsData.stations.filter((station) => associatedStations[`${station.net}.${station.sta}.${station.loc}`]).map((station) =>  
                <StationMarker
                    key={`${station.net}.${station.sta}.${station.loc}`}
                    station={station}
                    isStationAssociated={associatedStations[`${station.net}.${station.sta}.${station.loc}`]}
                    closePopup={closePopup}
                    isSystemLive={isSystemLive}
                    isEventDetail={isEventDetail}
                    eventsForStation={eventsForStations[`${station.net}.${station.sta}.${station.loc}`]}
                    eventDetail={eventDetail} />
            )}
            {isEventDetail && showUnassociatedStations && stationsData?.stations && stationsData.stations.filter((station) => !associatedStations[`${station.net}.${station.sta}.${station.loc}`]).map((station) => 
                <StationMarker
                    key={`${station.net}.${station.sta}.${station.loc}`}
                    station={station}
                    isStationAssociated={associatedStations[`${station.net}.${station.sta}.${station.loc}`]}
                    closePopup={closePopup}
                    isSystemLive={isSystemLive}
                    isEventDetail={isEventDetail}
                    eventsForStation={eventsForStations[`${station.net}.${station.sta}.${station.loc}`]}
                    eventDetail={eventDetail} />
            )}
            {showRecentEvents && recentEventsData?.length > 0 && recentEventsData.map((recentEvent) => {
                    log.debug('EarlyestMap => Placing a marker in {} {}, for eventID {}', +(recentEvent.lat ?? '0'), +(recentEvent.lon ?? '0'), recentEvent.eventId)
                    return (
                    <Marker
                        key={`${recentEvent.eventId}-recent-marker`}
                        position={[+(recentEvent.lat ?? '0'), +(recentEvent?.lon ?? '0')]}
                        zIndexOffset={0}
                        icon={new Leaflet.DivIcon({
                            html: `<div class='dot-marker-recent'></div>`,
                            className: 'no-show'
                        })} >
                        <EventPopup event={recentEventToReducedEvent(recentEvent)} closePopup={closePopup} />
                    </Marker>
                    )
                })
            }
            {events.map((event, index) => {
                    return (
                        <React.Fragment key={event.id}>
                        <EventMarker
                            key={`${event.id}-marker`}
                            position={[+event.lat, +event.lon]}
                            riseOnHover={true}
                            isSecondary={isEventDetail && (!eventDetail || eventDetail.eventId !== event.eventId)}
                            markerColor={event.color}>
                            <EventPopup event={event} closePopup={closePopup} />
                        </EventMarker>
                        {showPhases && phasesData[event.eventId] &&
                        <GeoJSON data={phasesData[event.eventId]} style={{color: event.color, weight: 1, opacity: 0.7}} />
                        }
                        {showEllipsoid && ellipsoidData[event.eventId] &&
                        <GeoJSON data={ellipsoidData[event.eventId]} style={{color: event.color, weight: 1, opacity: 0.7}} />
                        }
                        </React.Fragment>
                    )
                })}
        </MapContainer>
    ), [defaultMapBounds, isEventDetail, showTectonic, tectonicData, showStations, stationsData?.stations, showAssociatedStations, showUnassociatedStations, showRecentEvents, recentEventsData, events, associatedStations, closePopup, isSystemLive, eventsForStations, eventDetail, showPhases, phasesData, showEllipsoid, ellipsoidData])

    return(
        <>
        <div className='map-layer-control'>
            <MapCenterButton shouldUpdateMapBounds={shouldUpdateMapBounds} setShouldUpdateMapBounds={setShouldUpdateMapBounds} />
            <EarlyestDropdown
                text={t('component__dropdown_layers__text')}
                icon={<IconLayers />}>
                <DropdownLayers
                    isEventDetail={isEventDetail ?? false}
                    phasesToggled={showPhases}
                    phasesHandleToggle={setShowPhases}
                    ellipsoidToggled={showEllipsoid}
                    ellipsoidHandleToggle={setShowEllipsoid}
                    tectonicPlatesToggled={showTectonic}
                    tectonicPlatesHandleToggle={setShowTectonic}
                    recentEventsToggled={showRecentEvents}
                    recentEventsHandleToggle= {setShowRecentEvents}
                    stationsToggled={showStations}
                    stationsHandleToggle={setShowStations}
                    stationsEventUnassToggled={showUnassociatedStations}
                    stationsEventUnassHandleToggle={setShowUnassociatedStations}
                    stationsEventAssToggled={showAssociatedStations}
                    stationsEventAssHandleToggle={setShowAssociatedStations} />
            </EarlyestDropdown>
        </div>
        <OfflineBanner show={!isSystemLive} />
        <CoordinatesBox isEventDetail={isEventDetail ?? false} updateMousePosition={handleUpdateMousePosition} />
        {mapContainer}
        </>
    )
}

export type { EventMinimum, StringEventMinimumMap }
export { EarlyestMap }