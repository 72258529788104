import './EarlyestDashboard.css'
import { EventsList } from "../../components/EventsList/EventsList"

import { EarlyestMap } from "../../components/EarlyestMap/EarlyestMap"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks"
import { Timeline } from "../../components/Timeline/Timeline"
import { useEffect } from 'react'
import { resetIsSystemLive } from '../../redux/reducers/isSystemLive.slice'
import { resetCurrentEventParameters } from '../../redux/reducers/currentEventParameters.slice'

function EarlyestDashboard() {
    const requestError = useAppSelector((state) => state.requestError)
    const reducedEvents = useAppSelector((state) => state.reducedEvents)
    const currentEventParameters = useAppSelector((state) => state.currentEventParameters)
    const dispatch = useAppDispatch()

    return(
        <div className='dashboard-container'>
            <EventsList events={reducedEvents?.events ?? []} error={requestError} />
            <EarlyestMap events={reducedEvents?.events ?? []} creationTime={currentEventParameters.creationInfo?.creationTime} />
            <Timeline className='timeline-dashboard' />
        </div>
    )
}

export { EarlyestDashboard }