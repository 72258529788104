import { useEffect, useState } from 'react';
import './DropdownFilters.css'
import { useTranslation } from "react-i18next";
import {
    TextInput, Button
} from '@carbon/react';
import {FiltersDateTimeSelector, NewIntervalType, SerializableIntervalType} from '../FiltersDateTimeSelector/FiltersDateTimeSelector';
import GeofenceSelector, {CoordinateRef} from '../GeofenceSelector/GeofenceSelector';
import {
    isValidMinMaxMagnitude,
    isValidMinMaxDepth,
    isValidMagnitude,
    isValidDepth,
} from '../../utils'
import { defaultFilters, Filters, resetFilters, setFilters } from '../../redux/reducers/filters.slice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxCustomHooks';

import dayjs from 'dayjs'

import { maxDepthErrorText, maxMagErrorText, minDepthErrorText, minMagErrorText } from './messages';
import { disableButton } from './utils';
import {batch} from "react-redux";
import log from "loglevel";
import {
    resetTimePicker,
    setStoreFullInterval,
} from "../../redux/reducers/timepicker.slice";
import {DATETIME_TAG, SORT_DESC} from "../CustomTable/TableUtils";

type DropdownFiltersProps = {
    cancelCallback: () => void
}

const DropdownFilters = (props: DropdownFiltersProps) => {

    const {t} = useTranslation()
    const filters = useAppSelector((state) => state.filters)
    const timepicker = useAppSelector((state) => state.timepicker)
    const [fullInterval, setFullInterval] = useState<NewIntervalType|undefined>(timepicker.fullInterval)

    let {cancelCallback} = props
    let [localFilters, setLocalFilters] = useState<Filters>({...filters})
    const dispatch = useAppDispatch()

    useEffect(() => setLocalFilters({...filters}), [filters]) // this is not invoked until the Apply button is clicked

    useEffect(() => {
        if(timepicker.fullInterval) {
            if('days' in timepicker.fullInterval){
                if(timepicker && timepicker.fullInterval && timepicker.fullInterval.days) {
                    setLocalFilters({
                        ...localFilters,
                        starttime: `${dayjs().subtract(timepicker.fullInterval.days, 'day').format('YYYY-MM-DDT00:00:00')}.000Z`,
                        endtime: undefined,
                    })
                }
            } else if('startDate' in timepicker.fullInterval) {
                let {startDate, startHour, endDate, endHour} = timepicker.fullInterval
                let starttime = `${startDate.split('/').reverse().join('-')}T${startHour}.000Z`

                let endtime = undefined
                if(endDate && endDate.length > 0)
                    endtime = `${endDate.split('/').reverse().join('-')}T${endHour}.000Z`
                setLocalFilters({...localFilters, starttime, endtime})
            }
        }
    }, [timepicker])

    useEffect(() => {
        console.log('fullInterval =>', fullInterval)
        if(timepicker.fullInterval)
            setFullInterval(timepicker.fullInterval)
    },  [timepicker])

    useEffect(() => {
        console.log('fullInterval =>', fullInterval)
    },  [fullInterval])

    const setMinMag = (e: React.FormEvent<HTMLInputElement>) => setLocalFilters({...localFilters, minmag: e.currentTarget.value})
    const setMaxMag = (e: React.FormEvent<HTMLInputElement>) => setLocalFilters({...localFilters, maxmag: e.currentTarget.value})
    const setMinDepth =  (e: React.FormEvent<HTMLInputElement>) => setLocalFilters({...localFilters, mindepth: e.currentTarget.value})
    const setMaxDepth =  (e: React.FormEvent<HTMLInputElement>) => setLocalFilters({...localFilters, maxdepth: e.currentTarget.value})
    const applyFilters = () => {
        batch(() => {
            dispatch(setFilters({...localFilters, page: 1, sortDirection: SORT_DESC, sortHeader: DATETIME_TAG}))
            if(fullInterval)
                dispatch(setStoreFullInterval(fullInterval))
        })

    }

    useEffect(() => console.log('DF =>', timepicker.fullInterval), [timepicker])

    return <>
        <div className="dropdown-filters-menu-48">
            <div className='dropdown-filters-menu-container'>
                <p className='title mb-8'>{t('component__dropdown_filters__filters_title')}</p>
                <div className='divider' />
                <div className='overflowed' id="overflowed">
                    <FiltersDateTimeSelector
                        onUpdate={(interval ) => {
                            let s: SerializableIntervalType
                            console.log('FiltersDateTimeSelector => DF => interval =>', interval)
                            if(interval && 'days' in interval) {
                                s = {days: interval.days}
                            } else if(interval && 'startDate' in interval) {
                                // ideally this is correct as interval
                                let starttime = `${interval.startDate.split('/').reverse().join('-')}T${interval.startHour}.000Z`

                                let endtime = null
                                if(interval.endDate && interval.endDate.length > 0)
                                    endtime = `${interval.endDate.split('/').reverse().join('-')}T${interval.endHour}.000Z`

                                console.log('FiltersDateTimeSelector => starttime =>', starttime)
                                console.log('FiltersDateTimeSelector => endtime =>', endtime)
                                s = {
                                    startDate: starttime,
                                    endDate: endtime,
                                }
                            } else {
                                return;
                            }

                            log.debug('DateTimeRangePicker => s =>', s)
                            log.debug('DateTimeRangePicker => interval =>', interval)

                            setFullInterval(interval)
                            // dispatch(setDatePickerTime(s))
                        }}
                        />

                    <p className='subtitle mb-16'>{t('component__dropdown_filters__magnitude')}</p>
                    <div className='mg-label-container mb-32'>
                        <div
                            className='me-4 mg-label'>
                            <p className='label mg-label mb-8'>{t('component__dropdown_filters__magnitude_min')}</p>
                            <TextInput
                                invalidText={minMagErrorText(localFilters.minmag ?? '', localFilters.maxmag ?? '', t)}
                                labelText=''
                                className="input"
                                id="test1"
                                hideLabel
                                value={localFilters.minmag}
                                onChange={setMinMag}
                                invalid={!(isValidMagnitude(localFilters.minmag ?? '', true) && isValidMinMaxMagnitude(localFilters.minmag ?? '', localFilters.maxmag ?? '', true))}
                                placeholder={'e.g. 0.00'}
                            />
                        </div>
                        <div
                            className='ms-4 mg-label'>
                            <p className='label mg-label mb-8'>{t('component__dropdown_filters__magnitude_max')}</p>
                            <TextInput
                                invalidText={maxMagErrorText(localFilters.minmag ?? '', localFilters.maxmag ?? '', t)}
                                labelText=''
                                className="input"
                                id="test2"
                                hideLabel
                                value={localFilters.maxmag}
                                onChange={setMaxMag}
                                invalid={!(isValidMagnitude(localFilters.maxmag ?? '', true) && isValidMinMaxMagnitude(localFilters.minmag ?? '', localFilters.maxmag ?? '', true))}
                                placeholder={'e.g. 10.00'}
                            />
                        </div>
                    </div>

                    <GeofenceSelector 
                        shape={localFilters.shape}
                        selectedGeofence= {{
                            key: localFilters.ref_key,
                            region: localFilters.ref_region,
                            max_lat: localFilters.maxlat,
                            min_lat: localFilters.minlat,
                            max_lon: localFilters.maxlon,
                            min_lon: localFilters.minlon,
                            lat: localFilters.lat,
                            lon: localFilters.lon,
                            minradius: localFilters.minradiuskm,
                            maxradius: localFilters.maxradiuskm,
                        }}
                        onUpdate={(reference: CoordinateRef, shape: string) => {
                            setLocalFilters({
                                ...localFilters,
                                minlat: reference.min_lat,                                
                                minlon: reference.min_lon,
                                maxlat: reference.max_lat,
                                maxlon: reference.max_lon,
                                lat: reference.lat,
                                lon: reference.lon,
                                minradiuskm: reference.minradius,
                                maxradiuskm: reference.maxradius,
                                shape,
                                ref_key: reference.key,
                                ref_region: reference.region,
                            })
                        }}
                    />
                    
                    <p className='subtitle mb-16'>{t('component__dropdown_filters__depth')}</p>
                    <div className='mg-label-container mb-32'>
                        <div
                            className='me-4 mg-label'>
                            <p className='label mg-label me-4 mb-8'>{t('component__dropdown_filters__depth_min')}</p>
                            <TextInput
                                value={localFilters.mindepth ?? ''}
                                labelText=''
                                className="input"
                                id="test1"
                                hideLabel
                                placeholder={'e.g. 0.00'}
                                invalidText={minDepthErrorText(localFilters.mindepth ?? '', localFilters.maxdepth ?? '', t)}
                                onChange={setMinDepth}
                                invalid={!(isValidDepth(localFilters.maxdepth ?? '') && isValidMinMaxDepth(localFilters.mindepth ?? '', localFilters.maxdepth ?? ''))}
                            />
                        </div>
                        <div
                            className='ms-4 mg-label'>
                            <p className='label mg-label mb-8'>{t('component__dropdown_filters__depth_max')}</p>
                            <TextInput
                                value={localFilters.maxdepth ?? ''}
                                labelText=''
                                className="input"
                                id="test2"
                                hideLabel
                                placeholder={'e.g. 1000.00'}
                                invalidText={maxDepthErrorText(localFilters.mindepth ?? '', localFilters.maxdepth ?? '', t)}
                                onChange={setMaxDepth}
                                invalid={!(isValidDepth(localFilters.maxdepth ?? '') && isValidMinMaxDepth(localFilters.mindepth ?? '', localFilters.maxdepth ?? ''))}
                            />
                        </div>
                    </div>

                </div>
                <div className='divider mb-48'/>
                <div className='button-container'>
                    <Button
                        className="bea-btn-transparent remove-button"
                        onClick={() => {
                            setLocalFilters(defaultFilters)
                            batch(() => {
                                dispatch(resetTimePicker())
                                dispatch(resetFilters())
                            })

                            cancelCallback()
                        }}>
                        {t('component__dropdown_filters__remove_filters')}
                    </Button>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            className="button bea-btn-black me-4"
                            onClick={() => {
                                // clear localFilters
                                cancelCallback()
                            }}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            disabled={disableButton(localFilters, fullInterval)}
                            className="button bea-btn ms-4"
                            onClick={() => {
                                // apply localFilters
                                console.log('localFilters', localFilters);
                                applyFilters()
                                cancelCallback()
                            }}
                        >
                            {t('apply')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </>

}

export default DropdownFilters
