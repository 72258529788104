import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EventParameters } from '../../services/network'
import { RootState } from '../store'
import log from 'loglevel'

const initialStateEventParametersList: EventParameters[] = []

export const eventParametersListSlice = createSlice({
  name: 'eventParametersList',
  initialState: initialStateEventParametersList,
  reducers: {
    resetEventParametersList: (state) => {
      log.debug('Redux => Resetting EventParametersList')
      return initialStateEventParametersList
    },
    setEventParametersList: (state, action: PayloadAction<EventParameters[]>) => {
      log.debug('Redux => Setting new EventParametersList - Length: ' + action?.payload?.length)
      return action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setEventParametersList, resetEventParametersList } = eventParametersListSlice.actions

export const getEventParametersList = (state: RootState) => state.eventParametersList

export default eventParametersListSlice.reducer