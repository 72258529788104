import './EventsNavbar.css'
import {DropdownFiltersButton} from "../FiltersDropdown/DropdownFiltersButton";
const EventsNavbar = function () {
    return(<>
        <div className={'events-navbar-container'}>
            <div className={'navbar-title-container'}>
                <span className={'navbar-title'}>Earthquake List</span>
            </div>
            <DropdownFiltersButton />
        </div>
    </>)
}

export {
    EventsNavbar
}