import { useTranslation } from 'react-i18next'
import './StationsTable.css'
import { StationReduced } from '../../services/network'
import { EeTable, EeTableDataRow, EeTableHeader } from '../EeTable/EeTable'
import { useMemo } from 'react'
import { LatencyDisplay } from './LatencyDisplay'
import { STATION_PATH } from '../../routes/routes'

interface StationsTableProps {
    stations?: StationReduced[]
}

function StationsTable({stations}: StationsTableProps) {
    const {t} = useTranslation()

    const generateStationsDataRow = (stations?: StationReduced[]): EeTableDataRow[] => {
        if (!stations || stations.length === 0) return []
        return stations.map((station) => ({
            onClick: STATION_PATH.replace(':stationId', `${station.net ?? '--'}.${station.sta ?? '--'}.${station.loc ?? '--'}`),
            cells: {
                id: `${station.net ?? '--'}.${station.sta ?? '--'}.${station.loc ?? '--'}`,
                net: station.net ?? '--',
                sta: station.sta ?? '--',
                loc: station.loc ?? '--',
                chans: station.chans?.flatMap(chan => chan.name)?.join(', ') ?? '--',
                lat: station.lat ?? '--',
                lon: station.lon ?? '--',
                elev: +(station.elev ?? '--'),
                latency: {
                    value: <LatencyDisplay latency={+(station.latency ?? '-1')} chans={station.chans ?? []} />,
                    sortableValue: +(station.latency ?? '-1')
                }
            }
        }))
    }

    const headers: EeTableHeader[] = [
        {key: 'net', label: t('component__stations_table__net'), sortable: true},
        {key: 'sta', label: t('component__stations_table__sta'), sortable: true},
        {key: 'loc', label: t('component__stations_table__loc'), sortable: true},
        {key: 'chans', label: t('component__stations_table__chans'), sortable: true, spaceFraction: 2},
        {key: 'lat', label: t('component__stations_table__lat'), sortable: true},
        {key: 'lon', label: t('component__stations_table__lon'), sortable: true},
        {key: 'elev', label: t('component__stations_table__elev'), sortable: true},
        {key: 'latency', label: t('component__stations_table__latency'), sortable: true},
    ]

    const data: EeTableDataRow[] = useMemo(() => generateStationsDataRow(stations), [stations])

    return(
        <EeTable
            className='stations-table'
            headers={headers}
            data={data} />
    )
}

export { StationsTable }
