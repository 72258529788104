import { ReactComponent as ForwardIcon } from '../../assets/forward-icon.svg'
import { ReactComponent as BackwardIcon } from '../../assets/backward-icon.svg'
import { ReactComponent as PreviousSlotIcon } from '../../assets/previous-slot-icon.svg'
import { ReactComponent as NextSlotIcon } from '../../assets/next-slot-icon.svg'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

type TimelineButtonType = 'live' | 'forward' | 'backward' | 'previousSlot' | 'nextSlot'

interface TimelineButtonProps {
    type: TimelineButtonType,
    onClick: any,
    enabled?: boolean
    isSystemLive?: boolean
}

function TimelineButton({type, onClick, enabled, isSystemLive}: TimelineButtonProps) {
    const {t} = useTranslation()

    const getButtonColor = (enabled?: boolean) => {
        return enabled ? '#C6C6C6' : '#525252'
    }

    switch (type) {
        case 'forward':
            return(
                <div className={'tl-button ' + (enabled ? '' : 'tl-button-disabled')} onClick={onClick}>
                    <ForwardIcon fill={getButtonColor(enabled)} />
                </div>
            )
        case 'backward':
            return(
                <div className={'tl-button ' + (enabled ? '' : 'tl-button-disabled')} onClick={onClick}>
                    <BackwardIcon fill={getButtonColor(enabled)} />
                </div>
            )
        case 'previousSlot':
            return(
                <div className={'tl-button ' + (enabled ? '' : 'tl-button-disabled')} onClick={onClick}>
                    <PreviousSlotIcon fill={getButtonColor(enabled)} />
                </div>
            )
        case 'nextSlot':
            return(
                <div className={'tl-button ' + (enabled ? '' : 'tl-button-disabled')} onClick={onClick}>
                    <NextSlotIcon fill={getButtonColor(enabled)} />
                </div>
            )
        case 'live':
            return(
                <div className='tl-live-button-container'>
                    <div className={'tl-live-button' + (isSystemLive ? '' : enabled ? ' tl-live-button-disabled' : ' tl-live-button-deactivated')}  onClick={onClick} >
                        {t('component__live_button__label')}
                    </div>
                </div>
            )
    }
}

export type { TimelineButtonType }
export { TimelineButton }