import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import eventParametersList from './reducers/eventParametersList.slice'
import cancelledEvents from './reducers/cancelledEvents.slice'
import currentEventParameters from './reducers/currentEventParameters.slice'
import isLoading from './reducers/isLoading.slice'
import isSystemLive from './reducers/isSystemLive.slice'
import queryInterval from './reducers/queryInterval.slice'
import reducedEvents from './reducers/reducedEvents.slice'
import reducedEventsList from './reducers/reducedEventsList.slice'
import requestError from './reducers/requestError.slice'
import nowTime from './reducers/nowTime.slice'
import filters from './reducers/filters.slice'
import timepicker from './reducers/timepicker.slice'

/**
 * Here we will be adding reducers
 * */
const reducer = combineReducers({
    eventParametersList,
    cancelledEvents,
    currentEventParameters,
    isLoading,
    isSystemLive,
    queryInterval,
    reducedEvents,
    reducedEventsList,
    requestError,
    nowTime,
    filters,
    timepicker
})

/**
 * configureStore accepts a single object rather that multiple function arguments. 
 * It's because under the hood, the store has been configured to allow using the Redux DevTools Extension and has had some Redux middleware included by default.
 */
export const store = configureStore({
    reducer: reducer,
    devTools: process.env.NODE_ENV !== 'production',
  })

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch