import './Events.css'
import {useAppSelector} from "../../hooks/reduxCustomHooks";
import {EventsNavbar} from "../../components/EventsNavbar/EventsNavbar";
import {FiltersBox} from "../../components/FiltersBox/FiltersBox";
import {useEffect, useState} from "react";
import {ApiClient} from "../../services/ApiClient";
import log from "loglevel";
import {AxiosResponse} from "axios";
import {FilteredRecentEvents} from "../../services/network";
import {Pagination, PaginationFooter} from "../../components/PaginationFooter/PaginationFooter";
import {
    CANCELLED_TAG,
    COORDINATES_TAG,
    DATETIME_TAG, DEPTH_TAG,
    EarthquakeTableEvent,
    ID_TAG, MB_TAG, MWP_TAG, MWPD_TAG,
    VERSION_TAG,
    ZONE_TAG
} from "../../components/CustomTable/TableUtils";
import {buildEarthquakeTable} from "../../components/CustomTable/TableComposer";
import {getEndtimeIfNotInFuture} from "./utils";

const Events = function() {

    const filters = useAppSelector((state) => state.filters)
    const [eventsResponse, setEventsResponse] = useState<FilteredRecentEvents|undefined>()
    const [events, setEvents] = useState<Array<EarthquakeTableEvent>>([])
    const [pagination, setPagination] = useState<Pagination>({
        total: 0,
        last_page: 1,
        from: 0,
        to: 0
    })

    const _getEvents = async function() {
        try {
            let response: AxiosResponse<FilteredRecentEvents> = await ApiClient.recentEventsApi().getRecentEventsFiltered(
                filters.starttime, // startdate?: string
                getEndtimeIfNotInFuture(filters.endtime), // enddate?: string
                filters.lat ? Number(filters.lat) : undefined, // lat?: number
                filters.lon ? Number(filters.lon) : undefined, // lon?: number
                filters.minradiuskm ? Number(filters.minradiuskm) : undefined, // minradiuskm?: number
                filters.maxradiuskm ? Number(filters.maxradiuskm) : undefined, // maxradiuskm?: number
                filters.minlat ? Number(filters.minlat) : undefined, // minlat?: number
                filters.minlon ? Number(filters.minlon) : undefined, // minlon?: number
                filters.maxlat ? Number(filters.maxlat) : undefined, // maxlat?: number
                filters.maxlon ? Number(filters.maxlon) : undefined, // maxlon?: number
                filters.minmag ? Number(filters.minmag) : undefined, // minmag?: number
                filters.maxmag ? Number(filters.maxmag) : undefined, // maxmag?: number
                filters.mindepth ? Number(filters.mindepth) * 1000 : undefined, // mindepth?: number
                filters.maxdepth ? Number(filters.maxdepth) * 1000 : undefined, // maxdepth?: number
                filters.orderby, // orderby?: Orderings,
                filters.limit, // limit?: number
                filters.page // page?: number
            )

            setEventsResponse(response?.data)
        } catch (e) {
            console.log('Events => _getEvents => error =>', e)
        }
    }

    useEffect(() => {
        _getEvents().then(() => log.debug('sent'))
    }, [filters])

    useEffect(() => {
        if(!eventsResponse) return

        const depth = function (depth?: number): string {
            if(!depth) return '--';
            return `${(depth / 1000).toFixed(2)}`
        }

        let _remoteEvents: Array<EarthquakeTableEvent> = eventsResponse.data.map((e) => {
            return {
                [ID_TAG]: `${e?.eventId ?? 0}`,
                [DATETIME_TAG]: e?.originTime ?? '--',
                [VERSION_TAG]: `${e?.seqNum ?? '--'}`,
                [ZONE_TAG]: e?.region ?? '--',
                [COORDINATES_TAG]: `${e.lat}, ${e.lon}`,
                [DEPTH_TAG]: depth(e.depth),
                [MB_TAG]: `${e?.mb ?? '--'}`,
                [MWP_TAG]: `${e.mwp ?? '--'}`,
                [MWPD_TAG]: `${e.mwpd ?? '--'}`,
                [CANCELLED_TAG]: e.cancelled ?? false,
            }
        })
        setEvents(_remoteEvents)
    }, [eventsResponse])

    useEffect(() => {
        if(!eventsResponse) return;

        let {meta} = eventsResponse
        let {from, to, total, lastPage} = meta
        setPagination({
            from: from ?? 0,
            to: to ?? 0,
            total: total ?? 0,
            last_page: lastPage ?? 1
        })
    }, [eventsResponse])

    return(<>
        <div className={'events-container'}>
            <div className={'events-content'}>
                <EventsNavbar />
                <div className={'events-table-container'}>
                    <FiltersBox />
                    <div style={{maxWidth: '100%', overflow: 'auto'}}>
                        {
                            eventsResponse
                                ? (
                                    events.length === 0
                                        ? 'No data'
                                        : buildEarthquakeTable(events)
                                )
                                : 'Request in progress...'
                        }
                    </div>
                </div>
            </div>
            <div className={'events-pagination-container'}>
                <PaginationFooter pagination={pagination} />
            </div>
        </div>
    </>)
}

export {
    Events
}