import axios, { AxiosInstance, AxiosResponse } from 'axios'

type numberOrNone = number | 'None' | 'None'

interface TsunamigenicEvaluatorRequest {
    event_id: string,
    origin_id: string,
    lat: numberOrNone,
    lon: numberOrNone,
    depth: numberOrNone,
    minHorUnc: numberOrNone,
    maxHorUnc: numberOrNone,
    azMaxHorUnc: numberOrNone,
    mag: numberOrNone,
    nr_mag: numberOrNone,
    T50Ex: numberOrNone,
    T50Ex_nr: numberOrNone,
    Td: numberOrNone,
    Td_nr: numberOrNone,
    T0: numberOrNone,
    T0_nr: numberOrNone
}

interface TsunamigenicEvaluatorResponse {
    error?: any,
    detail?: any,
    data: {
        origin_id: string
        event_id: string
        latitude: numberOrNone
        longitude: numberOrNone
        depth: numberOrNone
        Discriminants: {
            T50Ex: numberOrNone
            T50Ex_nr: numberOrNone
            TdT50Ex: numberOrNone
            TdT50Ex_nr: numberOrNone
            Td: numberOrNone
            Td_nr: numberOrNone
            T0: numberOrNone
            T0_nr: numberOrNone
            Subduction_geometry: {
                zone: string
                Slab_depth_at_epicenter: string
            }
            Closest_plate_boundaries: [number, string, string, string][]
        }
        Decision_table: {
            event_type: string[]
            Compatible_close_plate_boundary_text: string[]
            Compatible_close_plate_boundary_color: string[]
            prob_underwater_text: string[]
            prob_underwater_color: string[]
            TdT50Ex_color: string[]
            TdT50Ex_value: string[]
            tdt0_value: string[]
            tdt0_color: string[]
            Mwp_magnitude_text: string[]
            Mwp_magnitude_color: string[]
            Hypocenter_depth_text: string
            Hypocenter_depth_color: string[]
            slab_depth_text: string[]
            slab_depth_color: string[]
        }
    }
}

const TSUNAMIGENIC_EVALUATOR_URL = process.env.REACT_APP_TSUNAMIGENIC_EVALUATOR_URL ?? 'http://localhost:8084/api/evaluate'

const networkClient: AxiosInstance = axios.create({
    baseURL: TSUNAMIGENIC_EVALUATOR_URL,
    headers: {
      "Content-Type": "application/json",
    },
});

const getTsunamigenicEvaluatorData = (request: TsunamigenicEvaluatorRequest): Promise<AxiosResponse<TsunamigenicEvaluatorResponse>> => {
    return networkClient.post<TsunamigenicEvaluatorResponse>(TSUNAMIGENIC_EVALUATOR_URL, request);
}

const TsunamigenicEvaluatorClient = {
    getTsunamigenicEvaluatorData
}

export type { TsunamigenicEvaluatorResponse }
export { TsunamigenicEvaluatorClient }