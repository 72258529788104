import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

interface QueryInterval {
    startDate: string,
    endDate: string
}

dayjs.extend(utc)
const nowDate = dayjs.utc()

const initialStateQueryInterval: QueryInterval = {
    startDate: nowDate.subtract(60, 'minutes').second(0).toISOString(),
    endDate: nowDate.second(59).toISOString()
}

export const queryIntervalSlice = createSlice({
  name: 'queryInterval',
  initialState: initialStateQueryInterval,
  reducers: {
    resetqueryInterval: (state) => {
      return initialStateQueryInterval
    },
    setqueryInterval: (state, action: PayloadAction<QueryInterval>) => {
      return action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setqueryInterval, resetqueryInterval } = queryIntervalSlice.actions

export const getqueryInterval = (state: RootState) => state.queryInterval

export default queryIntervalSlice.reducer