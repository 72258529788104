export const stationIconUnassociatedNotLive = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9635 20.4453C23.4066 21.1099 22.9302 22 22.1315 22L16 22L9.86852 22C9.06982 22 8.59343 21.1099 9.03647 20.4453L15.1679 11.2481C15.5638 10.6543 16.4362 10.6543 16.832 11.2481L22.9635 20.4453Z" fill="#8D8D8D"/>
<g style="mix-blend-mode:darken" opacity="0.4">
<path d="M22.5475 20.7227C22.769 21.0549 22.5308 21.5 22.1315 21.5L9.86852 21.5C9.46917 21.5 9.23097 21.0549 9.45249 20.7227L15.584 11.5254C15.7819 11.2286 16.2181 11.2286 16.416 11.5254L22.5475 20.7227Z" stroke="#393939"/>
</g>
</svg>`

export const stationIconUnassociatedLatencyGt1h = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9635 20.4453C23.4066 21.1099 22.9302 22 22.1315 22L16 22L9.86852 22C9.06982 22 8.59343 21.1099 9.03647 20.4453L15.1679 11.2481C15.5638 10.6543 16.4362 10.6543 16.832 11.2481L22.9635 20.4453Z" fill="#BE95FF"/>
<g style="mix-blend-mode:darken" opacity="0.4">
<path d="M22.5475 20.7227C22.769 21.0549 22.5308 21.5 22.1315 21.5L9.86852 21.5C9.46917 21.5 9.23097 21.0549 9.45249 20.7227L15.584 11.5254C15.7819 11.2286 16.2181 11.2286 16.416 11.5254L22.5475 20.7227Z" stroke="#393939"/>
</g>
</svg>`

export const stationIconUnassociatedLatencyLt1h = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9635 20.4453C23.4066 21.1099 22.9302 22 22.1315 22L16 22L9.86852 22C9.06982 22 8.59343 21.1099 9.03647 20.4453L15.1679 11.2481C15.5638 10.6543 16.4362 10.6543 16.832 11.2481L22.9635 20.4453Z" fill="#FF832B"/>
<g style="mix-blend-mode:darken" opacity="0.4">
<path d="M22.5475 20.7227C22.769 21.0549 22.5308 21.5 22.1315 21.5L9.86852 21.5C9.46917 21.5 9.23097 21.0549 9.45249 20.7227L15.584 11.5254C15.7819 11.2286 16.2181 11.2286 16.416 11.5254L22.5475 20.7227Z" stroke="#393939"/>
</g>
</svg>`

export const stationIconUnassociatedLatencyLt5m = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9635 20.4453C23.4066 21.1099 22.9302 22 22.1315 22L16 22L9.86852 22C9.06982 22 8.59343 21.1099 9.03647 20.4453L15.1679 11.2481C15.5638 10.6543 16.4362 10.6543 16.832 11.2481L22.9635 20.4453Z" fill="#FDDC69"/>
<g style="mix-blend-mode:darken" opacity="0.4">
<path d="M22.5475 20.7227C22.769 21.0549 22.5308 21.5 22.1315 21.5L9.86852 21.5C9.46917 21.5 9.23097 21.0549 9.45249 20.7227L15.584 11.5254C15.7819 11.2286 16.2181 11.2286 16.416 11.5254L22.5475 20.7227Z" stroke="#393939"/>
</g>
</svg>`

export const stationIconUnassociatedLatencyLt30s = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9635 20.4453C23.4066 21.1099 22.9302 22 22.1315 22L16 22L9.86852 22C9.06982 22 8.59343 21.1099 9.03647 20.4453L15.1679 11.2481C15.5638 10.6543 16.4362 10.6543 16.832 11.2481L22.9635 20.4453Z" fill="#6FDC8C"/>
<g style="mix-blend-mode:darken" opacity="0.4">
<path d="M22.5475 20.7227C22.769 21.0549 22.5308 21.5 22.1315 21.5L9.86852 21.5C9.46917 21.5 9.23097 21.0549 9.45249 20.7227L15.584 11.5254C15.7819 11.2286 16.2181 11.2286 16.416 11.5254L22.5475 20.7227Z" stroke="#393939"/>
</g>
</svg>`

export const stationIconAssociatedNotLive = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9635 20.4453C23.4066 21.1099 22.9302 22 22.1315 22L16 22L9.86852 22C9.06982 22 8.59343 21.1099 9.03647 20.4453L15.1679 11.2481C15.5638 10.6543 16.4362 10.6543 16.832 11.2481L22.9635 20.4453Z" fill="#8D8D8D"/>
<g style="mix-blend-mode:darken" opacity="0.4">
<path d="M22.5475 20.7227C22.769 21.0549 22.5308 21.5 22.1315 21.5L9.86852 21.5C9.46917 21.5 9.23097 21.0549 9.45249 20.7227L15.584 11.5254C15.7819 11.2286 16.2181 11.2286 16.416 11.5254L22.5475 20.7227Z" stroke="#393939"/>
</g>
<path d="M23.263 24.2C25.2597 24.2 26.4507 21.9746 25.3431 20.3132L18.0801 9.41875C17.0906 7.93442 14.9094 7.93442 13.9199 9.41876L6.65691 20.3132C5.54932 21.9746 6.7403 24.2 8.73704 24.2L23.263 24.2Z" stroke="#FA4D56" stroke-linejoin="round"/>
</svg>`

export const stationIconAssociatedLatencyGt1h = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9635 20.4453C23.4066 21.1099 22.9302 22 22.1315 22L16 22L9.86852 22C9.06982 22 8.59343 21.1099 9.03647 20.4453L15.1679 11.2481C15.5638 10.6543 16.4362 10.6543 16.832 11.2481L22.9635 20.4453Z" fill="#BE95FF"/>
<g style="mix-blend-mode:darken" opacity="0.4">
<path d="M22.5475 20.7227C22.769 21.0549 22.5308 21.5 22.1315 21.5L9.86852 21.5C9.46917 21.5 9.23097 21.0549 9.45249 20.7227L15.584 11.5254C15.7819 11.2286 16.2181 11.2286 16.416 11.5254L22.5475 20.7227Z" stroke="#393939"/>
</g>
<path d="M23.263 24.2C25.2597 24.2 26.4507 21.9746 25.3431 20.3132L18.0801 9.41875C17.0906 7.93442 14.9094 7.93442 13.9199 9.41876L6.65691 20.3132C5.54932 21.9746 6.7403 24.2 8.73704 24.2L23.263 24.2Z" stroke="#FA4D56" stroke-linejoin="round"/>
</svg>`

export const stationIconAssociatedLatencyLt1h = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9635 20.4453C23.4066 21.1099 22.9302 22 22.1315 22L16 22L9.86852 22C9.06982 22 8.59343 21.1099 9.03647 20.4453L15.1679 11.2481C15.5638 10.6543 16.4362 10.6543 16.832 11.2481L22.9635 20.4453Z" fill="#FF832B"/>
<g style="mix-blend-mode:darken" opacity="0.4">
<path d="M22.5475 20.7227C22.769 21.0549 22.5308 21.5 22.1315 21.5L9.86852 21.5C9.46917 21.5 9.23097 21.0549 9.45249 20.7227L15.584 11.5254C15.7819 11.2286 16.2181 11.2286 16.416 11.5254L22.5475 20.7227Z" stroke="#393939"/>
</g>
<path d="M23.263 24.2C25.2597 24.2 26.4507 21.9746 25.3431 20.3132L18.0801 9.41875C17.0906 7.93442 14.9094 7.93442 13.9199 9.41876L6.65691 20.3132C5.54932 21.9746 6.7403 24.2 8.73704 24.2L23.263 24.2Z" stroke="#FA4D56" stroke-linejoin="round"/>
</svg>`

export const stationIconAssociatedLatencyLt5m = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9635 20.4453C23.4066 21.1099 22.9302 22 22.1315 22L16 22L9.86852 22C9.06982 22 8.59343 21.1099 9.03647 20.4453L15.1679 11.2481C15.5638 10.6543 16.4362 10.6543 16.832 11.2481L22.9635 20.4453Z" fill="#FDDC69"/>
<g style="mix-blend-mode:darken" opacity="0.4">
<path d="M22.5475 20.7227C22.769 21.0549 22.5308 21.5 22.1315 21.5L9.86852 21.5C9.46917 21.5 9.23097 21.0549 9.45249 20.7227L15.584 11.5254C15.7819 11.2286 16.2181 11.2286 16.416 11.5254L22.5475 20.7227Z" stroke="#393939"/>
</g>
<path d="M23.263 24.2C25.2597 24.2 26.4507 21.9746 25.3431 20.3132L18.0801 9.41875C17.0906 7.93442 14.9094 7.93442 13.9199 9.41876L6.65691 20.3132C5.54932 21.9746 6.7403 24.2 8.73704 24.2L23.263 24.2Z" stroke="#FA4D56" stroke-linejoin="round"/>
</svg>`

export const stationIconAssociatedLatencyLt30s = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9635 20.4453C23.4066 21.1099 22.9302 22 22.1315 22L16 22L9.86852 22C9.06982 22 8.59343 21.1099 9.03647 20.4453L15.1679 11.2481C15.5638 10.6543 16.4362 10.6543 16.832 11.2481L22.9635 20.4453Z" fill="#6FDC8C"/>
<g style="mix-blend-mode:darken" opacity="0.4">
<path d="M22.1315 21.5L9.86852 21.5C9.46917 21.5 9.23097 21.0549 9.45249 20.7227L15.584 11.5254C15.7819 11.2286 16.2181 11.2286 16.416 11.5254L22.5475 20.7227C22.769 21.0549 22.5308 21.5 22.1315 21.5Z" stroke="#393939"/>
</g>
<path d="M8.73704 24.2L23.263 24.2C25.2597 24.2 26.4507 21.9746 25.3431 20.3132L18.0801 9.41875C17.0906 7.93442 14.9094 7.93442 13.9199 9.41876L6.65691 20.3132C5.54932 21.9746 6.7403 24.2 8.73704 24.2Z" stroke="#FA4D56" stroke-linejoin="round"/>
</svg>`

export const stationIconTd50exUnassociated = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9635 20.4453C23.4066 21.1099 22.9302 22 22.1315 22L16 22L9.86852 22C9.06982 22 8.59343 21.1099 9.03647 20.4453L15.1679 11.2481C15.5638 10.6543 16.4362 10.6543 16.832 11.2481L22.9635 20.4453Z" fill="#8D8D8D"/>
<g style="mix-blend-mode:darken" opacity="0.4">
<path d="M22.5475 20.7227C22.769 21.0549 22.5308 21.5 22.1315 21.5L9.86852 21.5C9.46917 21.5 9.23097 21.0549 9.45249 20.7227L15.584 11.5254C15.7819 11.2286 16.2181 11.2286 16.416 11.5254L22.5475 20.7227Z" stroke="#393939"/>
</g>
</svg>`

export const stationIconTd50exGte11 = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9635 20.4453C23.4066 21.1099 22.9302 22 22.1315 22L16 22L9.86852 22C9.06982 22 8.59343 21.1099 9.03647 20.4453L15.1679 11.2481C15.5638 10.6543 16.4362 10.6543 16.832 11.2481L22.9635 20.4453Z" fill="#DA1E28"/>
<g style="mix-blend-mode:darken" opacity="0.4">
<path d="M22.5475 20.7227C22.769 21.0549 22.5308 21.5 22.1315 21.5L9.86852 21.5C9.46917 21.5 9.23097 21.0549 9.45249 20.7227L15.584 11.5254C15.7819 11.2286 16.2181 11.2286 16.416 11.5254L22.5475 20.7227Z" stroke="#393939"/>
</g>
</svg>`

export const stationIconTd50exLt11 = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9635 20.4453C23.4066 21.1099 22.9302 22 22.1315 22L16 22L9.86852 22C9.06982 22 8.59343 21.1099 9.03647 20.4453L15.1679 11.2481C15.5638 10.6543 16.4362 10.6543 16.832 11.2481L22.9635 20.4453Z" fill="#FF8389"/>
<g style="mix-blend-mode:darken" opacity="0.4">
<path d="M22.5475 20.7227C22.769 21.0549 22.5308 21.5 22.1315 21.5L9.86852 21.5C9.46917 21.5 9.23097 21.0549 9.45249 20.7227L15.584 11.5254C15.7819 11.2286 16.2181 11.2286 16.416 11.5254L22.5475 20.7227Z" stroke="#393939"/>
</g>
</svg>`

export const stationIconTd50exLt09 = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.9635 20.4453C23.4066 21.1099 22.9302 22 22.1315 22L16 22L9.86852 22C9.06982 22 8.59343 21.1099 9.03647 20.4453L15.1679 11.2481C15.5638 10.6543 16.4362 10.6543 16.832 11.2481L22.9635 20.4453Z" fill="#F4F4F4"/>
<g style="mix-blend-mode:darken" opacity="0.4">
<path d="M22.5475 20.7227C22.769 21.0549 22.5308 21.5 22.1315 21.5L9.86852 21.5C9.46917 21.5 9.23097 21.0549 9.45249 20.7227L15.584 11.5254C15.7819 11.2286 16.2181 11.2286 16.416 11.5254L22.5475 20.7227Z" stroke="#393939"/>
</g>
</svg>`