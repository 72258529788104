import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxCustomHooks'
import { setFilters } from '../../redux/reducers/filters.slice'
import './PaginationFooter.css'
import { ReactComponent as DownArrowIcon } from '../../assets/down-arrow.svg'
import { ReactComponent as UpArrowIcon } from '../../assets/up-arrow.svg'
import { ReactComponent as PaginationPreviousIcon } from '../../assets/pagination-arrow-left.svg'
import { ReactComponent as PaginationNextIcon } from '../../assets/pagination-arrow-right.svg'
import {Option} from "../EeDropdown/EeDropdown";

export interface Pagination {
    total: number,
    last_page: number,
    from: number,
    to: number
}

interface PaginationFooterProps {
    pagination: Pagination
}

export const PaginationFooter = function({pagination}: PaginationFooterProps) {

    const options: Option[] = [
        {key: "10", value: "10"},
        {key: "20", value: "20"},
        {key: "30", value: "30"},
        {key: "40", value: "40"},
        {key: "50", value: "50"},
    ]

    const defaultPages: Option[] = [
        {key: "1", value: "1"},
    ]

    const filters = useAppSelector(state => state.filters)
    const dispatch = useAppDispatch()
    const {t} = useTranslation()
    const [pageCountOpen, setPageCountOpen] = useState(false)
    const [pageSelectionOpen, setPageSelectionOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(`50`) 
    const [pages, setPages] = useState<Option[]>(defaultPages)
    
    useEffect(() => {
        setSelectedOption(`${filters.limit ?? 50}`)
    }, [filters])

    useEffect(() => {
        if(pagination.last_page > 1) {
            let x: Option[] = []
            for(let i = 1; i <= pagination.last_page; i++) {
                x = [...x, { key: `${i}`, value: `${i}`}]
            }
            setPages(x)
        }
    }, [pagination])

    const openPageCountDropDown = () => {
        let pdc = document.getElementById('pd-content')
        if(pdc) {
            let {height} = pdc.getBoundingClientRect()
            pdc.style.bottom=`${32+height}px`
        }
        setPageCountOpen(!pageCountOpen)
    }

    const openPageSelectionDropDown = () => {
        let pdc = document.getElementById('ps-content')
        if(pdc) {
            let {height} = pdc.getBoundingClientRect()
            pdc.style.bottom=`${32+height}px`
        }
        setPageSelectionOpen(!pageSelectionOpen)
    }

    const selectPageCountOption = (key: string) => {
        dispatch(setFilters({
            ...filters,
            limit: Number(key),
            page: 1,
        }))
        setPageCountOpen(false)
    }

    const selectPageSelectionOption = (key: string) => {
        console.log('selectPageSelectionOption =>', key)
        dispatch(setFilters({
            ...filters,
            page: Number(key)
        }))
        setPageSelectionOpen(false)
    }

    const previous = () => {
        if(filters.page === 1)
            return      
        dispatch(setFilters({
            ...filters,
            page: filters.page - 1
        }))
    }

    const next = () => {
        if(filters.page+1 > pagination.last_page)
            return
        dispatch(setFilters({
            ...filters,
            page: filters.page + 1
        }))
    }


    return (<>
        <div className='pagination-footer'>
            <div className='pages-count-selection'>
                <span className='pagination-label'>{t('page__events__pagination__events_for_page')}</span>
                <div className='pagination-dropdown' onClick={openPageCountDropDown}>
                    <div className='pages-count'>
                        {selectedOption}                        
                    </div>
                    {
                            pageCountOpen 
                                ? <UpArrowIcon className="arrow"/> 
                                : <DownArrowIcon className="arrow"/>
                        }
                    <div id="pd-content" className={`pagination-dropdown-content ${pageCountOpen && 'show'}`}>
                        {
                            options.map((o) => <div key={o.key} onClick={() => selectPageCountOption(o.key)}>{o.value}</div>)
                        }
                    </div>
                </div>      
            </div>
            <div className='vertical-divider'></div>
            <span className='pagination-label' style={{width: '100%'}}>{t('page__events__pagination__recap', {start: pagination.from, end: pagination.to, total: pagination.total})}</span>
            <div className='vertical-divider'></div>
            <div className='pages-count-selection'>
                <div className='pagination-dropdown' onClick={openPageSelectionDropDown}>
                    <div className='pages-count'>
                        {filters.page ?? 1}                        
                    </div>
                    {
                            pageCountOpen 
                                ? <UpArrowIcon className="arrow"/> 
                                : <DownArrowIcon className="arrow"/>
                        }
                    <div id="ps-content" className={`pagination-dropdown-content ${pageSelectionOpen && 'show'}`}>
                        {
                            pages.map((o) => <div key={o.key} onClick={() => selectPageSelectionOption(o.key)}>{o.value}</div>)
                        }
                    </div>
                </div> 
                <div className='ml-8 mr-16 pagination-label'>
                    di {pagination.last_page} pagine
                </div>
                <div className="page-swipe-button" onClick={previous}>
                    <PaginationPreviousIcon />
                </div>
                <div className='vertical-divider-8'></div>
                <div className="page-swipe-button mr-16" onClick={next}>
                    <PaginationNextIcon />
                </div>
            </div>
        </div>
    </>)
}