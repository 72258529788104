import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/reduxCustomHooks'
import { ReactComponent as PopupCloseIcon } from '../../assets/popup-close-icon.svg'
import './DifferenceTime.css'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { EventParameters } from '../../services/network'
import { DATE_FORMAT_DEFAULT_SKELETON } from '../../utils'

function DifferenceTime() {
    const nowTime = useAppSelector((state) => state.nowTime)
    const isLoading = useAppSelector((state) => state.isLoading)
    const isSystemLive = useAppSelector((state) => state.isSystemLive)
    const currentEventParameters = useAppSelector((state) => state.currentEventParameters)
    const defaultDifference = '--:--:--'
    const [diff, setDiff] = useState<number>(0)
    const [diffStr, setDiffStr] = useState<string>(defaultDifference)
    const defaultSpanClass = 'difference-time-component-value'
    const [spanClass, setSpanClass] = useState<string>(defaultSpanClass)
    const [showWarning, setShowWarning] = useState<boolean>(true)
    const [showAlert, setShowAlert] = useState<boolean>(true)
    const {t, i18n} = useTranslation()

    dayjs.extend(utc)
    dayjs.locale(i18n.language)

    const DIFFERENCE_TIME_THRESHOLD_SECONDS_WARNING = process.env.REACT_APP_DIFFERENCE_TIME_THRESHOLD_SECONDS_WARNING ?? '120'
    const DIFFERENCE_TIME_THRESHOLD_SECONDS_ALERT = process.env.REACT_APP_DIFFERENCE_TIME_THRESHOLD_SECONDS_ALERT ?? '300'

    const resetState = (resetSpanClass: boolean, resetDiff: boolean) => {
        setShowAlert(true)
        setShowWarning(true)
        if (resetSpanClass) setSpanClass(defaultSpanClass)
        if (resetDiff) {
            setDiff(0)
            setDiffStr(defaultDifference)
        }
    }

    const formatTimeDifference = (diff: number): string => {
        // Format the time difference
        if (diff < 60) {
            return `00:00:${diff < 10 ? '0' + diff : diff}`
        } else if (diff < 3600) {
            const minutes = Math.floor(diff/60)
            const minutesStr = minutes < 10 ? `${'0' + minutes}` : `${minutes}`
            const seconds = diff%60
            const secondsStr = seconds < 10 ? `${'0' + seconds}` : `${seconds}`
            return `00:${minutesStr}:${secondsStr}`
        } else {
            const hours = Math.floor(diff/3600)
            const hoursStr = hours < 10 ? `${'0' + hours}` : `${hours}`
            const minutes = Math.floor((diff%3600)/60)
            const minutesStr = minutes < 10 ? `${'0' + minutes}` : `${minutes}`
            const seconds = (diff%3600)%60
            const secondsStr = seconds < 10 ? `${'0' + seconds}` : `${seconds}`
            return `${hoursStr}:${minutesStr}:${secondsStr}`
        }
    }

    const evaluateSpanClass = (diff: number): string => {
        if (diff < +DIFFERENCE_TIME_THRESHOLD_SECONDS_WARNING) {
            resetState(false, false)
            return defaultSpanClass
        } else if ( diff < +DIFFERENCE_TIME_THRESHOLD_SECONDS_ALERT) {
            return defaultSpanClass + ' difference-time-component-value-warning'
        } else {
            return defaultSpanClass + ' difference-time-component-value-alert'
        }
    }

    const evaluateDifference = (isLoading: boolean, isSystemLive: boolean, nowTime: string, currentEventParameters: EventParameters) => {
        if (isLoading || !isSystemLive) {
            resetState(true, true)
        } else {
            const dateStr = currentEventParameters.creationInfo?.creationTime
            if (dateStr && nowTime && nowTime !== '') {
                const diff = dayjs.utc(nowTime).diff(dayjs.utc(dateStr), 'second')
                const diffStr = formatTimeDifference(diff)
                const spanClass = evaluateSpanClass(diff)
                setDiff(diff)
                setDiffStr(diffStr)
                setSpanClass(spanClass)
            } else {
                resetState(true, true)
            }
        }
    }

    useEffect(() => {
        evaluateDifference(isLoading, isSystemLive, nowTime, currentEventParameters)
    }, [currentEventParameters, nowTime, isSystemLive, isLoading])

    return (
        <>
        {showWarning && (diff >= +DIFFERENCE_TIME_THRESHOLD_SECONDS_WARNING && diff < +DIFFERENCE_TIME_THRESHOLD_SECONDS_ALERT) && 
        <div className='difference-time-popup difference-time-popup-warning'>
            <span className='difference-time-popup-header difference-time-popup-header-title'>{t('component__difference_time__popup_warning_title')}</span>
            <span className='difference-time-popup-header difference-time-popup-header-value' onClick={() => setShowWarning(false)}><PopupCloseIcon  style={{cursor: 'pointer'}} /></span>
            <span className='difference-time-popup-body difference-time-popup-body-value'>{t('component__difference_time__popup_warning_body')}</span>
            <span className='difference-time-popup-footer difference-time-popup-footer-value'>{DATE_FORMAT_DEFAULT_SKELETON}</span>
        </div>
        }
        {showAlert && (diff >= +DIFFERENCE_TIME_THRESHOLD_SECONDS_ALERT) && 
        <div className='difference-time-popup difference-time-popup-alert'>
            <span className='difference-time-popup-header difference-time-popup-header-title'>{t('component__difference_time__popup_alert_title')}</span>
            <span className='difference-time-popup-header difference-time-popup-header-value' onClick={() => setShowAlert(false)}><PopupCloseIcon  style={{cursor: 'pointer'}} /></span>
            <span className='difference-time-popup-body difference-time-popup-body-value'>{t('component__difference_time__popup_alert_body')}</span>
            <span className='difference-time-popup-footer difference-time-popup-footer-value'>{DATE_FORMAT_DEFAULT_SKELETON}</span>
        </div>
        }
        <span className='difference-time-component'>
            <span className='difference-time-component-pre'>{t('component__difference_time__pre')}</span><span className={spanClass}>{diffStr}</span>
        </span>
        </>
    )
}

export { DifferenceTime }