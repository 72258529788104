import { useState, MouseEvent, MouseEventHandler } from "react"
import { useTranslation } from "react-i18next"
import { ReducedEventParameters } from "../../utils/eventReducedListCreator"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { DATE_FORMAT_DEFAULT } from "../../utils"

interface TimelineSlotProps {
    reducedEvents: ReducedEventParameters,
    eventId?: string,
    isCurrent?: boolean,
    onClick: MouseEventHandler
}

function TimelineSlot({reducedEvents, eventId, isCurrent, onClick}: TimelineSlotProps) {
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
    const [tooltipPosition, setTooltipPosition] = useState<number[]>([0,0])
    const { t, i18n } = useTranslation()

    dayjs.extend(utc)
    dayjs.locale(i18n.language)

    const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
        setTooltipPosition([e.clientX, e.clientY])
    }

    const handleMouseLeave = (e: MouseEvent<HTMLDivElement>) => {
        setTooltipVisible(false)
    }

    const handleMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
        setTooltipPosition([e.clientX, e.clientY])
        setTooltipVisible(true)
    }

    return(
        <div className={`tl-slot${isCurrent ? ' tl-slot-current' : ''}`}
             onClick={onClick}
             onMouseLeave={handleMouseLeave} 
             onMouseMove={handleMouseMove}
             onMouseEnter={handleMouseEnter}
             style={{gridColumn: `${reducedEvents.columnStart + 1} / span ${reducedEvents.columnSpan}`}}>
                
            {reducedEvents.events.map((event) => {
                let opacity
                if (eventId) {
                    opacity = event.eventId.split('/').pop() === eventId ? '1' : '0.2'
                } else {
                    opacity = '1'
                }

                return (
                    <div key={event.id} className='tl-slot-event-line' style={{gridRow: `${(event.positionIndex * 2) + 1} / span 1`, background: `${event.color}`, opacity: opacity}}></div>
                )
            })}

            {tooltipVisible && 
            <div className='tooltip-container' style={{top: `${tooltipPosition[1]-20}px`, left: `${tooltipPosition[0]-630}px`}}>
                    <span className='tooltip-fragment-prefix'>{t('component__timeslot_container__slot_creation_time')}</span>
                    <span className='tooltip-fragment-value'>{dayjs.utc(reducedEvents.creationTime).format(DATE_FORMAT_DEFAULT)}</span>
                    <span className='tooltip-fragment-prefix'>{t('component__timeslot_container__slot_duration')}</span>
                    <span className='tooltip-fragment-value'>{reducedEvents.duration}</span>
                    <span className='tooltip-fragment-prefix'>{t('component__timeslot_container__slot_version')}</span>
                    <span className='tooltip-fragment-value'>{reducedEvents.version}</span>
            </div>
            }
        </div>
    )
}

export { TimelineSlot }