import CustomTable from "./CustomTable"
import {
    EarthquakeTableEvent,
    earthquake_headers,
} from "./TableUtils"

export const EARTHQUAKE_LIST_TABLE = "EARTHQUAKE_LIST_TABLE"

export type GenericEvent = EarthquakeTableEvent

export const buildEarthquakeTable = (body: Array<EarthquakeTableEvent>) => {
    return <CustomTable
        type={EARTHQUAKE_LIST_TABLE}
        headers={earthquake_headers}
        className={['eqlist']}
        body={body}
    />
}