import { createRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PickDetailFragment } from '../../components/PickDetailFragment/PickDetailFragment'
import { Timeline } from '../../components/Timeline/Timeline'
import { useAppSelector } from '../../hooks/reduxCustomHooks'
import { EventParametersEventInner, EventParametersEventInnerOriginArrivalInner, EventParametersEventInnerPickInner, Station, StationReduced } from '../../services/network'
import './PickDetail.css'
import 'leaflet/dist/leaflet.css'
import Leaflet, { LatLngTuple, Map as LeafletMap } from 'leaflet'
import { Circle, MapContainer, Marker, GeoJSON, TileLayer, ZoomControl } from "react-leaflet"
import { ApiClient } from '../../services/ApiClient'
import log from 'loglevel'
import { StationMarker } from '../../components/EarlyestMap/StationMarker'
import { ReducedEvent, stationToStationReduced } from '../../utils/eventReducedListCreator'
import { EventPopup } from '../../components/EarlyestMap/EventPopup'

interface PickDetailProps {
    eventId: string,
    pickId: string
}

function PickDetail() {
    const { eventId, pickId } = useParams<keyof PickDetailProps>() as PickDetailProps
    const currentEventParameters = useAppSelector((state) => state.currentEventParameters)
    const reducedEvents = useAppSelector((state) => state.reducedEvents)
    const [event, setEvent] = useState<EventParametersEventInner>()
    const [eventReduced, setEventReduced] = useState<ReducedEvent>()
    const [arrival, setArrival] = useState<EventParametersEventInnerOriginArrivalInner>()
    const [pick, setPick] = useState<EventParametersEventInnerPickInner>()
    const [stationReducedData, setStationReducedData] = useState<StationReduced>()
    const map = createRef<LeafletMap>()

    const getStationData = async (net: string, sta: string, loc: string) => {
        try {
            const res = await ApiClient.stationsApi().getStationReduced(net, sta, loc)
            setStationReducedData(res.data)
        } catch (e) {
            log.debug('EarlyestMap => Request of stations data failed => ', e)
        }
    }

    const updateMapBound = (staLat: string, staLon: string, eventLat: string, eventLon: string) => {
        const arrayBounds: LatLngTuple[] = [[+staLat, +staLon], [+eventLat, +eventLon]]
        map.current?.flyToBounds(new Leaflet.LatLngBounds(arrayBounds), {maxZoom: 5})
    }

    useEffect(() => {
        updateMapBound(stationReducedData?.lat ?? '33', stationReducedData?.lon ?? '-10', eventReduced?.lat ?? '50', eventReduced?.lon ?? '38')
    }, [stationReducedData, eventReduced])

    useEffect(() => {
        // Set the single event in the state
        const currentEvent = currentEventParameters.event?.filter((event) => event.publicID?.split('/').pop() === eventId)[0]
        const arrival = currentEvent?.origin?.arrival?.find(arr => arr.pickID?.split('/').pop() === pickId)
        const pick = currentEvent?.pick?.find(pick => pick.publicID?.split('/').pop() === pickId)
        setEvent(currentEvent)
        setEventReduced(reducedEvents.events.find(redEv => redEv.eventId.split('/').pop() === eventId))
        setArrival(arrival)
        setPick(pick)
        getStationData(pick?.waveformID?.networkCode ?? '--', pick?.waveformID?.stationCode ?? '--', pick?.waveformID?.locationCode?.replace(/^(\d)$/, '0$&') ?? '--')
    }, [currentEventParameters, reducedEvents])

    const closePopup = () => map.current?.closePopup()

    return (
        <div className='pick-detail-container'>
            <PickDetailFragment event={event} pick={pick} arrival={arrival} />
            <MapContainer 
                className='pick-detail-map'
                zoomControl={false}
                scrollWheelZoom={true}
                zoom={7}
                // minZoom={3}
                // maxZoom={15}
                center={[+(event?.origin?.latitude ?? '0'), +(event?.origin?.longitude ?? '0')]}
                attributionControl={false /* Follow your coscience here... :) */} 
                style={{height: 'calc(100vh - 56px - 56px)', width: 'calc(100vw - 56px - 24px - 776px)'}}
                ref={map}>
                <ZoomControl position='bottomright'/>
                <TileLayer
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/Tile/{z}/{y}/{x}"
                    attribution='&copy; <a href="https://www.arcgis.com/index.html">ArcGIS Online</a> contributors'
                />
                <StationMarker
                    key={`${stationReducedData?.net ?? '--'}.${stationReducedData?.sta ?? '--'}.${stationReducedData?.loc ?? '--'}`}
                    station={stationReducedData ?? {}}
                    isStationAssociated={true}
                    closePopup={closePopup}
                    isSystemLive={false}
                    isEventDetail={true}
                    eventDetail={eventReduced} />
                {eventReduced &&
                <Marker
                    position={[+eventReduced.lat, +eventReduced.lon]}
                    riseOnHover={true}
                    icon={ 
                        new Leaflet.DivIcon({
                        html: `<div class='pick-dot-marker' style='background-color: ${eventReduced?.color}'></div>`,
                        className: 'no-show',
                        iconSize: [12, 12],
                        iconAnchor: [6, 6],
                        popupAnchor: [0, -16]
                    })} >
                        <EventPopup event={eventReduced} closePopup={closePopup} />
                </Marker>
                }
            </MapContainer>
            <Timeline eventId={eventId} className='timeline-pick-detail' />
        </div>
    )
}

export { PickDetail }