import { useNavigate, useParams } from 'react-router-dom'
import './StationDetail.css'
import { IconButton } from '../../components/IconButton/IconButton'
import { STATIONS_PATH } from '../../routes/routes'
import { ReactComponent as IconBack } from '../../assets/back-icon.svg'
import 'leaflet/dist/leaflet.css'
import { LatLngTuple, Map as LeafletMap } from 'leaflet'
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet'
import { StationMarker } from '../../components/EarlyestMap/StationMarker'
import { createRef, useEffect, useState } from 'react'
import { Station } from '../../services/network'
import { ApiClient } from '../../services/ApiClient'
import log from 'loglevel'
import { StationTable } from '../../components/StationTable/StationTable'
import { useTranslation } from 'react-i18next'

interface StationDetailProps {
    stationId: string
}

function StationDetail() {
    const {t} = useTranslation()
    const { stationId } = useParams<keyof StationDetailProps>() as StationDetailProps
    const navigate = useNavigate()
    const [stationData, setStationData] = useState<Station>()
    const [requestError, setRequestError] = useState<string>()
    const map = createRef<LeafletMap>()

    
    const closePopup = () => map.current?.closePopup()
    
    const getStationData = async (stationId: string) => {
        // Download info about the station and check whether or not the station exists
        const [net, sta, loc] = stationId.split('.')

        try {
            const res = await ApiClient.stationsApi().getStation(net, sta, loc)
            setStationData(res.data)
        } catch (e: any) {
            log.debug('StationDetail => Request of station data failed => ', e)
            setRequestError(e.message)
        }
    }
    
    useEffect(() => {
        // TODO: Add loading indicator
        getStationData(stationId)
    }, [stationId])

    // TODO: Add error indication
    return (
        <div className='station-detail-container'>
            <div className='breadcrumb-container-station-detail'>
                <IconButton icon={() => <IconBack/>} onClick={() => navigate(STATIONS_PATH, {replace: false})}/>
                <div className='breadcrumb-container-station-detail-internal'>
                    <span className='breadcrumb-item-stations'>{t('page__station_detail__stations')}</span>
                    <span className='breadcrumb-item-separator'>/</span>
                    <span className='breadcrumb-item-station-name'>{stationId}</span>
                </div>
            </div>
            {stationData && <>
            <MapContainer 
                className='station-detail-map'
                zoomControl={false}
                scrollWheelZoom={true}
                zoom={4}
                // minZoom={2}
                // maxZoom={15}
                center={[+(stationData.lat ?? '0'), +(stationData.lon ?? '0')]}
                attributionControl={false /* Follow your coscience here... :) */} 
                style={{height: '338px', width: 'calc((100vw - 56px -24px -24px -24px) / 2)'}}
                ref={map}>
                <ZoomControl position='bottomright'/>
                <TileLayer
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/Tile/{z}/{y}/{x}"
                    attribution='&copy; <a href="https://www.arcgis.com/index.html">ArcGIS Online</a> contributors'
                />
                {/* This is probably useless (or worse, harmful), but if needed can easily be added back
                <TileLayer
                    url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-labels/{z}/{x}/{y}{r}.png"
                /> */}
                <StationMarker
                    key={stationId}
                    station={stationData}
                    isStationAssociated={false}
                    closePopup={closePopup}
                    isSystemLive={true}
                    isEventDetail={false} />
            </MapContainer>
            <div className='station-info'>
                <div className='station-info-title'>{t('page__station_detail__station')}</div>
                <div className='station-info-container'>
                    <div className='station-info-dr-title'>{t('page__station_detail__network')}</div>
                    <div className='station-info-dr-title'>{t('page__station_detail__station')}</div>
                    <div className='station-info-dr-title'>{t('page__station_detail__location')}</div>
                    <div className='station-info-dr-title'>{t('page__station_detail__channels')}</div>
                    <div className='station-info-dr-value'>{stationData.net ?? '--'}</div>
                    <div className='station-info-dr-value'>{stationData.sta ?? '--'}</div>
                    <div className='station-info-dr-value'>{stationData.loc ?? '--'}</div>
                    <div className='station-info-dr-value'>{stationData.chans?.map(chan => chan.name ?? '--').join(', ')}</div>
                </div>
                <div className='station-info-title'>{t('page__station_detail__vertical_position')}</div>
                <div className='station-info-container'>
                    <div className='station-info-dr-title'>{t('page__station_detail__latitude')}</div>
                    <div className='station-info-dr-title'>{t('page__station_detail__longitude')}</div>
                    <div className='station-info-dr-title'>{t('page__station_detail__elevation')}</div>
                    <div className='station-info-dr-title'></div>
                    <div className='station-info-dr-value'>{stationData.lat ?? '--'}</div>
                    <div className='station-info-dr-value'>{stationData.lon ?? '--'}</div>
                    <div className='station-info-dr-value'>{stationData.elev ?? '--'}</div>
                    <div className='station-info-dr-value'></div>
                </div>
            </div>
            <div className='station-channels'>
                <div className='station-info-title'>{t('page__station_detail__channels')}</div>
                <StationTable station={stationData} />
            </div>
            </>}
        </div>
    )
}

export { StationDetail }