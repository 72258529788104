import './StationPopup.css'
import { Popup } from "react-leaflet"
import { ReactComponent as PopupCloseIcon } from '../../assets/popup-close-icon.svg'
import { useTranslation } from 'react-i18next'
import { MouseEventHandler, useEffect, useState } from 'react'
import { StationReduced } from "../../services/network"
import { EventMinimum } from "./EarlyestMap"
import { Link } from 'react-router-dom'
import { EVENT_PATH, STATION_PATH } from '../../routes/routes'
import { EventStation } from '../../utils/eventReducedListCreator'

interface StationPopupProps {
    station: StationReduced,
    closePopup: MouseEventHandler<HTMLSpanElement>,
    isStationAssociated?: boolean,
    isEventDetail?: boolean,
    eventStationDetail?: EventStation,
    eventsForStation?: EventMinimum[]
}

function StationPopup({station, closePopup, isStationAssociated, isEventDetail, eventStationDetail, eventsForStation}: StationPopupProps) {
    const {t} = useTranslation()
    const [stationKey, setStationKey] = useState<string>('')

    const formatLatency = (latencyRaw: number) => {
        if (latencyRaw < 60) {
            return `${latencyRaw}s`
        } else if (latencyRaw < 3600) {
            return `${Math.floor(latencyRaw/60)}m ${latencyRaw%60}s`
        } else if (latencyRaw < 86400) {
            return `${Math.floor(latencyRaw/3600)}h ${Math.floor((latencyRaw%3600)/60)}m ${(latencyRaw%3600)%60}s`
        } else {
            return `${Math.floor(latencyRaw/86400)}d ${Math.floor((latencyRaw%86400)/3600)}h ${Math.floor(((latencyRaw%86400)%3600)/60)}m ${((latencyRaw%86400)%3600)%60}s`
        }
    }

    useEffect(() => {
        setStationKey(`${station.net}.${station.sta}.${station.loc}`)
    }, [])

    return (
        <Popup className='station-popup'>
            <div className='station-popup-row station-popup-header'>
                <span className='station-popup-title'>{stationKey}</span>
                <span className='station-popup-value' onClick={closePopup}><PopupCloseIcon style={{cursor: 'pointer'}} /></span>
            </div>
            {isStationAssociated && !isEventDetail && eventsForStation?.map((event) =>
            <Link reloadDocument to={EVENT_PATH.replace(':eventId', event.eventId.split('/').pop() ?? '')}>
                <div className='station-popup-row station-popup-event' key={event.eventId}>
                    <span className='station-popup-dot' style={{backgroundColor: event.eventColor}}></span>
                    <span className='station-popup-key'>{event.eventTitle}</span>
                </div>
            </Link>
            )}
            {isStationAssociated && isEventDetail &&
            <>
            <div className='station-popup-row station-popup-detail'>
                <span className='station-popup-key'>{t('component__popup_station__arrival_time')}</span>
                <span className='station-popup-value'>{eventStationDetail?.arrivalTime ?? '--'}</span>
            </div>
            <div className='station-popup-row station-popup-detail'>
                <span className='station-popup-key'>{t('component__popup_station__mb')}</span>
                <span className='station-popup-value'>{eventStationDetail?.mb ?? '--'}</span>
            </div>
            <div className='station-popup-row station-popup-detail'>
                <span className='station-popup-key'>{t('component__popup_station__mwp')}</span>
                <span className='station-popup-value'>{eventStationDetail?.mwp ?? '--'}</span>
            </div>
            <div className='station-popup-row station-popup-detail'>
                <span className='station-popup-key'>{t('component__popup_station__mwpd')}</span>
                <span className='station-popup-value'>{eventStationDetail?.mwpd ?? '--'}</span>
            </div>
            <div className='station-popup-row station-popup-detail'>
                <span className='station-popup-key'>{t('component__popup_station__t50ex')}</span>
                <span className='station-popup-value'>{eventStationDetail?.t50ex ?? '--'}</span>
            </div>
            <div className='station-popup-row station-popup-detail'>
                <span className='station-popup-key'>{t('component__popup_station__t50')}</span>
                <span className='station-popup-value'>{eventStationDetail?.t50 ?? '--'}</span>
            </div>
            <div className='station-popup-row station-popup-detail'>
                <span className='station-popup-key'>{t('component__popup_station__td')}</span>
                <span className='station-popup-value'>{eventStationDetail?.td ?? '--'}</span>
            </div>
            <div className='station-popup-row station-popup-detail'>
                <span className='station-popup-key'>{t('component__popup_station__azimuth')}</span>
                <span className='station-popup-value'>{eventStationDetail?.azimuth ?? '--'}</span>
            </div>
            <div className='station-popup-row station-popup-detail'>
                <span className='station-popup-key'>{t('component__popup_station__distance')}</span>
                <span className='station-popup-value'>{eventStationDetail?.distance ?? '--'}</span>
            </div>
            </>
            }
            {!isStationAssociated && 
            <div className='station-popup-row station-popup-info'>
                <span className='station-popup-key'>{t('component__popup_station__latency')}</span>
                <span className='station-popup-value'>{formatLatency(+(station.latency ?? 0))}</span>
            </div>
            }
            <div className='station-popup-row station-popup-footer'>
                <Link reloadDocument to={STATION_PATH.replace(':stationId', stationKey)}>{t('component__popup_station__station_details')}</Link>
            </div>
        </Popup>
    )
}

export { StationPopup }