import { useTranslation } from 'react-i18next'
import { Accordion } from '../Accordion/Accordion'
import { EventParametersEventInner, EventParametersEventInnerOriginArrivalInner, EventParametersEventInnerPickInner } from '../../services/network'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT_SKELETON } from '../../utils';
import { Link } from 'react-router-dom';
import { EVENT_PATH } from '../../routes/routes';

interface PickDetailDataProps {
    event?: EventParametersEventInner,
    pick?: EventParametersEventInnerPickInner,
    arrival?: EventParametersEventInnerOriginArrivalInner
}

function PickDetailData({event, pick, arrival}: PickDetailDataProps) {
    const {t, i18n} = useTranslation()
    dayjs.extend(utc)
    dayjs.locale(i18n.language)

    return (
        <div className='data-list'>
            <Accordion title={t('component__pick_detail_data__event')}>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__pick_detail_data__event__id')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__event__link')}</div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-value'>{event?.publicID?.split('/').pop() ?? '--'}</div>
                    <div className='data-detail-value'><Link to={EVENT_PATH.replace(':eventId', event?.publicID?.split('/').pop() ?? '--')}>{t('component__pick_detail_data__event__link__label')}</Link></div>
                    <div className='data-detail-value'></div>
                    <div className='data-detail-value'></div>
                </div>
            </Accordion>
            <Accordion title={t('component__pick_detail_data__station')}>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__pick_detail_data__station__channel')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__station__stream')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__station__loc')}</div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-value'>{`${pick?.waveformID?.networkCode ?? '--'}_${pick?.waveformID?.stationCode ?? '--'}_${pick?.waveformID?.locationCode?.replace(/^(\d)$/, '0$&') ?? '--'}_${pick?.waveformID?.channelCode ?? '--'}`}</div>
                    <div className='data-detail-value'>{pick?.stream ?? '--'}</div>
                    <div className='data-detail-value'>{pick?.waveformID?.locationCode ?? '--'}</div>
                    <div className='data-detail-value'></div>
                </div>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__pick_detail_data__station__az')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__station__dist')}</div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-value'>{arrival?.azimuth ?? '--'}</div>
                    <div className='data-detail-value'>{arrival?.distance ?? '--'}</div>
                    <div className='data-detail-value'></div>
                    <div className='data-detail-value'></div>
                </div>
            </Accordion>
            <Accordion title={t('component__pick_detail_data__arrival_times')}>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label' style={{gridColumn: '1 / span 2'}}>{t('component__pick_detail_data__arrival_times__time')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__arrival_times__unc')}</div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-value' style={{gridColumn: '1 / span 2'}}>{pick?.time?.value ? dayjs.utc(pick.time.value).format(DATE_FORMAT_DEFAULT) : DATE_FORMAT_DEFAULT_SKELETON}</div>
                    <div className='data-detail-value'>{pick?.time?.uncertainty ?? '--'}</div>
                    <div className='data-detail-value'></div>
                </div>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__pick_detail_data__arrival_times__phase')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__arrival_times__residual')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__arrival_times__toang')}</div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-value'>{arrival?.phase ?? '--'}</div>
                    <div className='data-detail-value'>{arrival?.timeResidual ?? '--'}</div>
                    <div className='data-detail-value'>{arrival?.takeoffAngle ?? '--'}</div>
                    <div className='data-detail-value'></div>
                </div>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__pick_detail_data__arrival_times__pol')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__arrival_times__pol_type')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__arrival_times__pol_wt')}</div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-value'>{pick?.polarity ?? '--'}</div>
                    <div className='data-detail-value'>{pick?.polarityType ?? '--'}</div>
                    <div className='data-detail-value'>{pick?.polarityWeight ?? '--'}</div>
                    <div className='data-detail-value'></div>
                </div>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__pick_detail_data__arrival_times__paz')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__arrival_times__paz_unc')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__arrival_times__paz_calc')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__arrival_times__paz_wt')}</div>
                    <div className='data-detail-value'>{arrival?.polarizationAzimuth ?? '--'}</div>
                    <div className='data-detail-value'>{arrival?.polarizationAzimuthUnc ?? '--'}</div>
                    <div className='data-detail-value'>{arrival?.polarizationAzimuthCalc ?? '--'}</div>
                    <div className='data-detail-value'>{arrival?.polarizationWeight ?? '--'}</div>
                </div>
            </Accordion>
            <Accordion title={t('component__pick_detail_data__magnitude')}>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__pick_detail_data__magnitude__mb')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__magnitude__mwp')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__magnitude__mwpd')}</div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-value'>{arrival?.mb ?? '--'}</div>
                    <div className='data-detail-value'>{arrival?.Mwp ?? '--'}</div>
                    <div className='data-detail-value'>{arrival?.MwpdCorr ?? '--'}</div>
                    <div className='data-detail-value'></div>
                </div>
            </Accordion>
            <Accordion title={t('component__pick_detail_data__duration')}>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__pick_detail_data__duration__t50')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__duration__t50ex')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__duration__td')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__duration__t0')}</div>
                    <div className='data-detail-value'>{pick?.T50 ?? '--'}</div>
                    <div className='data-detail-value'>{pick?.T50Ex ?? '--'}</div>
                    <div className='data-detail-value'>{pick?.Td ?? '--'}</div>
                    <div className='data-detail-value'>{pick?.T0 ?? '--'}</div>
                </div>
            </Accordion>
            <Accordion title={t('component__pick_detail_data__amplitude')}>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__pick_detail_data__amplitude__aref')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__amplitude__aerr')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__amplitude__avel')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__amplitude__adisp')}</div>
                    <div className='data-detail-value'>{pick?.Aref ?? '--'}</div>
                    <div className='data-detail-value'>{pick?.Aerr ?? '--'}</div>
                    <div className='data-detail-value'>{pick?.Avel ?? '--'}</div>
                    <div className='data-detail-value'>{pick?.Adisp ?? '--'}</div>
                </div>
            </Accordion>
            <Accordion title={t('component__pick_detail_data__noise_level')}>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__pick_detail_data__noise_level__sn_hf')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__noise_level__sn_brbv')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__noise_levelsn_brbd')}</div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-value'>{pick?.snHF ?? '--'}</div>
                    <div className='data-detail-value'>{pick?.snBRB ?? '--'}</div>
                    <div className='data-detail-value'>{pick?.snBRBD ?? '--'}</div>
                    <div className='data-detail-value'></div>
                </div>
            </Accordion>
            <Accordion title={t('component__pick_detail_data__station_detail')}>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__pick_detail_data__station_detail__tot_wt')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__station_detail__dist_wt')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__station_detail__st_q_wt')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__station_detail__sta_corr')}</div>
                    <div className='data-detail-value'>{arrival?.totalWeight ?? '--'}</div>
                    <div className='data-detail-value'>{arrival?.distanceWeight ?? '--'}</div>
                    <div className='data-detail-value'>{arrival?.staQualityWeight ?? '--'}</div>
                    <div className='data-detail-value'>{pick?.staCorr ?? '--'}</div>
                </div>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__pick_detail_data__station_detail__status')}</div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-value'>{'--'}</div>
                    <div className='data-detail-value'></div>
                    <div className='data-detail-value'></div>
                    <div className='data-detail-value'></div>
                </div>
            </Accordion>
            <Accordion title={t('component__pick_detail_data__more_info')}>
                <div className='data-detail-four-column'>
                    <div className='data-detail-label'>{t('component__pick_detail_data__origin_id')}</div>
                    <div className='data-detail-label'>{t('component__pick_detail_data__pick_id')}</div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-label'></div>
                    <div className='data-detail-value'>{event?.origin?.publicID?.split('/').pop() ?? '--'}</div>
                    <div className='data-detail-value'>{pick?.publicID?.split('/').pop() ?? '--'}</div>
                    <div className='data-detail-value'></div>
                    <div className='data-detail-value'></div>
                </div>
            </Accordion>
        </div>
    )
}

export { PickDetailData }