import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

const initialStateIsSystemLive: boolean = true

export const isSystemLiveSlice = createSlice({
  name: 'isSystemLive',
  initialState: initialStateIsSystemLive,
  reducers: {
    resetIsSystemLive: (state) => {
      return initialStateIsSystemLive
    },
    setIsSystemLive: (state, action: PayloadAction<boolean>) => {
      return action.payload
    },
    toggleIsSystemLive: (state) => {
        return !state
    }
  },
})

// Action creators are generated for each case reducer function
export const { resetIsSystemLive, setIsSystemLive, toggleIsSystemLive } = isSystemLiveSlice.actions

export const isSystemLive = (state: RootState) => state.isSystemLive

export default isSystemLiveSlice.reducer