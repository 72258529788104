import { MouseEventHandler, ReactNode, useEffect, useMemo, useState } from 'react'
import { NumberNumberMap } from '../../utils'
import { ReactComponent as SortingIconNeutral } from './../../assets/sorting-icon-neutral.svg'
import { ReactComponent as SortingIconAsc } from './../../assets/sorting-icon-asc.svg'
import { ReactComponent as SortingIconDesc } from './../../assets/sorting-icon-desc.svg'
import './EeTable.css'
import log from 'loglevel'
import { Link } from 'react-router-dom'
import { TooltipFragment, TooltipProvider } from '../TooltipProvider/TooltipProvider'
import { useAppSelector } from '../../hooks/reduxCustomHooks'
import { Loading } from '@carbon/react'
import { useTranslation } from 'react-i18next'

type AscDesc = 'ASC' | 'DESC'

interface EeTableHeader {
    key: string,
    label: string,
    sortable: boolean,
    sorter?: (a: string | number, b: string | number) => number,
    spaceFraction?: number
}

interface EeTableDataRowCell {
    value: ReactNode,
    sortableValue: string | number
}

interface EeTableDataRow {
    cells: {
        id: string,
        [key: string]: string | number | EeTableDataRowCell
    },
    tooltipFragments?: TooltipFragment[],
    onClick?: string | MouseEventHandler<HTMLDivElement>
}

interface SortingStatus {
    sortedBy: string,
    order: AscDesc
}

interface EeTableProps {
    headers: EeTableHeader[],
    data: EeTableDataRow[],
    className?: string
}

function EeTable({headers, data, className}: EeTableProps) {
    const [sortingStatus, setSortingStatus] = useState<SortingStatus>({sortedBy: 'id', order: 'ASC'})
    const [sortedData, setSortedData] = useState<EeTableDataRow[]>([])
    const isLoading = useAppSelector((state) => state.isLoading)
    const {t} = useTranslation()
    
    useEffect(() => {
        setSortedData(data)
    }, [data])
    
    const processHeaders = (headers: EeTableHeader[]) => {
        let totalSpaceFractions: number = 0
        let fractionStartPerCell: NumberNumberMap = {}
        let keys: string[] = []
        
        // Extract info from headers
        headers.forEach((header, index) => {
            fractionStartPerCell[index] = index === 0 ? 1 : (totalSpaceFractions + 1)
            totalSpaceFractions += header.spaceFraction ?? 1
            keys.push(header.key)
        })
        
        return { totalSpaceFractions, fractionStartPerCell, keys }
    }
    
    const { totalSpaceFractions, fractionStartPerCell, keys } = useMemo(() => processHeaders(headers), [headers])

    const getCellValue = (cellData: string | number | EeTableDataRowCell): ReactNode => {
        return cellData && 
                typeof cellData !== 'number' && 
                typeof cellData !== 'string' && 
                'value' in cellData 
                ? cellData.value 
                : cellData
    }

    const getCellSortableValue = (cellData: string | number | EeTableDataRowCell): string | number => {
        return cellData && 
                typeof cellData !== 'number' && 
                typeof cellData !== 'string' && 
                'sortableValue' in cellData 
                ? cellData.sortableValue 
                : cellData
    }

    const isPrimitive = (variable: any) => {
        const varType = typeof variable
        return varType === 'string' || varType === 'number'
    }

    const getSortingIcon = (headerKey: string) => {
        if (headerKey === sortingStatus.sortedBy) {
            if (sortingStatus.order === 'ASC') {
                return <SortingIconAsc />
            } else {
                return <SortingIconDesc />
            }
        } else {
            return <SortingIconNeutral />
        }
    }

    const defaultSort = (a: string | number, b: string | number) => {
        if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b)
        } else if(typeof a === 'number' && typeof b === 'number') {
            return  a - b
        } else {
            return 0 // This is just to leave every element in its original position, since in this case we have a mixed array
        }
    }

    const sortTable = (header: EeTableHeader) => {
        log.debug(`EeTable => sortTable => Begin, header: ${header}`)
        // Evaluate the new field to sort on and the sorting order
        let sortBy: string
        let sortOrder: AscDesc
        if (sortingStatus.sortedBy === header.key) {
            if (sortingStatus.order === 'ASC') {
                sortBy = header.key
                sortOrder = 'DESC'
            } else {
                sortBy = 'id'
                sortOrder = 'ASC'
            }
        } else {
            sortBy = header.key
            sortOrder = 'ASC'
        }

        log.debug(`EeTable => sortTable => sortBy: ${sortBy} - sortOrder: ${sortOrder}`)

        if (sortBy === 'id') {
            log.debug('EeTable => sortTable => Sorting data by id, ASC')
            setSortedData(sortedData.sort((a, b) => defaultSort(a.cells.id, b.cells.id)))
        } else if (header.sorter) { // TODO: Why is this not enough to ensure (in the following lines) that the sorter function is not undefined?
            log.debug(`EeTable => sortTable => Sorting data by ${sortBy} using custom sorter, ${sortOrder}`)
            // Apply custom sorter on the selected field, keeping in mind ASC and DESC
            setSortedData(sortedData.sort((a, b) => sortOrder === 'ASC' ?
                header.sorter ? header.sorter(getCellSortableValue(a.cells[sortBy]), getCellSortableValue(b.cells[sortBy])) : 0
                :
                header.sorter ? 0 - header.sorter(getCellSortableValue(a.cells[sortBy]), getCellSortableValue(b.cells[sortBy])) : 0
            ))
        } else {
            log.debug(`EeTable => sortTable => Sorting data by ${sortBy} using default sorter, ${sortOrder}`)
            // Apply default sort on the selected field, keeping in mind ASC and DESC
            setSortedData(sortedData.sort((a, b) => sortOrder === 'ASC' ?
                defaultSort(getCellSortableValue(a.cells[sortBy]), getCellSortableValue(b.cells[sortBy]))
                :
                0 - defaultSort(getCellSortableValue(a.cells[sortBy]), getCellSortableValue(b.cells[sortBy]))
            ))
        }

        // Save sortBy and sortOrder in sortingStatus
        setSortingStatus({sortedBy: sortBy, order: sortOrder})
    }

    const generateTableHeader = () => {
        return (
            <div 
                className='ee-table-header'
                style={{gridTemplateColumns: `repeat(${totalSpaceFractions}, 1fr)`}} >
                {headers.map((header, index) =>
                    <div
                        key={`ee-table-header-cell-${header.key}`} 
                        className='ee-table-header-cell'
                        style={{gridColumn: `${fractionStartPerCell[index]} / span ${header.spaceFraction ?? 1}`}} >
                            <span 
                                className={`ee-table-header-text ${header.sortable ? 'ee-table-header-sortable' : ''}`} 
                                onClick={header.sortable ? () => sortTable(header) : undefined} >
                                    {header.label}
                            </span>
                            {header.sortable &&
                                getSortingIcon(header.key)
                            }
                    </div>
                )}
            </div>
        )
    }
    
    const generateTableRowInner = (rowData: EeTableDataRow, onClick?: MouseEventHandler<HTMLDivElement>) => {
        const rowInner = <div
                            key={`ee-table-row-${rowData.cells.id}`}
                            className='ee-table-row'
                            {...(onClick ? {onClick: onClick} :{})}
                            style={{gridTemplateColumns: `repeat(${totalSpaceFractions}, 1fr)`}} >
                                {keys.map((key, index) =>
                                    <div 
                                    key={`ee-table-row-cell-${rowData.cells.id}-${key}`}
                                    className='ee-table-row-cell'
                                    style={{gridColumn: `${fractionStartPerCell[index]} / span ${headers[index].spaceFraction ?? 1}`}}>
                                            {isPrimitive(getCellValue(rowData.cells[key]))
                                            ? <span className='ee-table-row-text'>{getCellValue(rowData.cells[key])}</span>
                                            : getCellValue(rowData.cells[key])}
                                    </div>
                                )}
                         </div>
        
        return (
            <>
            {rowData.tooltipFragments ?
            <TooltipProvider key={`ee-table-row-${rowData.cells.id}`} tooltipFragments={rowData.tooltipFragments}>{rowInner}</TooltipProvider>
            :
            rowInner
            }
            </>
        )
    }

    const generateTableRow = (rowData: EeTableDataRow) => {
        if (rowData.onClick) {
            if (typeof rowData.onClick === 'string') {
                return <Link key={`ee-table-row-${rowData.cells.id}`} to={rowData.onClick}>{generateTableRowInner(rowData)}</Link>
            } else {
                return generateTableRowInner(rowData, rowData.onClick)    
            }
        } else {
            return generateTableRowInner(rowData)
        }
    }

    const generateTableContent = (sortedData: EeTableDataRow[], isLoading: boolean) => {
        if (isLoading) {
            return <div className='ee-table-loader'><Loading description="Loading indicator" withOverlay={false} small={false} /></div>
        }

        if (sortedData.length === 0) {
            return <>
            {generateTableHeader()}
            <div className='ee-table-placeholder-container'>
                <span className='ee-table-placeholder-sub'>{t('component__ee_table__placeholder_sub')}</span>
                <span className='ee-table-placeholder-main'>{t('component__ee_table__placeholder_main')}</span>
            </div>
            </>
        } else {
            return <>
            {generateTableHeader()}
            {sortedData.map((rowData) => generateTableRow(rowData))}
            </>
        }
    }
    
    return (
        <div className={`ee-table ${ className ? className : ''}`}>
            {generateTableContent(sortedData, isLoading)}
        </div>
    )
}

export type { EeTableHeader, EeTableDataRow }
export { EeTable }