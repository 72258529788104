import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PICK_PATH } from '../../routes/routes'
import { ReducedPick } from '../../utils/eventReducedListCreator'
import { EeTable, EeTableDataRow, EeTableHeader } from '../EeTable/EeTable'
import './PicksTable.css'

interface PicksTableProps {
    picks: ReducedPick[]
}

function PicksTable({picks}: PicksTableProps) {
    const {t} = useTranslation()

    const generatePicksDataRow = (picks: ReducedPick[]): EeTableDataRow[] => {
        if (!picks || picks.length === 0) return []
        return picks.map((pick) => ({
            onClick: PICK_PATH.replace(':eventId', pick.eventId).replace(':pickId', pick.pickId),
            tooltipFragments: [
                {prefix: t('component__picks_table__origin_id'), value: pick.originId},
                {prefix: t('component__picks_table__pick_id'), value: pick.pickId}
            ],
            cells: {
                id: pick.pickId,
                eventId: {
                    value: <div className='picks-table-event-container'><div style={{background: `${pick.color}`}} className='picks-table-event-dot'></div> {pick.eventId}</div>,
                    sortableValue: +pick.eventId ?? -1
                },
                arivalTime: {
                    value: <div className='picks-table-date-container'><div className='picks-table-date'>{pick.arrivalDate}</div><div className='picks-table-time'>{pick.arrivalTime}</div></div>,
                    sortableValue: `${pick.arrivalDate}-${pick.arrivalTime}`
                },
                mb: pick.mb,
                mwp: pick.mwp,
                mwpd: pick.mwpd,
                t50: pick.t50,
                t50ex: pick.t50ex,
                td: pick.td,
                t0: pick.t0,
                status: pick.status
            }
        }))
    }

    const headers: EeTableHeader[] = [
        {key: 'eventId', label: t('component__picks_table__event_id'), sortable: true, spaceFraction: 3},
        {key: 'arivalTime', label: t('component__picks_table__arival_time'), sortable: true, spaceFraction: 4},
        {key: 'mb', label: t('component__picks_table__mb'), sortable: true, spaceFraction: 2},
        {key: 'mwp', label: t('component__picks_table__mwp'), sortable: true, spaceFraction: 2},
        {key: 'mwpd', label: t('component__picks_table__mwpd'), sortable: true, spaceFraction: 2},
        {key: 't50', label: t('component__picks_table__t50'), sortable: true, spaceFraction: 2},
        {key: 't50ex', label: t('component__picks_table__t50ex'), sortable: true, spaceFraction: 2},
        {key: 'td', label: t('component__picks_table__td'), sortable: true, spaceFraction: 2},
        {key: 't0', label: t('component__picks_table__t0'), sortable: true, spaceFraction: 2},
        {key: 'status', label: t('component__picks_table__status'), sortable: false, spaceFraction: 4},
    ]

    const data: EeTableDataRow[] = useMemo(() => generatePicksDataRow(picks), [picks])

    return (
        <EeTable
            className='picks-table'
            headers={headers}
            data={data} />
    )
}

export { PicksTable }