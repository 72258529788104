import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReducedEventParameters } from '../../utils/eventReducedListCreator'
import { RootState } from '../store'

const initialStateReducedEvents: ReducedEventParameters = {
    creationTime: '',
    version: '--',
    duration: '',
    columnStart: -1,
    columnSpan: -1,
    events: []
}

export const reducedEventsSlice = createSlice({
  name: 'reducedEvents',
  initialState: initialStateReducedEvents,
  reducers: {
    resetReducedEvents: (state) => {
      return initialStateReducedEvents
    },
    setReducedEvents: (state, action: PayloadAction<ReducedEventParameters>) => {
      return action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setReducedEvents, resetReducedEvents } = reducedEventsSlice.actions

export const getReducedEvents = (state: RootState) => state.reducedEvents

export default reducedEventsSlice.reducer