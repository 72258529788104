import { StationReduced } from "../../services/network"
import { stationIconAssociatedLatencyGt1h, stationIconAssociatedLatencyLt1h, stationIconAssociatedLatencyLt30s, stationIconAssociatedLatencyLt5m, stationIconAssociatedNotLive, stationIconTd50exGte11, stationIconTd50exLt09, stationIconTd50exLt11, stationIconTd50exUnassociated, stationIconUnassociatedLatencyGt1h, stationIconUnassociatedLatencyLt1h, stationIconUnassociatedLatencyLt30s, stationIconUnassociatedLatencyLt5m, stationIconUnassociatedNotLive } from "./constants"
import Leaflet from 'leaflet'
import { Marker } from "react-leaflet"
import { ReducedEvent } from "../../utils"
import { MouseEventHandler, useEffect, useState } from "react"
import { EventMinimum } from "./EarlyestMap"
import { StationPopup } from "./StationPopup"

interface StationMarkerProps {
    station: StationReduced,
    closePopup: MouseEventHandler<HTMLSpanElement>,
    isStationAssociated?: boolean,
    isSystemLive?: boolean,
    isEventDetail?: boolean,
    eventDetail?: ReducedEvent,
    eventsForStation?: EventMinimum[],
}

function StationMarker({station, closePopup, isStationAssociated, isSystemLive, isEventDetail, eventDetail, eventsForStation}: StationMarkerProps) {
    const [icon, setIcon] = useState<string>()

    const evaluateStationIcon = (station: StationReduced) => {
        const stationKey = `${station.net}.${station.sta}.${station.loc}`

        if (isEventDetail) {
            if (eventDetail) {
                if (!isStationAssociated) return stationIconTd50exUnassociated

                const td50x = +(eventDetail.stations[stationKey]?.t50ex)

                if (td50x < 0.9) {
                    return stationIconTd50exLt09
                } else if (td50x < 1.1) {
                    return stationIconTd50exLt11
                } else {
                    return stationIconTd50exGte11
                }
            } else {
                return stationIconTd50exUnassociated
            }
        }

        if (isStationAssociated) {
            const latency = +(station.latency ?? '0')

            if (!isSystemLive) return stationIconAssociatedNotLive

            if (latency < 30) {
                return stationIconAssociatedLatencyLt30s
            } else if (latency < 300) {
                return stationIconAssociatedLatencyLt5m
            } else if (latency < 3600) {
                return stationIconAssociatedLatencyLt1h
            } else {
                return stationIconAssociatedLatencyGt1h
            }
        } else {
            const latency = +(station.latency ?? '0')

            if (!isSystemLive) return stationIconUnassociatedNotLive

            if (latency < 30) {
                return stationIconUnassociatedLatencyLt30s
            } else if (latency < 300) {
                return stationIconUnassociatedLatencyLt5m
            } else if (latency < 3600) {
                return stationIconUnassociatedLatencyLt1h
            } else {
                return stationIconUnassociatedLatencyGt1h
            }
        }
    }
    
    useEffect(() => {
        setIcon(evaluateStationIcon(station))
    }, [])

    return (
        <Marker
            position={[+(station.lat ?? '0'), +(station.lon ?? '0')]}
            icon={new Leaflet.DivIcon({
                html: `${icon}`,
                className: 'no-show',
                iconSize: [32, 32],
                iconAnchor: [16, 16],
                popupAnchor: [0, -16]
            })} >
                <StationPopup
                    station={station}
                    closePopup={closePopup}
                    isStationAssociated={isStationAssociated}
                    isEventDetail={isEventDetail}
                    eventStationDetail={eventDetail?.stations[`${station.net}.${station.sta}.${station.loc}`]}
                    eventsForStation={eventsForStation} />
        </Marker>
    )
}

export { StationMarker }