import { ReactComponent as MapAutoCenterOn } from '../../assets/map-auto-center-on-icon.svg'
import { ReactComponent as MapAutoCenterOff } from '../../assets/map-auto-center-off-icon.svg'
import './MapCenterButton.css'

interface MapCenterButtonProps {
  shouldUpdateMapBounds: boolean
  setShouldUpdateMapBounds: React.Dispatch<React.SetStateAction<boolean>>
}

const MapCenterButton = ({ shouldUpdateMapBounds, setShouldUpdateMapBounds }: MapCenterButtonProps) => {
  const handleButtonClick = () => {
    setShouldUpdateMapBounds(!shouldUpdateMapBounds)
  }

  return (
    <div className="map-auto-center-button" onClick={handleButtonClick}>
      {shouldUpdateMapBounds ?
        <>
          <MapAutoCenterOn className='map-auto-center-icon' />
          <div className="map-auto-center-tooltip">Disable auto-centering</div>
        </>
      :
        <>
          <MapAutoCenterOff className='map-auto-center-icon' />
          <div className="map-auto-center-tooltip">Enable auto-centering</div>
        </>
      }
    </div>
  )
}

export default MapCenterButton
