import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import L from 'leaflet'

interface CoordinatesBoxProps {
    updateMousePosition: (callback: (latlng: L.LatLng) => void) => void
    isEventDetail: boolean
}

function CoordinatesBox({updateMousePosition, isEventDetail}: CoordinatesBoxProps) {
    const [mousePosition, setMousePosition] = useState<L.LatLng | null>(null)
    const { t } = useTranslation()

    useEffect(() => {
        updateMousePosition(setMousePosition); // Register the updater function
    }, [updateMousePosition])

    return (
        <div className={`map-coordinates-box ${isEventDetail ? 'map-coordinates-box-event' : 'map-coordinates-box-dash'}`} >
            <span className='map-coordinates-box-label'>{t('component__earlyest_map__box__label')}</span>
            <span className='map-coordinates-box-value'>{`${mousePosition?.lat.toFixed(4) ?? '--'}, ${mousePosition?.lng.toFixed(4) ?? '--'}`}</span>
        </div>
    )
}

export { CoordinatesBox }