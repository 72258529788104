/* tslint:disable */
/* eslint-disable */
/**
 * EarlyEst Dashboard API
 * All the APIs of the EarlyEst Dashboard
 *
 * The version of the OpenAPI document: 2.16.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return the version of the API exposed by the server
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiVersion: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary A multipart/form-data POST request accepting an array of XML files and calling the Kafka Producer
         * @param {Array<File>} [xmlfiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadOldMonitorPost: async (xmlfiles?: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/load-old-monitor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (xmlfiles) {
                xmlfiles.forEach((element) => {
                    localVarFormParams.append('xmlfiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return the version of the API exposed by the server
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiVersion(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiVersion(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApiApi.getApiVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary A multipart/form-data POST request accepting an array of XML files and calling the Kafka Producer
         * @param {Array<File>} [xmlfiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadOldMonitorPost(xmlfiles?: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadOldMonitorPost(xmlfiles, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApiApi.loadOldMonitorPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiApiFp(configuration)
    return {
        /**
         * 
         * @summary Return the version of the API exposed by the server
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiVersion(options?: any): AxiosPromise<string> {
            return localVarFp.getApiVersion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary A multipart/form-data POST request accepting an array of XML files and calling the Kafka Producer
         * @param {Array<File>} [xmlfiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadOldMonitorPost(xmlfiles?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.loadOldMonitorPost(xmlfiles, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiApi - interface
 * @export
 * @interface ApiApi
 */
export interface ApiApiInterface {
    /**
     * 
     * @summary Return the version of the API exposed by the server
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    getApiVersion(options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary A multipart/form-data POST request accepting an array of XML files and calling the Kafka Producer
     * @param {Array<File>} [xmlfiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApiInterface
     */
    loadOldMonitorPost(xmlfiles?: Array<File>, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI implements ApiApiInterface {
    /**
     * 
     * @summary Return the version of the API exposed by the server
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public getApiVersion(options?: RawAxiosRequestConfig) {
        return ApiApiFp(this.configuration).getApiVersion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary A multipart/form-data POST request accepting an array of XML files and calling the Kafka Producer
     * @param {Array<File>} [xmlfiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public loadOldMonitorPost(xmlfiles?: Array<File>, options?: RawAxiosRequestConfig) {
        return ApiApiFp(this.configuration).loadOldMonitorPost(xmlfiles, options).then((request) => request(this.axios, this.basePath));
    }
}

