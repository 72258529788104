const MOPAD_URL = process.env.REACT_APP_MOPAD_URL ?? 'http://localhost:8383/cgi-bin/mopad.cgi?plot_arg='

interface MopadRequest {
    strike: number,
    dip: number,
    rake: number,
    color?: string
}

const getMopadImgUrl = (request: MopadRequest): string => {
    return `${MOPAD_URL}${request.strike},${request.dip},${request.rake}${request.color ? '&color='+request.color : ''}`
}

const MopadClient = {
    getMopadImgUrl
}

export { MopadClient }