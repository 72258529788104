export const SORT_ASC = 0;
export const SORT_DESC = 1;

export interface TableHeader { stringHeader: string, tag: string, sortable: boolean, cols?: number }

///////// EARTHQUAKE LIST
export const ID_TAG = 'id'
export const DATETIME_TAG = 'datetime'
export const VERSION_TAG = 'version'
export const ZONE_TAG = 'zone'
export const COORDINATES_TAG = 'coordinates'
export const DEPTH_TAG = 'depth'
export const MB_TAG = 'mb'
export const MWP_TAG = 'mwp'
export const MWPD_TAG = 'mwpd'
export const CANCELLED_TAG = 'cancelled'

export interface EarthquakeTableEvent {
    [ID_TAG]: string,
    [DATETIME_TAG]: string,
    [VERSION_TAG]: string
    [ZONE_TAG]: string,
    [COORDINATES_TAG]: string,
    [DEPTH_TAG]: string,
    [MB_TAG]:string,
    [MWP_TAG]: string,
    [MWPD_TAG]: string,
    [CANCELLED_TAG]: boolean

}

export const earthquake_headers: Array<TableHeader> = [
    {
        stringHeader: 'page__events__table__id',
        sortable: true,
        tag: ID_TAG,
    },
    {
        stringHeader: 'page__events__table__datetime',
        sortable: true,
        tag: DATETIME_TAG,
    },
    {
        stringHeader: 'page__events__table__version',
        sortable: true,
        tag: VERSION_TAG,
    },
    {
        stringHeader: 'page__events__table__zone',
        sortable: true,
        tag: ZONE_TAG,
    },
    {
        stringHeader: 'page__events__table__coords',
        sortable: false,
        tag: COORDINATES_TAG,
    },
    {
        stringHeader: 'page__events__table__depth',
        sortable: true,
        tag: DEPTH_TAG,
    },
    {
        stringHeader: 'page__events__table__mb',
        sortable: true,
        tag: MB_TAG,
    },
    {
        stringHeader: 'page__events__table__mwp',
        sortable: true,
        tag: MWP_TAG,
    },
    {
        stringHeader: 'page__events__table__mwpd',
        sortable: true,
        tag: MWPD_TAG,
    },
]