import log from 'loglevel'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EVENT_PATH } from '../../routes/routes'
import { TooltipProvider } from '../TooltipProvider/TooltipProvider'
import { DoubleRowEventSummary } from './DoubleRowEventSummary'
import { DoubleRowEventTitle } from './DoubleRowEventTitle'
import {ReactComponent as OpenInNewTabIcon} from '../../assets/open-in-new-tab.svg'
import './EventCard.css'
import { PrimaryMagnitude } from '../../utils/eventReducedListCreator'

interface EventCardProps {
    color: string,
    title: string,
    subtitle: string,
    lat: string,
    lon: string,
    depth: string,
    mb: string,
    mbStations: string,
    mwp: string,
    mwpStations: string,
    mwpd: string,
    mwpdStations: string,
    primaryMag: PrimaryMagnitude,
    eventId: string,
    originId: string,
    cancelled: boolean
}

function EventCard({color, title, subtitle, lat, lon, depth, mb, mbStations, mwp, mwpStations, mwpd, mwpdStations, primaryMag, eventId, originId, cancelled}: EventCardProps) {

    const {t} = useTranslation()
    const navigate = useNavigate()

    const eventUrl = EVENT_PATH.replace(':eventId', eventId.split('/').pop() ?? '')

    return (
        <TooltipProvider
            delay={1000}
            tooltipFragments={[
                {prefix: t('component__event_card__tooltip_event'), value: eventId.split('/').pop() ?? ''},
                {prefix: t('component__event_card__tooltip_origin'), value: originId.split('/').pop() ?? ''},
            ]}>
            <div 
                className='event-card'
                onClick={() => navigate(eventUrl, {replace: false})}>
                <OpenInNewTabIcon className="new-tab-icon" onClick={(e) => {
                    e.stopPropagation() // Prevent the parent's onClick from being triggered
                    window.open(eventUrl, '_blank');
                }}/>
                <DoubleRowEventTitle 
                    color={color}
                    title={title} 
                    subtitle={subtitle}
                    cancelled={cancelled} />
                <DoubleRowEventSummary 
                    lat={lat}
                    lon={lon}
                    depth={depth}
                    mb={mb}
                    mbStations={mbStations}
                    mwp={mwp}
                    mwpStations={mwpStations}
                    mwpd={mwpd}
                    mwpdStations={mwpdStations}
                    primaryMag={primaryMag}
                    />
            </div>
        </TooltipProvider>
    )
}

export { EventCard }