import { useTranslation } from 'react-i18next'
import { ReducedEvent } from '../../utils'
import { EventCard } from '../EventCard/EventCard'
import { ReactComponent as IconLoadError } from '../../assets/load-error.svg'
import './EventsList.css'
import { useAppSelector } from '../../hooks/reduxCustomHooks'
import { Loading } from '@carbon/react'

interface EventsListProps {
    events: ReducedEvent[],
    error?: string
}

function EventsList({events, error}: EventsListProps) {
    const {t} = useTranslation()
    const isLoading = useAppSelector((state) => state.isLoading)

    const showBodyOrIndicators = () => {
        if (isLoading) {
            return  <div className='empty-list-placeholder-main'>
                        <Loading className='empty-list-placeholder-main-child' description="Loading indicator" withOverlay={false} small={true} />
                        <span className='empty-list-placeholder-main-child'> {t('component__event_list__placeholder_loading')}</span>
                    </div>
        }

        if (events.length !== 0 && !error) {
            return events.map((event, index) => (
                <EventCard 
                    key={event.id}
                    color={event.color}
                    title={event.title} 
                    subtitle={event.subtitle} 
                    lat={event.lat} 
                    lon={event.lon} 
                    depth={event.depth} 
                    mb={event.mb} 
                    mbStations={event.mbStations}
                    mwp={event.mwp} 
                    mwpStations={event.mwpStations}
                    mwpd={event.mwpd}
                    mwpdStations={event.mwpdStations}
                    primaryMag={event.primaryMag}
                    eventId={event.eventId}
                    originId={event.originId}
                    cancelled={event.cancelled}
                    />
            ))
        } else {
            return <>
                {error ? 
                    <>
                    <span className='empty-list-placeholder empty-list-placeholder-main'><IconLoadError /> {t('component__event_list__placeholder_error')}</span>
                    <span className='empty-list-placeholder empty-list-placeholder-sub'>{error}</span>
                    </>
                    :
                    <>
                    <span className='empty-list-placeholder empty-list-placeholder-main'>{t('component__event_list__placeholder_main')}</span>
                    <span className='empty-list-placeholder empty-list-placeholder-sub'>{t('component__event_list__placeholder_sub')}</span>
                    </>
                }
                </>
        }
    }

    return (
        <div className={`events-list ${events.length === 0 || isLoading ? 'events-list-empty' : ''}`}>
            {showBodyOrIndicators()}
        </div>
    )
}

export { EventsList }