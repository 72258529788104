import { useEffect } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT_DEFAULT } from '../../utils'
import { ReactComponent as PopupCloseIcon } from '../../assets/popup-close-icon.svg'
import './NotificationPanel.css'

type NotificationType = 'error' | 'warning' | 'success'

interface NotificationPanelProps {
    title: string
    body: string
    type: NotificationType
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const NotificationPanel = ({ title, body, type, visible, setVisible }: NotificationPanelProps) => {
    const {t, i18n} = useTranslation()

    const autoHideDuration = Number(process.env.REACT_APP_AUTO_HIDE_DURATION) || 5  // Default to 5 seconds

    dayjs.extend(utc)
    dayjs.locale(i18n.language)

    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => {
                setVisible(false)
            }, autoHideDuration * 1000)
            return () => clearTimeout(timer)
        }
    }, [visible, setVisible, autoHideDuration])

    return visible ? (
        <div className={`notification-panel ${type}`}>
        <div className='notification-header'>
            <strong>{title}</strong>
            <button onClick={() => setVisible(false)}>
                <PopupCloseIcon className='icon' />
            </button>
        </div>
        <div className='notification-body'>
            {body}
        </div>
        <div className='notification-timestamp'>
            {dayjs.utc().format(DATE_FORMAT_DEFAULT)}
        </div>
        </div>
    ) : null
}

export type { NotificationType }

export { NotificationPanel }