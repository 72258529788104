import dayjs from "dayjs"
import { ChangeEventHandler, Dispatch, SetStateAction, useEffect, useState } from "react"
import { JUST_DATE_FORMAT_DEFAULT, TIME_FORMAT_DEFAULT } from "../../utils"

interface DateTimeFormatProps {
    setSelectedDate: Dispatch<SetStateAction<dayjs.Dayjs | undefined>>,
    activeDate: dayjs.Dayjs,
    setActiveDate: Dispatch<SetStateAction<dayjs.Dayjs | undefined>>
}

function DateTimeForm({setSelectedDate, activeDate, setActiveDate}: DateTimeFormatProps) {
    const [date, setDate] = useState<string>('')
    const [dateError, setDateError] = useState<boolean>(false)
    const [time, setTime] = useState<string>('')
    const [timeError, setTimeError] = useState<boolean>(false)

    useEffect(() => {
        setDate(activeDate.format(JUST_DATE_FORMAT_DEFAULT))
        setTime(activeDate.format(TIME_FORMAT_DEFAULT))
    }, [activeDate])

    const handleDateChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const inputStr = e.target.value

        if (inputStr.length === 0 || inputStr.match(/^[0-9\-]+$/g)) {
            setDate(inputStr)
            if (inputStr.length >= 10 && inputStr.match(/^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/g)) {
                const datePieces = inputStr.split('-')
                if (datePieces?.length === 3) {
                    setDateError(false)
                    setActiveDate(activeDate.year(parseInt(datePieces[0], 10)).month(parseInt(datePieces[1], 10) - 1).date(parseInt(datePieces[2], 10)))
                    setSelectedDate(activeDate.year(parseInt(datePieces[0], 10)).month(parseInt(datePieces[1], 10) - 1).date(parseInt(datePieces[2], 10)))
                }
            } else {
                setDateError(true)
            }
        }
    }

    const handleTimeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const inputStr = e.target.value

        if (inputStr.length === 0 || inputStr.match(/^[0-9\:]+$/g)) {
            setTime(inputStr)
            if (inputStr.length >= 8 && inputStr.match(/^[0-9]{2}\:[0-9]{2}\:[0-9]{2}$/g)) {
                const timePieces = inputStr.split(':')
                if (timePieces?.length === 3) {
                    setTimeError(false)
                    setActiveDate(activeDate.hour(parseInt(timePieces[0], 10)).minute(parseInt(timePieces[1], 10)).second(parseInt(timePieces[2], 10)))
                    setSelectedDate(activeDate.hour(parseInt(timePieces[0], 10)).minute(parseInt(timePieces[1], 10)).second(parseInt(timePieces[2], 10)))
                }
            } else {
                setTimeError(true)
            }
        }
    }

    return (
        <div className='calendar-date-time-form'>
                <span className='calendar-date-time-label'>Set Date</span>
                <span className='calendar-date-time-label'>Set Time</span>
                <input 
                    className={'calendar-date-time-input ' + (dateError ? 'calendar-date-time-input-error' : '')} 
                    value={date} 
                    onChange={handleDateChange} 
                    style={{width: '110px'}} 
                    type='text' 
                    maxLength={10} />
                <input 
                    className={'calendar-date-time-input ' + (timeError ? 'calendar-date-time-input-error' : '')} 
                    value={time} 
                    onChange={handleTimeChange} 
                    style={{width: '90px'}} 
                    type='text' 
                    maxLength={8}/>
        </div>
    )
}

export { DateTimeForm }