import './SideBar.css'
import { ReactComponent as DashboardIcon } from '../../assets/dashboard-icon.svg'
import { ReactComponent as EventsIcon } from '../../assets/events-icon.svg'
import { ReactComponent as PicksIcon } from '../../assets/picks-icon.svg'
import { ReactComponent as StationsIcon } from '../../assets/stations-icon.svg'
import { ReactComponent as InfoIcon } from '../../assets/info-icon.svg'
import log from "loglevel"
import { SideButton } from '../SideButton/SideButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { DASHBOARD_PATH, EVENTS_PATH, INFO_PATH, PICKS_PATH, STATIONS_PATH } from '../../routes/routes'
import { useTranslation } from 'react-i18next'

function SideBar() {

    const navigation = useNavigate()
    const location = useLocation()
    const {t} = useTranslation()

    const handleClick = (eeSection: 'dashboard' | 'events' | 'picks' | 'stations' | 'info') => {
        switch (eeSection) {
            case 'dashboard':
                log.debug('Going to section => ', eeSection)
                navigation(DASHBOARD_PATH)
                break
            case 'events':
                log.debug('Going to section => ', eeSection)
                navigation(EVENTS_PATH)
                break
            case 'picks':
                log.debug('Going to section => ', eeSection)
                navigation(PICKS_PATH)
                break
            case 'stations':
                log.debug('Going to section => ', eeSection)
                navigation(STATIONS_PATH)
                break
            case 'info':
                log.debug('Going to section => ', eeSection)
                navigation(INFO_PATH)
                break
        }
    }

    const isButtonSelected = (ownPath: string) => {
        return location.pathname.includes(ownPath)
    }

    return(
        <div className='sidebar'>
            <div></div>
            <div></div>
            <SideButton tooltipText={t('component__sidebar__dashboard')} selected={isButtonSelected(DASHBOARD_PATH)} icon={(fillColor: string) => <DashboardIcon fill={fillColor}/>} onClick={() => handleClick('dashboard')} />
            <SideButton tooltipText={t('component__sidebar__events')} selected={isButtonSelected(EVENTS_PATH)} icon={(fillColor: string) => <EventsIcon fill={fillColor}/>} onClick={() => handleClick('events')} />
            <SideButton tooltipText={t('component__sidebar__picks')} selected={isButtonSelected(PICKS_PATH)} icon={(fillColor: string) => <PicksIcon fill={fillColor}/>} onClick={() => handleClick('picks')} />
            <SideButton tooltipText={t('component__sidebar__stations')} selected={isButtonSelected(STATIONS_PATH)} icon={(fillColor: string) => <StationsIcon fill={fillColor}/>} onClick={() => handleClick('stations')} />
            <SideButton tooltipText={t('component__sidebar__info')} selected={isButtonSelected(INFO_PATH)} icon={(fillColor: string) => <InfoIcon fill={fillColor}/>} onClick={() => handleClick('info')} />
        </div>
    )
}

export { SideBar }