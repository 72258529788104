import 'dayjs/locale/it'
import './DoubleRowRegion.css'

interface DoubleRowRegionParams {
    title: string,
    content: string,
    className?: string,
}

function DoubleRowGeneric({title, content, className}: DoubleRowRegionParams) {

    return (
        <div className={`doublerowregion-container ${className ?? ''}`}>
            <span className={`doublerowregion-region`}>{title}</span>
            <span className='doublerowregion-country'>{content}</span>
        </div>
    )
}

export { DoubleRowGeneric }
