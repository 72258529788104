import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { ReactComponent as OfflineInfoIcon } from '../../assets/info-offline-icon.svg'
import { ReactComponent as CancelIcon } from '../../assets/cancel-icon.svg'
import './OfflineBanner.css'

type OfflineBannerProps = {
    show: boolean,
}

const OfflineBanner = ({ show }: OfflineBannerProps) => {
    const [isDismissed, setIsDismissed] = useState(false);
    const {t} = useTranslation()

    useEffect(() => {
        if (!show) {
            setIsDismissed(false);
        }
    }, [show])

    const handleDismiss = () => {
        setIsDismissed(true);
    }

    if (!show || isDismissed) {
        return null
    }

    return (
        <div className="offline-banner">
            <OfflineInfoIcon />
            <span className="offline-banner-message">{t('component__offline_banner__message')}</span>
            <div className="offline-banner-dismiss">
                <CancelIcon className="offline-dismiss-icon" onClick={handleDismiss} />
            </div>
        </div>
    )
}

export default OfflineBanner