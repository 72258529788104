import {Dropdown} from "@carbon/react";
import React from "react";
import {useTranslation} from "react-i18next";
import './EeDropdown.css'

export interface Option {
    key: string,
    value: string,
}

export interface SelectedItem {
    selectedItem: Option
}

interface EeDropdownProps {
    id: string,
    options: Option[],
    selectedItem: number,
    label: string,
    callback: (selected: SelectedItem) => void
}

const EeDropdown = function({id, options, selectedItem, label, callback}: EeDropdownProps) {
    const {t} = useTranslation()
    return (<>
        <Dropdown
            key={`dropdown-${id}`}
            id={id}
            label={label}
            hideLabel
            titleText={t(label)}
            onChange={callback}
            items={options}
            itemToString={(item: Option) => item.value}
            selectedItem={options[selectedItem]}
        />
    </>)
}

export {
    EeDropdown
}