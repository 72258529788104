import { TFunction } from "i18next"
import { Filters } from "../../redux/reducers/filters.slice"
import dayjs from "dayjs"
import { CIRCLE_GEOFENCE, RECTANGULAR_GEOFENCE, CROWN_GEOFENCE} from "../GeofenceSelector/GeofenceSelector"
import {getEndtimeIfNotInFuture} from "../../pages/Events/utils";

export const evaluateGeofenceChip = (filters: Filters, t: TFunction) => {
    
    if(filters.shape === RECTANGULAR_GEOFENCE){
        let { ref_key, minlat, maxlat, minlon, maxlon } = filters
        if(ref_key && ref_key !== '') {
            return ref_key
        }

        if(!minlat && !maxlat && !minlon && !maxlon){
            return undefined
        }

        let latString = ``
        if(minlat && maxlat)
            latString = `Lat[${minlat} ${maxlat}] `
        if(minlat && !maxlat)
            latString = `Lat[${minlat}] `
        if(!minlat && maxlat)
            latString = `Lat[${maxlat}] `

        let lonString = ``
        if(minlon && maxlon)
            lonString = `Lon[${minlon} ${maxlon}] `
        if(minlon && !maxlat)
            lonString = `Lon[${minlon}] `
        if(!minlon && maxlat)
            lonString = `Lon[${maxlon}] `

        return `${latString}${lonString}`
    } 
    
    if(filters.shape === CIRCLE_GEOFENCE) {
        let { lat, lon, maxradiuskm } = filters
        if(lat && lon && maxradiuskm){
            return ['component__filters_box__geofence_chip_circle', lat, lon, maxradiuskm].join('#')
        }
    } 
    
    if(filters.shape === CROWN_GEOFENCE) {
        let { lat, lon, minradiuskm, maxradiuskm } = filters
        if(lat && lon && minradiuskm && maxradiuskm){
            return ['component__filters_box__geofence_chip_crown', lat, lon, minradiuskm, maxradiuskm].join('#')
        }
    }

    return undefined
}

export const evaluateMagnitudeChip = (filters: Filters) => {

    if(filters.minmag || filters.maxmag) {
        let magnitude:string = ''
        let {minmag, maxmag} = filters
        if(minmag && maxmag) {
            magnitude = `${filters.minmag ?? '--'} - ${filters.maxmag ?? '--'}`
        } else if(minmag && !maxmag) {
            magnitude = `${filters.minmag}`
        } else if(!minmag && maxmag) {
            magnitude = `${filters.maxmag}`
        }
        
        return magnitude
    }

    return undefined
}


export const evaluateDepthChip = (filters: Filters) => {

    if(filters.mindepth || filters.maxdepth) {
        let depth:string = ''
        let {mindepth, maxdepth} = filters
        if(mindepth && maxdepth) {
            depth = `${filters.mindepth ?? '--'} - ${filters.maxdepth ?? '--'}`
        } else if(mindepth && !maxdepth) {
            depth = `${filters.mindepth}`
        } else if(!mindepth && maxdepth) {
            depth = `${filters.maxdepth}`
        }
        
        return depth
    }

    return undefined
}

export const evaluateDatetimeChip = (filters: Filters) => {
    let {starttime, endtime} = filters
    if(starttime || endtime) {
        let start = dayjs.utc(starttime)
        let end = dayjs.utc(endtime)
        if(!endtime)
            start = dayjs.utc(starttime)
        const formatPattern = 'YYYY-MM-DD, HH:mm:ss'
        let key = `component__filters_box__time_utc`

        let time:string = ''

        if(starttime && endtime) {
            if(getEndtimeIfNotInFuture(endtime))
                time = `${start.format(formatPattern)} - ${end.format(formatPattern)}`
            else
                time = `${start.format(formatPattern)} - Now`
        } else if(starttime && !endtime) {
            time = `${start.format(formatPattern)} - Now`
        } else if(!starttime && endtime) {
            time = `?? - ${start.format(formatPattern)}`
        }

        return [key, time]
    }  

    return undefined
}


export const chipText = (text: string, t: TFunction) => {
    if(text.startsWith('component__filters_box__geofence_chip_circle')) {
        let tokens = text.split('#');
        if(tokens.length !== 4)
            return 'Error'
        return t(tokens[0], {lat: tokens[1], lon: tokens[2], radius: tokens[3]})
    }

    if(text.startsWith('component__filters_box__geofence_chip_crown')) {
        let tokens = text.split('#');
        if(tokens.length !== 5)
            return 'Error'
        // "Point: ({{lat}}, {{lon}}) - Radius: [{{minradius}} {{maxradius}}] km"
        return t(tokens[0], {lat: tokens[1], lon: tokens[2], minradius: tokens[3], maxradius: tokens[4]})
    }

    return text;
}