import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

const initialStateRequestError: any = null

export const requestErrorSlice = createSlice({
  name: 'requestError',
  initialState: initialStateRequestError,
  reducers: {
    resetRequestError: (state) => {
      return initialStateRequestError
    },
    setRequestError: (state, action: PayloadAction<any>) => {
      return action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { resetRequestError, setRequestError } = requestErrorSlice.actions

export const getRequestError = (state: RootState) => state.requestError

export default requestErrorSlice.reducer