import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/reduxCustomHooks';
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT_SKELETON } from '../../utils'
import './CreationTimeViewer.css'

function CreationTimeViewer() {
    const {t} = useTranslation()
    const currentEventParameters = useAppSelector((state) => state.currentEventParameters)
    const isLoading = useAppSelector((state) => state.isLoading)
    const [date, setDate] = useState<string>()
    const { i18n } = useTranslation();
    dayjs.extend(utc)
    dayjs.locale(i18n.language)

    useEffect(() => {
        const dateStr = currentEventParameters.creationInfo?.creationTime
        setDate((isLoading || !dateStr) ? DATE_FORMAT_DEFAULT_SKELETON : dayjs.utc(dateStr).format(DATE_FORMAT_DEFAULT))
    }, [currentEventParameters, isLoading])

    return (
        <span className='ctv-component'>
            <span className='ctv-component-pre'>{t('component__creation_time_viewer__creation_time')}</span><span className='ctv-component-value'>{date}</span>
        </span>
    )
}

export { CreationTimeViewer }