/* tslint:disable */
/* eslint-disable */
/**
 * EarlyEst Dashboard API
 * All the APIs of the EarlyEst Dashboard
 *
 * The version of the OpenAPI document: 2.16.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ParsedStations } from '../model';
// @ts-ignore
import { ParsedStationsReduced } from '../model';
// @ts-ignore
import { Station } from '../model';
// @ts-ignore
import { StationReduced } from '../model';
/**
 * StationsApi - axios parameter creator
 * @export
 */
export const StationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return a specific Station from the DB complete of all the data associated with it
         * @param {string} net The net of the desired Station
         * @param {string} sta The sta of the desired Station
         * @param {string} loc The loc of the desired Station
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStation: async (net: string, sta: string, loc: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'net' is not null or undefined
            assertParamExists('getStation', 'net', net)
            // verify required parameter 'sta' is not null or undefined
            assertParamExists('getStation', 'sta', sta)
            // verify required parameter 'loc' is not null or undefined
            assertParamExists('getStation', 'loc', loc)
            const localVarPath = `/station/{net}/{sta}/{loc}`
                .replace(`{${"net"}}`, encodeURIComponent(String(net)))
                .replace(`{${"sta"}}`, encodeURIComponent(String(sta)))
                .replace(`{${"loc"}}`, encodeURIComponent(String(loc)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a specific Station from the DB with a reduced set of data associated with it
         * @param {string} net The net of the desired Station
         * @param {string} sta The sta of the desired Station
         * @param {string} loc The loc of the desired Station
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationReduced: async (net: string, sta: string, loc: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'net' is not null or undefined
            assertParamExists('getStationReduced', 'net', net)
            // verify required parameter 'sta' is not null or undefined
            assertParamExists('getStationReduced', 'sta', sta)
            // verify required parameter 'loc' is not null or undefined
            assertParamExists('getStationReduced', 'loc', loc)
            const localVarPath = `/station-reduced/{net}/{sta}/{loc}`
                .replace(`{${"net"}}`, encodeURIComponent(String(net)))
                .replace(`{${"sta"}}`, encodeURIComponent(String(sta)))
                .replace(`{${"loc"}}`, encodeURIComponent(String(loc)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return all the existing Stations in the DB complete of all the data associated with them
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return all the existing Stations in the DB with a reduced set of data associated with them
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationsReduced: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stations-reduced`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StationsApi - functional programming interface
 * @export
 */
export const StationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return a specific Station from the DB complete of all the data associated with it
         * @param {string} net The net of the desired Station
         * @param {string} sta The sta of the desired Station
         * @param {string} loc The loc of the desired Station
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStation(net: string, sta: string, loc: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Station>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStation(net, sta, loc, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StationsApi.getStation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Return a specific Station from the DB with a reduced set of data associated with it
         * @param {string} net The net of the desired Station
         * @param {string} sta The sta of the desired Station
         * @param {string} loc The loc of the desired Station
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStationReduced(net: string, sta: string, loc: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StationReduced>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStationReduced(net, sta, loc, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StationsApi.getStationReduced']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Return all the existing Stations in the DB complete of all the data associated with them
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParsedStations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StationsApi.getStations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Return all the existing Stations in the DB with a reduced set of data associated with them
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStationsReduced(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParsedStationsReduced>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStationsReduced(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StationsApi.getStationsReduced']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StationsApi - factory interface
 * @export
 */
export const StationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Return a specific Station from the DB complete of all the data associated with it
         * @param {string} net The net of the desired Station
         * @param {string} sta The sta of the desired Station
         * @param {string} loc The loc of the desired Station
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStation(net: string, sta: string, loc: string, options?: any): AxiosPromise<Station> {
            return localVarFp.getStation(net, sta, loc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a specific Station from the DB with a reduced set of data associated with it
         * @param {string} net The net of the desired Station
         * @param {string} sta The sta of the desired Station
         * @param {string} loc The loc of the desired Station
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationReduced(net: string, sta: string, loc: string, options?: any): AxiosPromise<StationReduced> {
            return localVarFp.getStationReduced(net, sta, loc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return all the existing Stations in the DB complete of all the data associated with them
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStations(options?: any): AxiosPromise<ParsedStations> {
            return localVarFp.getStations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return all the existing Stations in the DB with a reduced set of data associated with them
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStationsReduced(options?: any): AxiosPromise<ParsedStationsReduced> {
            return localVarFp.getStationsReduced(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StationsApi - interface
 * @export
 * @interface StationsApi
 */
export interface StationsApiInterface {
    /**
     * 
     * @summary Return a specific Station from the DB complete of all the data associated with it
     * @param {string} net The net of the desired Station
     * @param {string} sta The sta of the desired Station
     * @param {string} loc The loc of the desired Station
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationsApiInterface
     */
    getStation(net: string, sta: string, loc: string, options?: RawAxiosRequestConfig): AxiosPromise<Station>;

    /**
     * 
     * @summary Return a specific Station from the DB with a reduced set of data associated with it
     * @param {string} net The net of the desired Station
     * @param {string} sta The sta of the desired Station
     * @param {string} loc The loc of the desired Station
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationsApiInterface
     */
    getStationReduced(net: string, sta: string, loc: string, options?: RawAxiosRequestConfig): AxiosPromise<StationReduced>;

    /**
     * 
     * @summary Return all the existing Stations in the DB complete of all the data associated with them
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationsApiInterface
     */
    getStations(options?: RawAxiosRequestConfig): AxiosPromise<ParsedStations>;

    /**
     * 
     * @summary Return all the existing Stations in the DB with a reduced set of data associated with them
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationsApiInterface
     */
    getStationsReduced(options?: RawAxiosRequestConfig): AxiosPromise<ParsedStationsReduced>;

}

/**
 * StationsApi - object-oriented interface
 * @export
 * @class StationsApi
 * @extends {BaseAPI}
 */
export class StationsApi extends BaseAPI implements StationsApiInterface {
    /**
     * 
     * @summary Return a specific Station from the DB complete of all the data associated with it
     * @param {string} net The net of the desired Station
     * @param {string} sta The sta of the desired Station
     * @param {string} loc The loc of the desired Station
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationsApi
     */
    public getStation(net: string, sta: string, loc: string, options?: RawAxiosRequestConfig) {
        return StationsApiFp(this.configuration).getStation(net, sta, loc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a specific Station from the DB with a reduced set of data associated with it
     * @param {string} net The net of the desired Station
     * @param {string} sta The sta of the desired Station
     * @param {string} loc The loc of the desired Station
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationsApi
     */
    public getStationReduced(net: string, sta: string, loc: string, options?: RawAxiosRequestConfig) {
        return StationsApiFp(this.configuration).getStationReduced(net, sta, loc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return all the existing Stations in the DB complete of all the data associated with them
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationsApi
     */
    public getStations(options?: RawAxiosRequestConfig) {
        return StationsApiFp(this.configuration).getStations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return all the existing Stations in the DB with a reduced set of data associated with them
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StationsApi
     */
    public getStationsReduced(options?: RawAxiosRequestConfig) {
        return StationsApiFp(this.configuration).getStationsReduced(options).then((request) => request(this.axios, this.basePath));
    }
}

