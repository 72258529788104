import './StationMap.css'
import 'leaflet/dist/leaflet.css'
import Leaflet, { LatLngTuple, Map as LeafletMap } from 'leaflet'
import { Circle, MapContainer, Marker, GeoJSON, TileLayer, ZoomControl } from 'react-leaflet'
import log from 'loglevel'
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { createRef } from 'react'
import { ParsedStationsReduced } from '../../services/network'
import { StationMarker } from '../EarlyestMap/StationMarker'

interface StationMapProps {
    stationsData?: ParsedStationsReduced
}

function StationMap({stationsData}: StationMapProps) {
    const map = createRef<LeafletMap>()
    const { t, i18n } = useTranslation();
    dayjs.extend(utc)
    dayjs.locale(i18n.language)

    const defaultMapBounds: LatLngTuple[] = [[33, -10], [50, 38]]

    const closePopup = () => map.current?.closePopup()
    
    return (
        <MapContainer 
            className='stations-map'
            zoomControl={false}
            scrollWheelZoom={true}
            zoom={7}
            // minZoom={3}
            // maxZoom={15}
            bounds={defaultMapBounds}
            attributionControl={false /* Follow your coscience here... :) */} 
            style={{height: 'calc(100vh - 56px)', width: 'calc(100vw - 56px)'} /* 56px is the size of the navbar and the sidebar */}
            ref={map}>
            <ZoomControl position='bottomright'/>
            <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/Tile/{z}/{y}/{x}"
                attribution='&copy; <a href="https://www.arcgis.com/index.html">ArcGIS Online</a> contributors'
            />
            {/* This is probably useless (or worse, harmful), but if needed can easily be added back
            <TileLayer
                url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-labels/{z}/{x}/{y}{r}.png"
            /> */}
            {stationsData?.stations?.map((station) => 
                <StationMarker
                    key={`${station.net}.${station.sta}.${station.loc}`}
                    station={station}
                    isStationAssociated={false}
                    closePopup={closePopup}
                    isSystemLive={true}
                    isEventDetail={false} />
            )}
        </MapContainer>
    )
}

export { StationMap }